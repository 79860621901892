import moment from "moment";
import React from "react";
import ReactTooltip from "react-tooltip";
import { heightBoxSchedule } from "..";
export class RenderInfoSchedule extends React.Component {
  constructor(props) {
    super(props);
    this.heightTotal = 0;
    this.firstID = "";
    this.firstElement = null;
  }
  componentDidMount() {
    this.firstID = this.props.elementParents.elements[0].elementID;
    this.props.elementParents.elements.forEach((e) => {
      let heightPx = (e.percentHeight / 100) * heightBoxSchedule;
      this.heightTotal += heightPx;
    });
    this.firstElement = this.props.elementParents.elements[0];
  }
  isCarregamentoAtrasado = () => {
    //endAt 
    return moment(this.firstElement.schedule.endAt).isBefore(moment())
  }
  getClassLoadingStatus = () => {
    // panel-sob-no-patio
    // panel-sob-atrasado
    // panel-sob-carregado
    // { code: 0, name: "VEÍCULO NÂO CHEGOU" },
    // { code: 1, name: "VEÍCULO NO PÁTIO" },
    // { code: 2, name: "VEÍCULO CARREGADO" }
  //   VEICULO_VAZIO LoadingStatus = 3
	// SEM_OPERCACAO LoadingStatus = 4

    const isAtrasado = this.isCarregamentoAtrasado();

    const classes = [];
    if (this.firstElement.schedule.loadingStatus == 0) {
      if (isAtrasado) {
        classes.push("panel-sob-atrasado")
      }
    }
    if (this.firstElement.schedule.loadingStatus == 1) {
      classes.push("panel-sob-no-patio")
    }
    if (this.firstElement.schedule.loadingStatus == 2) {
      classes.push("panel-sob-carregado")
    }
    if (this.firstElement.schedule.loadingStatus == 3) {
      classes.push("panel-sob-veiculo-vazio")
    }
    if (this.firstElement.schedule.loadingStatus == 4) {
      classes.push("panel-sob-sem-operacao")
    }
    return classes.join(" ")
  }
  render() {
    if (this.props.currentParentID == this.firstID) {
      const shipmentCode = this.firstElement.schedule.shipmentCode ? this.firstElement.schedule.shipmentCode : [];
      return (
        <>
          <div className={`panel-sob ${!this.props.elementParents.active ? "panel-sob-inactive" : ""} ${this.getClassLoadingStatus()}`} style={{ height: `${this.heightTotal}px` }}>
            <div className="info-schedule" id={this.firstID} key={this.firstID} data-tip data-for={this.firstID}>
              <h3>{this.firstElement.schedule.carrier && this.firstElement.schedule.carrier.name != "" ? this.firstElement.schedule.carrier.name : "Sem transportadora"}</h3>
              <p>
                {" "}
                Shipment: <span>{shipmentCode.join(", ")}</span>
              </p>
              <p>
                {" "}
                Veículo: <span>{this.firstElement.schedule.vehicleType.name}</span>
              </p>

              <p>
                Das <span>{moment(this.firstElement.schedule.startAt).format("HH:mm")}</span> às <span>{moment(this.firstElement.schedule.endAt).format("HH:mm")}</span>
              </p>
              <p>
                {" "}
                Sacos: <span>{this.firstElement.schedule.qtdeSacos}</span>
              </p>
            </div>

            {/* <div className="btn-remove-schedule" onClick={(e)=>{
              e.stopPropagation();
              this.props.onClickRemoveSchedule(this.firstElement.schedule)
            }}><i className="btn-remove-schedule-icon pi pi-times-circle" ></i></div> */}
          </div>

          <ReactTooltip id={this.firstID} type="info">
            <div style={{ color: "#FFF", textAlign: "left", zIndex: 18 }}>
              <span className="tooltip-info">
                Transpotadora: <b>{this.firstElement.schedule.carrier.name}</b>
              </span>
              <br />
              <span className="tooltip-info">
                Shipment: <b>{shipmentCode.join(", ")}</b>
              </span>
              <br />
              <span className="tooltip-info">
                Sacos: <b>{this.firstElement.schedule.qtdeSacos}</b>
              </span>

              {this.firstElement.schedule.driver.name != "" && (
                <><br />
                  <span className="tooltip-info">
                    Motorista: <b>{this.firstElement.schedule.driver.name}</b>
                  </span>
                </>)}
              <br />
              <span className="tooltip-info">
                Veículo: <b>{this.firstElement.schedule.vehicleType.name}</b> - Placa <b>{this.firstElement.schedule.vehicleType.plate}</b>
              </span>
              <br />
              <span className="tooltip-info">
                Das: <b>{moment(this.firstElement.schedule.startAt).format("HH:mm")}</b> às <b>{moment(this.firstElement.schedule.endAt).format("HH:mm")}</b>
              </span>
            </div>
          </ReactTooltip>
        </>
      );
    } else return <></>;
  }
}
