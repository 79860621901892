
import authentication from "../../services/authentication.service";
import history from '../../history';

export const types = {
  LOGIN_SET_CREDENTIAL: "login.setCrecential",
  LOGIN_CLEAR_CREDENTIAL: "login.setCredential",
  LOGIN_SET_ERROR_MESSAGE: "login.setErrorMessage",
  LOGIN_CLEAR_ERROR_MESSAGE: "login.clearErrorMessage",
  LOGIN_SET_TOKEN: "login.setToken",
  LOGIN_CLEAR_TOKEN: "login.clearToken",
  LOGIN_SET_LOGIN: "login.setLogin",
  LOGIN_CLEAR_LOGIN: "login.clearLogin",
  LOGIN_CLEAR_ALL: "login.clearAll",
  LOGIN_FETCH_AUTHENTICATION: "login.fetchAuthentication",
  LOGIN_FETCH_AUTHENTICATION_SUCCESS: "login.fetchAuthenticationSuccess",
  LOGIN_FETCH_AUTHENTICATION_ERROR: "login.fetchAuthenticationError"
};

export function loginSetCredential(credential) {
  return {
    type: types.LOGIN_SET_CREDENTIAL,
    credential
  };
}

export function loginClearCredential() {
  return {
    type: types.LOGIN_CLEAR_CREDENTIAL
  };
}

export function loginSetErrorMessage(errorMessage = "") {
  return {
    type: types.LOGIN_SET_ERROR_MESSAGE,
    errorMessage
  };
}

export function loginClearErrorMessage() {
  return {
    type: types.LOGIN_CLEAR_ERROR_MESSAGE
  };
}

export function loginSetToken(token = "") {
  return {
    type: types.LOGIN_SET_TOKEN,
    token
  };
}

export function loginClearToken() {
  return {
    type: types.LOGIN_CLEAR_TOKEN
  };
}

export function loginSetLogin(login = "") {
  return {
    type: types.LOGIN_SET_LOGIN,
    login
  };
}

export function loginClearLogin() {
  return {
    type: types.LOGIN_CLEAR_LOGIN
  };
}

export function loginClearAll() {
  return {
    type: types.LOGIN_CLEAR_All
  };
}
export function fetchAuthentication() {
  return {
    type: types.LOGIN_FETCH_AUTHENTICATION,
    async: true
  };
}

function fetchSuccess() {

  return {
    type: types.LOGIN_FETCH_AUTHENTICATION_SUCCESS,
    async: false
  };

}
export function fetchAuthenticationSucces(data) {
  const { token, login } = data;
  return dispatch => {
    dispatch(loginSetToken(token));
    dispatch(loginSetLogin(login));
    dispatch(fetchSuccess());
    history.push('/');

  };
}
export function fetchAuthenticationError(errorMessage) {
  return dispatch => {
    dispatch(loginSetErrorMessage(errorMessage));
    dispatch({
      type: types.LOGIN_FETCH_AUTHENTICATION_ERROR,
      async: false
    });
  };
}

export function authenticate() {
  return (dispatch, getState) => {
    dispatch(fetchAuthentication());
    authentication
      .login(getState().loginState.credential)
      .then(response => { 
        const { token, login, errorMessage } = response.data;
        if (errorMessage && errorMessage != "") {
          const errorMessage = "Login ou senha inválidos";
          dispatch(fetchAuthenticationError(errorMessage));
        } else {
          dispatch(fetchAuthenticationSucces({ token, login }));
        }
      })
      .catch(err => {
        if (err && err.response) {
          const errorMessage = "Login ou senha inválidos";
          dispatch(fetchAuthenticationError(errorMessage));
        }
      });
  };
}
