import React from 'react';
import { connect } from 'react-redux';
import Dashboard from './Dashboard';
import { actions } from '../dashboard.actions';
import { actions as carrierActions } from '../../carrier/carrier.actions';
import { actions as locationActions } from '../../location/location.actions';
import { actionsCarrierEfficiency } from '../../../store/reports/carrierefficiency';
import { actionsDivergence } from '../../../store/reports/divergence';
import { actionsRelationWeightByFreight } from '../../../store/reports/relationweightbyfreight';
import { actionsPercentFreightOnBill } from '../../../store/reports/percentfreightonbill';
import { actionsValueFreightByLocation } from '../../../store/reports/freightvaluebylocation';
import { AppService, COMPANY_CHANGE_EVENT_NAME, BUSINESS_UNIT_CHANGE_EVENT_NAME } from '../../../services/app.service';
import moment from 'moment';
import { Column } from 'primereact/column';

function findMenu(oidMenu, menuItems) {
  let menuFinded = null
  menuItems.forEach(menuItem => {
    if (menuItem.oid === oidMenu) {
      menuFinded = menuItem
    }
    if (menuItem.items) {
      const resultChildren = findMenu(oidMenu, menuItem.items)
      if (resultChildren) {
        menuFinded = resultChildren
      }
    }
  });
  return menuFinded
}

function isChildMenu(oidMenu, menuItems) {
  let isChild = false;
  menuItems.forEach(menuItem => {
    if (menuItem.oid === oidMenu) {
      isChild = true
      return;
    }
    if (menuItem.items && isChildMenu(oidMenu, menuItem.items)) {
      isChild = true;
      return;
    }
  });

  return isChild
}
function findMenuParent(oidMenu, menuItems) {
  let parentMenu = null
  menuItems.forEach(menuItem => {

    if (menuItem.items && isChildMenu(oidMenu, menuItem.items)) {
      parentMenu = menuItem
    }
  });
  return parentMenu
}

const mapStateToProps = state => {
  const appState = state.appState;
  const menuState = state.menuState;
  const userConfigurations = appState.userConfigurations ? appState.userConfigurations : null;
  let userFavorites = [];
  let userLastAccesses = [];


  if (userConfigurations) {
    userFavorites = userConfigurations.favorites ? userConfigurations.favorites : [];
    userLastAccesses = userConfigurations.lastAccesses ? userConfigurations.lastAccesses : [];
  }

  const menuItems = menuState.items;
  const allCarriersOption = { person: { name: "Todas", documentNumber: "" } }
  const periodOptions = [
    { description: "Mês atual", period: "CURRENT_MONTH" },
    { description: "Últimos 7 dias", period: "LAST_SEVEN_DAYS" },
    { description: "Últimos 15 dias", period: "LAST_FIFTEEN_DAYS" },
    { description: "Últimos 30 dias", period: "LAST_THIRTY_DAYS" },
    { description: "Últimos 2 meses", period: "LAST_TWO_MONTHS" },
    { description: "Últimos 3 meses", period: "LAST_THREE_MONTHS" },
    { description: "Últimos 6 meses", period: "LAST_SIX_MONTHS" },
    { description: "Último ano", period: "LAST_YEAR" }]
  const indicatorEfficiencyParams = {
    visible: userConfigurations && userConfigurations.indicators ? !!userConfigurations.indicators.efficiency : false,
    title: state.carrierEfficiencyReportState.carrierEfficiency.title,
    value: state.carrierEfficiencyReportState.carrierEfficiency.value,
    carrier: state.carrierEfficiencyReportState.carrierEfficiency.filters.carrier.name,
    period: state.carrierEfficiencyReportState.carrierEfficiency.filters.period.description,

    lookup: {
      carrier: {
        show: state.carrierEfficiencyReportState.carrierEfficiency.lookup.showCarrier,
        columns: state.carrierEfficiencyReportState.carrierEfficiency.lookup.columnsCarrier,
        records: [allCarriersOption, ...state.crudCarrierState.records],
        columns: state.crudCarrierState.columns.filter(col => col.field === 'name').map(col => <Column key={col.field} {...col} />),
        first: state.crudCarrierState.page > 0 ? state.crudCarrierState.page * (state.crudCarrierState.max + 1) : state.crudCarrierState.index,
        rows: state.crudCarrierState.max < 15 ? state.crudCarrierState.max + 1 : 15,
        totalRecords: state.crudCarrierState.count + 1,
      },
      period: {
        show: state.carrierEfficiencyReportState.carrierEfficiency.lookup.showPeriod,
        columns: [{
          field: "description",
          header: "Período",
          columnKey: "period",
          sortable: false,
          filter: false
        }].map(col => <Column key={col.field} {...col} />),
        records: periodOptions,
        first: 0,
        rows: 10,
        totalRecords: periodOptions.length,
      }

    }
  }

  const indicatorDivergenceParams = {
    visible: userConfigurations && userConfigurations.indicators ? !!userConfigurations.indicators.divergence : false,
    title: state.divergenceReportState.divergence.title,
    value: state.divergenceReportState.divergence.value,
    percentageValue: state.divergenceReportState.divergence.percentageValue,
    carrier: state.divergenceReportState.divergence.filters.carrier.name,
    period: state.divergenceReportState.divergence.filters.period.description,

    lookup: {
      carrier: {
        show: state.divergenceReportState.divergence.lookup.showCarrier,
        columns: state.divergenceReportState.divergence.lookup.columnsCarrier,
        records: [allCarriersOption, ...state.crudCarrierState.records],
        columns: state.crudCarrierState.columns.filter(col => col.field === 'name').map(col => <Column key={col.field} {...col} />),
        first: state.crudCarrierState.page > 0 ? state.crudCarrierState.page * (state.crudCarrierState.max + 1) : state.crudCarrierState.index,
        rows: state.crudCarrierState.max < 15 ? state.crudCarrierState.max + 1 : 15,
        totalRecords: state.crudCarrierState.count + 1,
      },
      period: {
        show: state.divergenceReportState.divergence.lookup.showPeriod,
        columns: [{
          field: "description",
          header: "Período",
          columnKey: "period",
          sortable: false,
          filter: false
        }].map(col => <Column key={col.field} {...col} />),
        records: periodOptions,
        first: 0,
        rows: 10,
        totalRecords: periodOptions.length,
      }

    }
  }

  const indicatorRelationWeightByFreightParams = {
    visible: userConfigurations && userConfigurations.indicators ? !!userConfigurations.indicators.relationWeightByFreight : false,
    title: state.relationWeightByFreightState.weightbyfreight.title,
    value: state.relationWeightByFreightState.weightbyfreight.value,
    carrier: state.relationWeightByFreightState.weightbyfreight.filters.carrier.name,
    period: state.relationWeightByFreightState.weightbyfreight.filters.period.description,

    lookup: {
      carrier: {
        show: state.relationWeightByFreightState.weightbyfreight.lookup.showCarrier,
        columns: state.relationWeightByFreightState.weightbyfreight.lookup.columnsCarrier,
        records: [allCarriersOption, ...state.crudCarrierState.records],
        columns: state.crudCarrierState.columns.filter(col => col.field === 'name').map(col => <Column key={col.field} {...col} />),
        first: state.crudCarrierState.page > 0 ? state.crudCarrierState.page * (state.crudCarrierState.max + 1) : state.crudCarrierState.index,
        rows: state.crudCarrierState.max < 15 ? state.crudCarrierState.max + 1 : 15,
        totalRecords: state.crudCarrierState.count + 1,
      },
      period: {
        show: state.relationWeightByFreightState.weightbyfreight.lookup.showPeriod,
        columns: [{
          field: "description",
          header: "Período",
          columnKey: "period",
          sortable: false,
          filter: false
        }].map(col => <Column key={col.field} {...col} />),
        records: periodOptions,
        first: 0,
        rows: 10,
        totalRecords: periodOptions.length,
      }

    }
  }

  const indicatorPercentFreightOnBillParams = {
    visible: userConfigurations && userConfigurations.indicators ? !!userConfigurations.indicators.percentFreightOnBill : false,
    title: state.percentFreightOnBillReportState.percentFreightOnBill.title,
    value: state.percentFreightOnBillReportState.percentFreightOnBill.value,
    percentageValue: state.percentFreightOnBillReportState.percentFreightOnBill.percentageValue,
    carrier: state.percentFreightOnBillReportState.percentFreightOnBill.filters.carrier.name,
    period: state.percentFreightOnBillReportState.percentFreightOnBill.filters.period.description,

    lookup: {
      carrier: {
        show: state.percentFreightOnBillReportState.percentFreightOnBill.lookup.showCarrier,
        columns: state.percentFreightOnBillReportState.percentFreightOnBill.lookup.columnsCarrier,
        records: [allCarriersOption, ...state.crudCarrierState.records],
        columns: state.crudCarrierState.columns.filter(col => col.field === 'name').map(col => <Column key={col.field} {...col} />),
        first: state.crudCarrierState.page > 0 ? state.crudCarrierState.page * (state.crudCarrierState.max + 1) : state.crudCarrierState.index,
        rows: state.crudCarrierState.max < 15 ? state.crudCarrierState.max + 1 : 15,
        totalRecords: state.crudCarrierState.count + 1,
      },
      period: {
        show: state.percentFreightOnBillReportState.percentFreightOnBill.lookup.showPeriod,
        columns: [{
          field: "description",
          header: "Período",
          columnKey: "period",
          sortable: false,
          filter: false
        }].map(col => <Column key={col.field} {...col} />),
        records: periodOptions,
        first: 0,
        rows: 10,
        totalRecords: periodOptions.length,
      }

    }
  }

  const allLocationsOption = { name: "Todos", initials: "", zipCode: "" }

  const indicatorFreightValueByLocationParams = {
    visible: userConfigurations && userConfigurations.indicators ? !!userConfigurations.indicators.freightValueByLocation : false,
    title: state.freightValueByLocationState.freightValue.title,
    value: state.freightValueByLocationState.freightValue.value,
    location: state.freightValueByLocationState.freightValue.filters.location.name,
    period: state.freightValueByLocationState.freightValue.filters.period.description,
    lookup: {
      location: {
        show: state.freightValueByLocationState.freightValue.lookup.showLocation,
        columns: state.freightValueByLocationState.freightValue.lookup.columnsLocation,
        records: [allLocationsOption, ...state.crudLocationState.records],
        columns: state.crudLocationState.columns.filter(col => col.field === 'name').map(col => <Column key={col.field} {...col} />),
        first: state.crudLocationState.page > 0 ? state.crudLocationState.page * (state.crudLocationState.max + 1) : state.crudLocationState.index,
        rows: state.crudLocationState.max < 15 ? state.crudLocationState.max + 1 : 15,
        totalRecords: state.crudLocationState.count + 1,
      },
      period: {
        show: state.freightValueByLocationState.freightValue.lookup.showPeriod,
        columns: [{
          field: "description",
          header: "Período",
          columnKey: "period",
          sortable: false,
          filter: false
        }].map(col => <Column key={col.field} {...col} />),
        records: periodOptions,
        first: 0,
        rows: 10,
        totalRecords: periodOptions.length,
      }

    }
  }

  return {
    userFavorites,
    userLastAccesses,
    menuItems,
    indicatorEfficiencyParams,
    indicatorDivergenceParams,
    indicatorRelationWeightByFreightParams,
    indicatorPercentFreightOnBillParams,
    indicatorFreightValueByLocationParams
  }
}

function onConfirmCarrier(e, dispatch, action) {
  let value = { name: "Todas", documentNumber: "" }
  if (e.selection) {
    value.name = e.selection.name
    value.documentNumber = e.selection.documentNumber
  }
  dispatch(action.setCNPJ(value));
  dispatch(action.setShowCarrier(false))
  dispatch(action.load())
}

function onConfirmLocation(e, dispatch, action) {
  let value = { name: "Todos", initials: "", zipCode: "" }
  if (e.selection) {
    value.name = e.selection.name
    value.initials = e.selection.initials
    value.zipCode = e.selection.zipCode
  }
  dispatch(action.setLocation(value))
  dispatch(action.setShowLocation(false))
  dispatch(action.load())
}

function onConfirmPeriod(e, dispatch, action) {
  let value = { description: "Mês atual", period: "CURRENT_MONTH" }
  if (e.selection) {
    value = e.selection
  }
  dispatch(action.setPeriod(value));
  dispatch(action.setShowPeriod(false));
  dispatch(action.load());
}



const mapDispatchToProps = dispatch => {
  return {
    loadUserConfigurations: () => dispatch(actions.loadUserConfigurations()),

    //FILTERS SEARCH CARRIER
    loockupCarrierOnFilter: (filterParams) => {
      dispatch(carrierActions.applyFilter(filterParams))
      dispatch(carrierActions.load())
    },

    // COMPONENTE EFFICIENCE
    setLookupCarrierVisible: (visible) => {
      console.log('change lookup carrier visibility to ', visible);
      if (visible) {
        dispatch(carrierActions.paginate({
          max: 14,
          index: 0,
          page: 0
        }));
        dispatch(carrierActions.applyFilter([], { reload: false }))
        dispatch(carrierActions.load())
      }
      dispatch(actionsCarrierEfficiency.setShowCarrier(visible))
    },
    setLookupPeriodVisible: (visible) => {
      dispatch(actionsCarrierEfficiency.setShowPeriod(visible))
    },
    onConfirmCarrierToEfficiencyIndicator: (e) => {
      onConfirmCarrier(e, dispatch, actionsCarrierEfficiency)
    },
    onConfirmPeriodToEfficiencyIndicator: (e) => {
      onConfirmPeriod(e, dispatch, actionsCarrierEfficiency)
    },
    lookupIndicatorEfficientyCarrierOnPage: paginationParams => dispatch(carrierActions.paginate(paginationParams)),
    lookupIndicatorEfficientyCarrieApplyFilter: filterParams => {
      dispatch(carrierActions.setType(null));
      dispatch(carrierActions.applyFilter(filterParams))
    },
    lookupIndicatorEfficientyCarrieLoad: () => dispatch(carrierActions.load()),


    //---- COMPONENTE DIVERGENCE ----
    setLookupCarrierDivergenceVisible: (visible) => {
      console.log('change lookup carrier visibility to ', visible);
      if (visible) {
        dispatch(carrierActions.paginate({
          max: 14,
          index: 0,
          page: 0
        }));
        dispatch(carrierActions.applyFilter([], { reload: false }))
        dispatch(carrierActions.load())
      }
      dispatch(actionsDivergence.setShowCarrier(visible))
    },
    setLookupPeriodDivergenceVisible: (visible) => {
      dispatch(actionsDivergence.setShowPeriod(visible))
    },
    onConfirmCarrierToDivergence: (e) => {
      onConfirmCarrier(e, dispatch, actionsDivergence)
    },
    onConfirmPeriodToDivergence: (e) => {
      onConfirmPeriod(e, dispatch, actionsDivergence)
    },
    lookupIndicatorDivergenceOnPage: paginationParams => dispatch(carrierActions.paginate(paginationParams)),
    lookupIndicatorDivergenceApplyFilter: filterParams => {
      dispatch(carrierActions.setType(null));
      dispatch(carrierActions.applyFilter(filterParams))
    },
    lookupIndicatorDivergenceLoad: () => dispatch(carrierActions.load()),


    //---- COMPONENTE PERCENT FREIGHT ON BILL ----
    setLookupCarrierPercentFreightOnBillVisible: (visible) => {
      console.log('change lookup carrier visibility to ', visible);
      if (visible) {
        dispatch(carrierActions.paginate({
          max: 14,
          index: 0,
          page: 0
        }));
        dispatch(carrierActions.applyFilter([], { reload: false }))
        dispatch(carrierActions.load())
      }
      dispatch(actionsPercentFreightOnBill.setShowCarrier(visible))
    },
    setLookupPeriodPercentFreightOnBillVisible: (visible) => {
      dispatch(actionsPercentFreightOnBill.setShowPeriod(visible))
    },
    onConfirmCarrierToPercentFreightOnBill: (e) => {
      onConfirmCarrier(e, dispatch, actionsPercentFreightOnBill)
    },
    onConfirmPeriodToPercentFreightOnBill: (e) => {
      onConfirmPeriod(e, dispatch, actionsPercentFreightOnBill)
    },
    lookupIndicatorPercentFreightOnBillOnPage: paginationParams => dispatch(carrierActions.paginate(paginationParams)),
    lookupIndicatorPercentFreightOnBillApplyFilter: filterParams => {
      dispatch(carrierActions.setType(null));
      dispatch(carrierActions.applyFilter(filterParams))
    },
    lookupIndicatorPercentFreightOnBillLoad: () => dispatch(carrierActions.load()),


    // ---- COMPONENTE INDICATOR RELATION WEIGHT BY FREIGHT ------
    setLookupCarrierRelationWeightByFreightVisible: (visible) => {
      console.log('change lookup carrier visibility to ', visible);
      if (visible) {
        dispatch(carrierActions.paginate({
          max: 14,
          index: 0,
          page: 0
        }));
        dispatch(carrierActions.applyFilter([], { reload: false }))
        dispatch(carrierActions.load())
      }
      dispatch(actionsRelationWeightByFreight.setShowCarrier(visible))
    },
    setLookupRelationWeightByFreightPeriodVisible: (visible) => {
      dispatch(actionsRelationWeightByFreight.setShowPeriod(visible))
    },
    onConfirmCarrierToRelationWeightByFreight: (e) => {
      onConfirmCarrier(e, dispatch, actionsRelationWeightByFreight)
    },
    onConfirmPeriodToRelationWeightByFreight: (e) => {
      onConfirmPeriod(e, dispatch, actionsRelationWeightByFreight)
    },
    lookupIndicatorRelationWeightByFreightOnPage: paginationParams => dispatch(carrierActions.paginate(paginationParams)),
    lookupIndicatorRelationWeightByFreightApplyFilter: filterParams => {
      dispatch(carrierActions.setType(null));
      dispatch(carrierActions.applyFilter(filterParams))
    },
    lookupIndicatorRelationWeightByFreightLoad: () => dispatch(carrierActions.load()),



    // ---- COMPONENTE FREIGHT VALUE BY LOCATION ------
    lookupIndicatorFreightValueOnPage: paginationParams => dispatch(locationActions.paginate(paginationParams)),
    setLookupPeriodFreightValueVisible: (visible) => {
      dispatch(actionsValueFreightByLocation.setShowPeriod(visible))
    },
    setLookupLocationFreightValueVisible: (visible) => {
      if (visible) {
        dispatch(locationActions.applyFilter([], { reload: false }))
        dispatch(locationActions.applyFilter([{ campo: 'type', valor: 'STATE', tipoFiltro: 'EQUALS' }], { reload: false }))
        dispatch(locationActions.paginate({
          max: 14,
          index: 0,
          page: 0
        }));

        //dispatch(locationActions.load())
      }
      dispatch(actionsValueFreightByLocation.setShowLocation(visible))
    },
    onConfirmLocationFreightValue: (e) => {
      onConfirmLocation(e, dispatch, actionsValueFreightByLocation)
    },
    onConfirmPeriodFreightValue: (e) => {
      onConfirmPeriod(e, dispatch, actionsValueFreightByLocation)
    },


    loadIndicators: () => {
      dispatch(actionsCarrierEfficiency.load())
      dispatch(actionsDivergence.load())
      dispatch(actionsPercentFreightOnBill.load())
      dispatch(actionsRelationWeightByFreight.load())
      dispatch(actionsValueFreightByLocation.load())
    },

  }
}

class DashboardPageContainer extends React.Component {


  componentDidMount() {
    AppService.on(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.on(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
    // this.props.loadExpectedAndRealizedData();
    // this.props.loadUserConfigurations()
    this.props.loadIndicators();
  }
  componentWillUnmount() {
    AppService.off(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.off(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
  }

  onReceiveAppNotifications = (eventName, params) => {
    if (eventName === COMPANY_CHANGE_EVENT_NAME || eventName === BUSINESS_UNIT_CHANGE_EVENT_NAME) {
      // this.props.loadExpectedAndRealizedData();
      this.props.loadIndicators();
    }
    // this.props.loadUserConfigurations()
  }

  btnReportCarrierOnClick = () => {
    this.props.setLookupCarrierVisible(true);
  }

  btnReportPeriodOnClick = () => {
    this.props.setLookupPeriodVisible(true);
  }

  lookupIndicatorEfficientyCarrierOnPage = (e) => {
    this.props.lookupIndicatorEfficientyCarrierOnPage({
      max: e.rows - 1,
      index: e.first > 0 ? e.first - e.page : 0,
      page: e.page
    })
  }

  btnReportDivergenceCarrierOnClick = () => {
    this.props.setLookupCarrierDivergenceVisible(true);
  }

  btnReportDivergencePeriodOnClick = () => {
    this.props.setLookupPeriodDivergenceVisible(true);
  }
  setLockupCarrierOnFilter = (e) => {
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach(key => {
      filters.push({
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: 'CONTEM'

      })
    })
    console.log(filters);
    this.props.loockupCarrierOnFilter(filters)
  }

  lookupIndicatorDivergenceCarrierOnPage = (e) => {
    this.props.lookupIndicatorDivergenceOnPage({
      max: e.rows - 1,
      index: e.first > 0 ? e.first - e.page : 0,
      page: e.page
    })
  }

  btnReportPercentFreightOnBillCarrierOnClick = () => {
    this.props.setLookupCarrierPercentFreightOnBillVisible(true);
  }

  btnReportPercentFreightOnBillPeridOnClick = () => {
    this.props.setLookupPeriodPercentFreightOnBillVisible(true);
  }

  lookupIndicatorPercentFreightOnBillCarrierOnPage = (e) => {
    this.props.lookupIndicatorPercentFreightOnBillOnPage({
      max: e.rows - 1,
      index: e.first > 0 ? e.first - e.page : 0,
      page: e.page
    })
  }

  btnReportRelationWeightByFreightCarrierOnClick = () => {
    this.props.setLookupCarrierRelationWeightByFreightVisible(true);
  }

  btnReportRelationWeightByFreightPeriodOnClick = () => {
    this.props.setLookupRelationWeightByFreightPeriodVisible(true);
  }

  lookupIndicatorRelationWeightByFreightCarrierOnPage = (e) => {
    this.props.lookupIndicatorRelationWeightByFreightOnPage({
      max: e.rows - 1,
      index: e.first > 0 ? e.first - e.page : 0,
      page: e.page
    })
  }

  btnReportFreightValueLocationOnClick = () => {
    this.props.setLookupLocationFreightValueVisible(true)
  }

  btnReportFreightValuePeriodOnClick = () => {
    this.props.setLookupPeriodFreightValueVisible(true)
  }

  lookupLocationFreightValueOnPage = (e) => {
    this.props.lookupIndicatorFreightValueOnPage({
      max: e.rows - 1,
      index: e.first > 0 ? e.first - e.page : 0,
      page: e.page
    })
  }

  setLockupLocationOnFilter = (e) => {
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach(key => {
      filters.push({
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: 'IGUAL'

      })
    })
    console.log(filters);
    this.props.loockupCarrierOnFilter(filters)
  }



  render() {
    const menuItems = this.props.menuItems;
    const favoriteRecords = !menuItems || menuItems.length <= 0 ? [] : this.props.userFavorites.map(favorite => {
      const menu = findMenu(favorite, menuItems)
      if (menu) {
        const menuParent = findMenuParent(menu.oid, menuItems)
        return { title: menu.label, command: menu.command, iconName: menuParent.iconName }
      }
      return null
    })
    const lastAccessedRecords = [];
    // const lastAccessedRecords = !menuItems || menuItems.length <= 0 ? [] : this.props.userLastAccesses.map(access => {
    //   const menu = findMenu(access.oidMenu, menuItems)
    //   if (menu && menu.oid) {
    //     const menuParent = findMenuParent(menu.oid, menuItems)
    //     return { title: menu.label, date: moment(access.accessDate).format('DD/MM/YYYY hh:mm'), iconName: menuParent.iconName, command: menu.command }
    //   }
    // })
    const dashboardParams = {
      favoriteRecords: favoriteRecords ? favoriteRecords : [],
      lastAccessedRecords: lastAccessedRecords ? lastAccessedRecords.reverse() : [],
      efficiency: {
        visible: this.props.indicatorEfficiencyParams.visible,
        title: this.props.indicatorEfficiencyParams.title,
        value: this.props.indicatorEfficiencyParams.value,
        carrier: this.props.indicatorEfficiencyParams.carrier,
        period: this.props.indicatorEfficiencyParams.period,
        lookup: {
          carrier: {
            columns: this.props.indicatorEfficiencyParams.lookup.carrier.columns,
            records: this.props.indicatorEfficiencyParams.lookup.carrier.records,
            totalRecords: this.props.indicatorEfficiencyParams.lookup.carrier.totalRecords,
            first: this.props.indicatorEfficiencyParams.lookup.carrier.first,
            show: this.props.indicatorEfficiencyParams.lookup.carrier.show,
            rowsPerPageOptions: [15],
            rows: this.props.indicatorEfficiencyParams.lookup.carrier.rows,
            onHide: () => { this.props.setLookupCarrierVisible(false) },
            onConfirm: this.props.onConfirmCarrierToEfficiencyIndicator,
            onCancel: () => { this.props.setLookupCarrierVisible(false) },
            onPage: this.lookupIndicatorEfficientyCarrierOnPage,
            onFilter: this.setLockupCarrierOnFilter,
          },
          period: {
            columns: this.props.indicatorEfficiencyParams.lookup.period.columns,
            records: this.props.indicatorEfficiencyParams.lookup.period.records,
            totalRecords: this.props.indicatorEfficiencyParams.lookup.period.totalRecords,
            first: this.props.indicatorEfficiencyParams.lookup.period.first,
            show: this.props.indicatorEfficiencyParams.lookup.period.show,
            rowsPerPageOptions: [10],
            rows: this.props.indicatorEfficiencyParams.lookup.period.rows,
            onHide: () => { this.props.setLookupPeriodVisible(false) },
            onConfirm: this.props.onConfirmPeriodToEfficiencyIndicator,
            onCancel: () => { this.props.setLookupPeriodVisible(false) },
            onPage: this.lookupIndicatorEfficiencyPeriodOnPage,
            onFilter: () => { },
          }
        },
        btnCarrierOnClick: this.btnReportCarrierOnClick,
        btnPeriodoOnClick: this.btnReportPeriodOnClick,
      },
      divergence: {
        visible: this.props.indicatorDivergenceParams.visible,
        title: this.props.indicatorDivergenceParams.title,
        value: this.props.indicatorDivergenceParams.value,
        percentageValue: this.props.indicatorDivergenceParams.percentageValue,
        carrier: this.props.indicatorDivergenceParams.carrier,
        period: this.props.indicatorDivergenceParams.period,
        lookup: {
          carrier: {
            columns: this.props.indicatorDivergenceParams.lookup.carrier.columns,
            records: this.props.indicatorDivergenceParams.lookup.carrier.records,
            totalRecords: this.props.indicatorDivergenceParams.lookup.carrier.totalRecords,
            first: this.props.indicatorDivergenceParams.lookup.carrier.first,
            show: this.props.indicatorDivergenceParams.lookup.carrier.show,
            rowsPerPageOptions: [15],
            rows: this.props.indicatorDivergenceParams.lookup.carrier.rows,
            onHide: () => { this.props.setLookupCarrierDivergenceVisible(false) },
            onConfirm: this.props.onConfirmCarrierToDivergence,
            onCancel: () => { this.props.setLookupCarrierDivergenceVisible(false) },
            onPage: this.lookupIndicatorDivergenceCarrierOnPage,
            onFilter: this.setLockupCarrierOnFilter,
          },
          period: {
            columns: this.props.indicatorDivergenceParams.lookup.period.columns,
            records: this.props.indicatorDivergenceParams.lookup.period.records,
            totalRecords: this.props.indicatorDivergenceParams.lookup.period.totalRecords,
            first: this.props.indicatorDivergenceParams.lookup.period.first,
            show: this.props.indicatorDivergenceParams.lookup.period.show,
            rowsPerPageOptions: [10],
            rows: this.props.indicatorDivergenceParams.lookup.period.rows,
            onHide: () => { this.props.setLookupPeriodDivergenceVisible(false) },
            onConfirm: this.props.onConfirmPeriodToDivergence,
            onCancel: () => { this.props.setLookupPeriodDivergenceVisible(false) },
            onPage: this.lookupIndicatorDivergenceOnPage,
            onFilter: () => { },
          }
        },
        btnCarrierOnClick: this.btnReportDivergenceCarrierOnClick,
        btnPeriodoOnClick: this.btnReportDivergencePeriodOnClick,
      },
      percentFreightOnBill: {
        visible: this.props.indicatorPercentFreightOnBillParams.visible,
        title: this.props.indicatorPercentFreightOnBillParams.title,
        value: this.props.indicatorPercentFreightOnBillParams.value,
        carrier: this.props.indicatorPercentFreightOnBillParams.carrier,
        period: this.props.indicatorPercentFreightOnBillParams.period,
        lookup: {
          carrier: {
            columns: this.props.indicatorPercentFreightOnBillParams.lookup.carrier.columns,
            records: this.props.indicatorPercentFreightOnBillParams.lookup.carrier.records,
            totalRecords: this.props.indicatorPercentFreightOnBillParams.lookup.carrier.totalRecords,
            first: this.props.indicatorPercentFreightOnBillParams.lookup.carrier.first,
            show: this.props.indicatorPercentFreightOnBillParams.lookup.carrier.show,
            rowsPerPageOptions: [15],
            rows: this.props.indicatorPercentFreightOnBillParams.lookup.carrier.rows,
            onHide: () => { this.props.setLookupCarrierPercentFreightOnBillVisible(false) },
            onConfirm: this.props.onConfirmCarrierToPercentFreightOnBill,
            onCancel: () => { this.props.setLookupCarrierPercentFreightOnBillVisible(false) },
            onPage: this.lookupIndicatorPercentFreightOnBillCarrierOnPage,
            onFilter: this.setLockupCarrierOnFilter,
          },
          period: {
            columns: this.props.indicatorPercentFreightOnBillParams.lookup.period.columns,
            records: this.props.indicatorPercentFreightOnBillParams.lookup.period.records,
            totalRecords: this.props.indicatorPercentFreightOnBillParams.lookup.period.totalRecords,
            first: this.props.indicatorPercentFreightOnBillParams.lookup.period.first,
            show: this.props.indicatorPercentFreightOnBillParams.lookup.period.show,
            rowsPerPageOptions: [10],
            rows: this.props.indicatorPercentFreightOnBillParams.lookup.period.rows,
            onHide: () => { this.props.setLookupPeriodPercentFreightOnBillVisible(false) },
            onConfirm: this.props.onConfirmPeriodToPercentFreightOnBill,
            onCancel: () => { this.props.setLookupPeriodPercentFreightOnBillVisible(false) },
            onPage: this.lookupIndicatorPercentFreightOnBillOnPage,
            onFilter: () => { },
          }
        },
        btnCarrierOnClick: this.btnReportPercentFreightOnBillCarrierOnClick,
        btnPeriodoOnClick: this.btnReportPercentFreightOnBillPeridOnClick,
      },
      relationWeightByFreight: {
        visible: this.props.indicatorRelationWeightByFreightParams.visible,
        title: this.props.indicatorRelationWeightByFreightParams.title,
        value: this.props.indicatorRelationWeightByFreightParams.value,
        percentageValue: this.props.indicatorRelationWeightByFreightParams.percentageValue,
        carrier: this.props.indicatorRelationWeightByFreightParams.carrier,
        period: this.props.indicatorRelationWeightByFreightParams.period,
        lookup: {
          carrier: {
            columns: this.props.indicatorRelationWeightByFreightParams.lookup.carrier.columns,
            records: this.props.indicatorRelationWeightByFreightParams.lookup.carrier.records,
            totalRecords: this.props.indicatorRelationWeightByFreightParams.lookup.carrier.totalRecords,
            first: this.props.indicatorRelationWeightByFreightParams.lookup.carrier.first,
            show: this.props.indicatorRelationWeightByFreightParams.lookup.carrier.show,
            rowsPerPageOptions: [15],
            rows: this.props.indicatorRelationWeightByFreightParams.lookup.carrier.rows,
            onHide: () => { this.props.setLookupCarrierRelationWeightByFreightVisible(false) },
            onConfirm: this.props.onConfirmCarrierToRelationWeightByFreight,
            onCancel: () => { this.props.setLookupCarrierRelationWeightByFreightVisible(false) },
            onPage: this.lookupIndicatorRelationWeightByFreightCarrierOnPage,
            onFilter: this.setLockupCarrierOnFilter,
          },
          period: {
            columns: this.props.indicatorRelationWeightByFreightParams.lookup.period.columns,
            records: this.props.indicatorRelationWeightByFreightParams.lookup.period.records,
            totalRecords: this.props.indicatorRelationWeightByFreightParams.lookup.period.totalRecords,
            first: this.props.indicatorRelationWeightByFreightParams.lookup.period.first,
            show: this.props.indicatorRelationWeightByFreightParams.lookup.period.show,
            rowsPerPageOptions: [10],
            rows: this.props.indicatorRelationWeightByFreightParams.lookup.period.rows,
            onHide: () => { this.props.setLookupRelationWeightByFreightPeriodVisible(false) },
            onConfirm: this.props.onConfirmPeriodToRelationWeightByFreight,
            onCancel: () => { this.props.setLookupRelationWeightByFreightPeriodVisible(false) },
            onPage: this.lookupIndicatorRelationWeightByFreightOnPage,
            onFilter: () => { },
          }
        },
        btnCarrierOnClick: this.btnReportRelationWeightByFreightCarrierOnClick,
        btnPeriodoOnClick: this.btnReportRelationWeightByFreightPeriodOnClick,
      },
      freightValueByLocation: {
        visible: this.props.indicatorFreightValueByLocationParams.visible,
        title: this.props.indicatorFreightValueByLocationParams.title,
        value: this.props.indicatorFreightValueByLocationParams.value,
        location: this.props.indicatorFreightValueByLocationParams.location,
        period: this.props.indicatorFreightValueByLocationParams.period,
        lookup: {
          location: {
            columns: this.props.indicatorFreightValueByLocationParams.lookup.location.columns,
            records: this.props.indicatorFreightValueByLocationParams.lookup.location.records,
            totalRecords: this.props.indicatorFreightValueByLocationParams.lookup.location.totalRecords,
            first: this.props.indicatorFreightValueByLocationParams.lookup.location.first,
            show: this.props.indicatorFreightValueByLocationParams.lookup.location.show,
            rowsPerPageOptions: [15],
            rows: this.props.indicatorFreightValueByLocationParams.lookup.location.rows,
            onHide: () => { this.props.setLookupLocationFreightValueVisible(false) },
            onConfirm: this.props.onConfirmLocationFreightValue,
            onCancel: () => { this.props.setLookupLocationFreightValueVisible(false) },
            onPage: this.lookupLocationFreightValueOnPage,
            onFilter: this.setLockupLocationOnFilter,
          },
          period: {
            columns: this.props.indicatorFreightValueByLocationParams.lookup.period.columns,
            records: this.props.indicatorFreightValueByLocationParams.lookup.period.records,
            totalRecords: this.props.indicatorFreightValueByLocationParams.lookup.period.totalRecords,
            first: this.props.indicatorFreightValueByLocationParams.lookup.period.first,
            show: this.props.indicatorFreightValueByLocationParams.lookup.period.show,
            rowsPerPageOptions: [10],
            rows: this.props.indicatorFreightValueByLocationParams.lookup.period.rows,
            onHide: () => { this.props.setLookupPeriodFreightValueVisible(false) },
            onConfirm: this.props.onConfirmPeriodFreightValue,
            onCancel: () => { this.props.setLookupPeriodFreightValueVisible(false) },
            onPage: this.lookupIndicatorFreightValueOnPage,
            onFilter: () => { },
          }
        },
        btnLocationFreightValueOnClick: this.btnReportFreightValueLocationOnClick,
        btnPeriodFreightValueOnClick: this.btnReportFreightValuePeriodOnClick,
      }


    }

    return (<Dashboard dashboardParams={dashboardParams} />);
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPageContainer);
