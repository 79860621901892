import React from 'react';
import { connect } from "react-redux";
import {Growl} from 'primereact/growl'


const mapStateToProps = (state =>{
    return {
        messages: state.messageState.messages
    }
})

class GrowlMessenger extends React.Component{
    
    clearMsg=()=>{
        this.growl.clear();
    }
    componentWillReceiveProps(newProps){
        if(newProps.messages !== this.props.messages ){
            if(newProps.messages && newProps.messages.length > 0){
                this.growl.clear(); 
                setTimeout(()=>{
                    this.growl.show(newProps.messages);
                    setTimeout(this.clearMsg,2500);
                },500) 
            }else{
                this.growl.clear();
            }
        }
    }
    render(){
        let screen = null;
        if(this.props.messages){
            screen = (
                <div>
                     <Growl ref={(el) => this.growl = el}  style={{marginTop:'100px'}}/>
                </div>
            )
        }
        return screen;
    }
}

export default connect(mapStateToProps)(GrowlMessenger)