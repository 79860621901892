import { validate } from './validations';
import { build } from '../../services/crudFactory';
import { Action } from '../../services/actions.factory';
import storage from '../../services/stoage.service';
import api from '../../services/api.service';
const BASE_PATH = '/lognex/users';
const prefix = 'crudUsuario';
const crud = build(validate, BASE_PATH);

const newTypes = {
    PICKLIST_PERFIS_MOVE_TO_TARGET: `${prefix}pickListPerfisMoveToTarget`,
    PICKLIST_PERFIS_MOVE_TO_SOURCE: `${prefix}pickListPerfisMoveToSource`,
    PICKLIST_PERFIS_MOVE_ALL_TO_SOURCE: `${prefix}pickListPerfisMoveAllToSource`,
    PICKLIST_PERFIS_MOVE_ALL_TO_TARGET: `${prefix}pickListPerfisMoveAllToTarget`,
    SET_LOOKUP_CARRIER_VISIBLE: `${prefix}setLookupCarrierVisible`,
}

const actions = new Action(prefix, crud);

const types = Object.assign({}, actions.types, newTypes);

actions.setLookupCarrierVisible = (visible) => {
    return {
        type: types.SET_LOOKUP_CARRIER_VISIBLE,
        visible,
    };
};

actions.setLookupPessoaVisible = (visible) => {
    return {
        type: types.SET_LOOKUP_PESSOA_VISIBLE,
        visible
    }
}


actions.setBooleanFilter = (field, value) => {
    return {
        type: types.SET_BOOLEAN_FILTER,
        field,
        value
    }
}

actions.pickListPerfisMoveToSource = (perfisRemoved) => {
    return {
        type: types.PICKLIST_PERFIS_MOVE_TO_SOURCE,
        perfisRemoved
    }
}

actions.pickListPerfisMoveAllToSource = () => {
    return {
        type: types.PICKLIST_PERFIS_MOVE_ALL_TO_SOURCE
    }
}

actions.pickListPerfisMoveToTarget = (perfisAdd, oid) => {
    return {
        type: types.PICKLIST_PERFIS_MOVE_TO_TARGET,
        perfisAdd,
        oid
    }
}

actions.pickListPerfisMoveAllToTarget = (allPerfis, oid) => {
    return actions.pickListPerfisMoveToTarget(allPerfis, oid)
}

actions.removeUserFromDriver = (userId) => {
    return new Promise((resolve, reject) => {
        if (userId) {

            api.remove(`/api/v1/lognex/users/${userId}`,)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                })
        }
    })
}

actions.saveUserFromUserDriver = (userDriver, success, reject) => {
    return (dispatch, getState) => {

        const user = storage.getUser();


        let newUser = {
            oid: userDriver.userId || null,
            name: userDriver.name,
            login: userDriver.documentNumber,
            email: userDriver.documentNumber,
            groups: user.groups,
            carrier: user.carrier,
            password: userDriver.password,
            documentNumber: userDriver.documentNumber,
            role: "",
            phone: "",
            address: {},
            userType: "",
            accountkey: "",
        }



        if (!user.carrier) {
            return reject("Usuário sem permissão para cadastrar motorista.");
        }

        if (userDriver && userDriver.userId) {


            const url = `/api/v1/lognex/users/${userDriver.userId}`
            api.put(url, newUser)
                .then((response) => {
                    if (success) {
                        success(response.status);
                    }
                })
                .catch((err) => {
                    if (reject) {
                        reject(err);
                    }
                });

        } else {
            const url = '/api/v1/lognex/users'
            api.post(url, newUser)
                .then((response) => {
                    if (success) {
                        success(response.data);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    if (reject) {
                        reject(err);
                    }
                });

        }
    };
}

export { types, actions };