import React from 'react';
import { SelectButton } from 'primereact/selectbutton';
import { Calendar } from 'primereact/calendar';
import { LookupField } from '../../../components/lookup/LookupField';
import { Dropdown } from 'primereact/dropdown';
import {MultiSelect} from 'primereact/multiselect';
import './AuditFilter.scss';

export const AuditFilter = (props) => {
    return (
        <div className="carrier-form">
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="p-grid">
                        <div className="p-col-2 form-field-container">
                            <label className="lxRequiredField">Filtrar por</label>
                            <SelectButton value={props.currentDocTypeFilter} options={props.docTypeOptions} onChange={props.docTypeChangeHandler}></SelectButton>
                        </div>
                        <div className="p-col-3 form-field-container">
                            <label className="lxRequiredField">Situação</label>
                            <MultiSelect className="lxMultiSelectDocStatus" selectedItemTemplate={props.docStatusFilterItemSelectedItemTemplate} itemTemplate={props.docStatusFilterItemTemplate} value={props.currentDocStatusFilter} onChange={props.docStatusChangeHandler} name="currentFileType" options={props.docStatusOptions}></MultiSelect>
                           
                        </div>
                        <div className="p-col-3 form-field-container">
                            <label className="lxRequiredField">Tipo</label>
                            
                            <Dropdown
                                className="lxDropdowDocStatusType"  
                                name="currentStatusType"  
                                value={props.currentPendingsTypeFilter}
                                options={props.docPendingsTypeOptions}
                                onChange={props.onPendingsTypeChangeHandler} 
                            ></Dropdown>
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-2 form-field-container">
                            <label className="lxRequiredField">De</label>
                            <Calendar showIcon={true} value={props.initialDateFilter} showTime={false} onChange={props.initialDateChangeHandler} disabled={false} name="initialDateFilter" dateFormat="dd/mm/yy" />
                        </div>
                        <div className="p-col-2 form-field-container">
                            <label className="lxRequiredField">Até</label>
                            <Calendar showIcon={true} value={props.finalDateFilter} showTime={false} onChange={props.finalDateChangeHandler} disabled={false} name="finallDateFilter" dateFormat="dd/mm/yy" />
                        </div>
                        <div className="p-col-4 form-field-container">
                            <label>Transportadora</label>
                            <LookupField
                                value={props.carrierFilter ? props.carrierFilter : null}
                                placeholder="Transportadora"
                                name="name"
                                field="name"
                                onComplete={props.lookupCarrierOnComplete}
                                onInputFilter={props.lookupCarrierOnInputFilter}
                                editDisabled={props.lookupCarrier.editDisabled}
                                onEditField={props.lookupCarrierOnEdit}
                                onClick={props.lookupCarrierOnClick}
                                visible={props.lookupCarrier.visible}
                                modal={props.lookupCarrier.modal}
                                header={props.lookupCarrier.header}
                                onHide={props.lookupCarrierOnHide}
                                onConfirm={props.lookupCarrierOnConfirm}
                                onCancel={props.lookupCarrierOnCancel}
                                records={props.lookupCarrier.records}
                                columns={props.lookupCarrier.columns}
                                totalRecords={props.lookupCarrier.totalRecords}
                                sortOrder={props.lookupCarrier.order}
                                sortField={props.lookupCarrier.sortField}
                                first={props.lookupCarrier.first}
                                rows={props.lookupCarrier.rows}
                                onPage={props.lookupCarrierOnPage}
                                onFilter={props.lookupCarrierOnFilter}
                                onSort={props.lookupCarrierOnOnSort}
                            />

                        </div>
                        <div className="p-col-2 form-field-container">
                            <br />
                            <button
                                className="lognex-btn-highlighted"
                                style={{ width: "100%" }}
                                title="Filtrar"
                                onClick={props.applyAuditFilter}>
                                Filtrar
                            </button>
                        </div>
                        <div className="p-col-2 form-field-container">
                            <br />
                            <button
                                className="lognex-btn-highlighted-inverse"
                                style={{ width: "100%" }}
                                title="Download"
                                onClick={props.onDownloadCSV}
                                disabled={!props.enableExportCSV}
                            >
                                Exportar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    );
}
