
import React from 'react';
import { connect } from 'react-redux';
import { Column } from 'primereact/column';
import Toolbar from "../../../components/toolbar/Toolbar";
import { actions } from '../route.actions';
import { routes } from '../../../constants';
import { Page } from "../../../components/page/Page";
import { RouteForm } from './RouteForm';
import { actions as carrierActions } from '../../carrier/carrier.actions';
import { actions as locationActions } from '../../location/location.actions';


const mapStateToProps = state => {
  const record = state.crudRouteState.currentRecord;
  const currentCompany = state.appState.currentCompany;

  const lookupCarrierBase = {
    records: state.crudCarrierState.records,
    columns: state.crudCarrierState.columns
      .filter(col => col.field === 'oid' || col.field === 'name' || col.field === 'tradeName')
      .map(col => <Column key={col.field} {...col} />),
    first: state.crudCarrierState.index,
    rows: state.crudCarrierState.max,
    totalRecords: state.crudCarrierState.count,
    sortField: state.crudCarrierState.sortField,
    order: state.crudCarrierState.sortOrder,
    editDisabled: !record.oidCarrier
  }
  let lookupCarrier = Object.assign({}, state.crudRouteState.lookupCarrier, lookupCarrierBase);

  const lookupOriginBase = {
    records: state.crudLocationState.records,
    columns: state.crudLocationState.columns
      .filter(col => col.field === 'oid' || col.field === 'description')
      .map(col => <Column key={col.field} {...col} />),
    first: state.crudLocationState.index,
    rows: state.crudLocationState.max,
    totalRecords: state.crudLocationState.count,
    sortField: state.crudLocationState.sortField,
    order: state.crudLocationState.sortOrder,
    editDisabled: !record.oidLocation
  }
  let lookupOrigin = Object.assign({}, state.crudRouteState.lookupOrigin, lookupOriginBase);

  const lookupDestinationBase = {
    records: state.crudLocationState.records,
    columns: state.crudLocationState.columns
      .filter(col => col.field === 'oid' || col.field === 'description')
      .map(col => <Column key={col.field} {...col} />),
    first: state.crudLocationState.index,
    rows: state.crudLocationState.max,
    totalRecords: state.crudLocationState.count,
    sortField: state.crudLocationState.sortField,
    order: state.crudLocationState.sortOrder,
    editDisabled: !record.oidLocation
  }
  let lookupDestination = Object.assign({}, state.crudRouteState.lookupDestination, lookupDestinationBase);

  return {
    currentCompany,
    record,
    lookupCarrier,
    lookupOrigin,
    lookupDestination
  }
}

const mapDispatchToProps = dispatch => {
  return {

    lookupCarrierSetVisible: visible => dispatch(actions.setLookupCarrierVisible(visible)),
    lookupCarrierPaginate: paginationParams => dispatch(carrierActions.paginate(paginationParams)),
    lookupCarrierSortOrder: sortParams => dispatch(carrierActions.sortOrder(sortParams)),
    lookupCarrierApplyFilter: filterParams => {
      dispatch(carrierActions.setType(null));
      dispatch(carrierActions.applyFilter(filterParams))
    },
    lookupCarrierLoad: () => dispatch(carrierActions.load()),
    setCarrier: (carrier) => {
      dispatch(actions.setField('oidCarrier', carrier ? carrier.oid : null));
      dispatch(actions.setField('carrierName', carrier ? carrier.name : ''));
      dispatch(actions.setField('carrierNumber', carrier ? carrier.documentNumber : ''));
    },
    setCompany: (company) => {
      dispatch(actions.setField('oidCompany', company ? company.oid : null));
      dispatch(actions.setField('companyName', company ? company.nomeEmpresa : ''));
      dispatch(actions.setField('companyNumber', company ? company.CNPJ : ''));
    },
    lookupOriginSetVisible: visible => dispatch(actions.setLookupOriginVisible(visible)),
    lookupOriginPaginate: paginationParams => dispatch(locationActions.paginate(paginationParams)),
    lookupOriginSortOrder: sortParams => dispatch(locationActions.sortOrder(sortParams)),
    lookupOriginApplyFilter: filterParams => {
      dispatch(locationActions.setType(null));
      dispatch(locationActions.applyFilter(filterParams))
    },
    lookupOriginLoad: () => dispatch(locationActions.load()),
    setOrigin: (origin) => {
      dispatch(actions.setField('oidOrigin', origin != null ? origin.oid : null));
      dispatch(actions.setField('originName', origin != null ? origin.description : ''));
    },
    lookupDestinationSetVisible: visible => dispatch(actions.setLookupDestinationVisible(visible)),
    lookupDestinationPaginate: paginationParams => dispatch(locationActions.paginate(paginationParams)),
    lookupDestinationSortOrder: sortParams => dispatch(locationActions.sortOrder(sortParams)),
    lookupDestinationApplyFilter: filterParams => {
      dispatch(locationActions.setType(null));
      dispatch(locationActions.applyFilter(filterParams))
    },
    lookupDestinationLoad: () => dispatch(locationActions.load()),
    setDestination: (destination) => {
      dispatch(actions.setField('oidDestination', destination != null ? destination.oid : null));
      dispatch(actions.setField('destinationName', destination != null ? destination.description : ''));
    },

    setField: (field, value) => dispatch(actions.setField(field, value)),
    changeHandler: event => {
      const target = event.target;
      const field = target.name;
      let value = target.value;
      dispatch(actions.removeErrors([{ field }]));
      dispatch(actions.setField(field, value));
    },
    saveHandler: event => {
      dispatch(actions.save());
    },
    removeHandler: event => {
      dispatch(actions.remove());
    },
    newHandler: event => {
      dispatch(actions.makeNew());
    },
    loadById: oid => dispatch(actions.loadById(oid))
  }
}

class RouteFormPageContainer extends React.Component {

  constructor(props) {
    super(props);
    actions.afterSave = ({ oid }) => {
      this.props.history.replace(`${routes.PATH_ROUTE}/${oid}`);
    };
    actions.afterNew = () => {
      this.props.history.replace(`${routes.PATH_ROUTE}/novo`);
    };
    actions.afterRemove = () => {
      this.props.history.replace(`${routes.PATH_ROUTE}`);
    };
  }

  componentDidMount() {
    const oid = this.props.match.params.id;
    if (oid && parseInt(oid)) {
      this.props.loadById(oid);
    } else {
      this.props.newHandler();
      this.props.setCompany(this.props.currentCompany);
    }
  }

  searchHandler = () => {
    this.props.history.push(`${routes.PATH_ROUTE}`)
  }

  lookupCarrierOnPage = (e) => {
    this.props.lookupCarrierPaginate({
      max: e.rows,
      index: e.first
    });
  }

  lookupCarrierOnSort = (e) => {
    this.props.lookupCarrierSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField
    });
  }

  lookupCarrierOnFilter = (e) => {
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach(key => {
      filters.push({
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: 'CONTEM'
      });
    });
    this.props.lookupCarrierApplyFilter(filters);
  }

  lookupCarrierOnClick = (e) => {
    this.props.lookupCarrierLoad();
    this.props.lookupCarrierSetVisible(true);
  }

  lookupCarrierOnHide = (e) => {
    this.props.lookupCarrierSetVisible(false);
  }

  lookupCarrierOnConfirm = (e) => {
    this.props.setCarrier(e.selection);
    this.props.lookupCarrierSetVisible(false);
  }

  lookupCarrierOnCancel = (e) => {
    this.props.setCarrier(null);
    this.props.lookupCarrierSetVisible(false);
  }

  lookupCarrierOnEdit = () => {
    if (this.props.record.oidPessoa) {
      this.props.history.push(`${routes.PATH_CARRIER}/${this.props.record.oidCarrier}`);
    }
  }

  lookupCarrierOnComplete = e => { }

  lookupCarrierOnInputFilter = (e) => {
    const value = e.value;
    const filters = [];
    if (typeof value === 'string') {
      filters.push({
        campo: "name",
        valor: value,
        tipoFiltro: 'CONTEM'
      });
      this.props.setField("carrierName", value);
      this.props.setField("oidCarrier", null);
    } else {
      this.props.setCarrier(value);
    }
    this.props.lookupCarrierApplyFilter(filters);
  }

  lookupOriginOnPage = (e) => {
    this.props.lookupOriginPaginate({
      max: e.rows,
      index: e.first
    })
  }

  lookupOriginOnSort = (e) => {
    this.props.lookupOriginSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField
    })
  }

  lookupOriginOnFilter = (e) => {
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach(key => {
      filters.push({
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: 'CONTEM'
      })
    })
    this.props.lookupOriginApplyFilter(filters);
  }

  lookupOriginOnClick = (e) => {
    this.props.lookupOriginLoad();
    this.props.lookupOriginSetVisible(true);
  }

  lookupOriginOnHide = (e) => {
    this.props.lookupOriginSetVisible(false);
  }

  lookupOriginOnConfirm = (e) => {
    this.props.setOrigin(e.selection);
    this.props.lookupOriginSetVisible(false);
  }

  lookupOriginOnCancel = (e) => {
    this.props.setOrigin(null);
    this.props.lookupOriginSetVisible(false);
  }

  lookupOriginOnEdit = () => {
    if (this.props.record.oidPessoa) {
      this.props.history.push(`${routes.PATH_ORIGIN}/${this.props.record.oidOrigin}`);
    }
  }

  lookupOriginOnComplete = e => {
    console.log(e);
  }

  lookupOriginOnInputFilter = (e) => {
    const value = e.value;
    const filters = [];
    if (typeof value === 'string') {
      filters.push({
        campo: "name",
        valor: value,
        tipoFiltro: 'CONTEM'
      });
      this.props.setField("originName", value);
      this.props.setField("oidOrigin", null);
    } else {
      this.props.setOrigin(value);
    }
    this.props.lookupOriginApplyFilter(filters);
  }

  lookupDestinationOnPage = (e) => {
    this.props.lookupDestinationPaginate({
      max: e.rows,
      index: e.first
    });
  }

  lookupDestinationOnSort = (e) => {
    this.props.lookupDestinationSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField
    });
  }

  lookupDestinationOnFilter = (e) => {
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach(key => {
      filters.push({
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: 'CONTEM'
      })
    });
    this.props.lookupDestinationApplyFilter(filters);
  }

  lookupDestinationOnClick = (e) => {
    this.props.lookupDestinationLoad();
    this.props.lookupDestinationSetVisible(true);
  }

  lookupDestinationOnHide = (e) => {
    this.props.lookupDestinationSetVisible(false);
  }

  lookupDestinationOnConfirm = (e) => {
    this.props.setDestination(e.selection);
    this.props.lookupDestinationSetVisible(false);
  }

  lookupDestinationOnCancel = (e) => {
    this.props.setDestination(null);
    this.props.lookupDestinationSetVisible(false);
  }

  lookupDestinationOnEdit = () => {
    if (this.props.record.oidPessoa) {
      this.props.history.push(`${routes.PATH_DESTINATION}/${this.props.record.oidDestination}`);
    }
  }

  lookupDestinationOnComplete = e => {
    console.log(e);
  }

  lookupDestinationOnInputFilter = (e) => {
    const value = e.value;
    const filters = [];
    if (typeof value === 'string') {
      filters.push({
        campo: "name",
        valor: value,
        tipoFiltro: 'CONTEM'
      });
      this.props.setField("destinationName", value);
      this.props.setField("oidDestination", "");
    } else {
      this.props.setDestination(value);
    }
    this.props.lookupDestinationApplyFilter(filters);
  }

  render() {
    const content = (
      <RouteForm
        changeHandler={this.props.changeHandler}
        record={this.props.record}

        lookupCarrier={this.props.lookupCarrier}
        lookupCarrierOnHide={this.lookupCarrierOnHide}
        lookupCarrierOnConfirm={this.lookupCarrierOnConfirm}
        lookupCarrierOnCancel={this.lookupCarrierOnCancel}
        lookupCarrierOnPage={this.lookupCarrierOnPage}
        lookupCarrierOnSort={this.lookupCarrierOnSort}
        lookupCarrierOnFilter={this.lookupCarrierOnFilter}
        lookupCarrierOnClick={this.lookupCarrierOnClick}
        lookupCarrierOnEdit={this.lookupCarrierOnEdit}
        lookupCarrierOnComplete={this.lookupCarrierOnComplete}
        lookupCarrierOnInputFilter={this.lookupCarrierOnInputFilter}

        lookupOrigin={this.props.lookupOrigin}
        lookupOriginOnHide={this.lookupOriginOnHide}
        lookupOriginOnConfirm={this.lookupOriginOnConfirm}
        lookupOriginOnCancel={this.lookupOriginOnCancel}
        lookupOriginOnPage={this.lookupOriginOnPage}
        lookupOriginOnSort={this.lookupOriginOnSort}
        lookupOriginOnFilter={this.lookupOriginOnFilter}
        lookupOriginOnClick={this.lookupOriginOnClick}
        lookupOriginOnEdit={this.lookupOriginOnEdit}
        lookupOriginOnComplete={this.lookupOriginOnComplete}
        lookupOriginOnInputFilter={this.lookupOriginOnInputFilter}

        lookupDestination={this.props.lookupDestination}
        lookupDestinationOnHide={this.lookupDestinationOnHide}
        lookupDestinationOnConfirm={this.lookupDestinationOnConfirm}
        lookupDestinationOnCancel={this.lookupDestinationOnCancel}
        lookupDestinationOnPage={this.lookupDestinationOnPage}
        lookupDestinationOnSort={this.lookupDestinationOnSort}
        lookupDestinationOnFilter={this.lookupDestinationOnFilter}
        lookupDestinationOnClick={this.lookupDestinationOnClick}
        lookupDestinationOnEdit={this.lookupDestinationOnEdit}
        lookupDestinationOnComplete={this.lookupDestinationOnComplete}
        lookupDestinationOnInputFilter={this.lookupDestinationOnInputFilter}

      />
    );
    const title = `Rota ${this.props.record.oid || 'nova'}`
    const header = (
      <div>
        <h1>{title}</h1>
        <hr className="lxSplitter" ></hr>
        <div>
          <Toolbar
            onSave={this.props.saveHandler} saveButtonStyle='highlighted'
            onRemove={this.props.removeHandler} removeButtonStyle='danger'
            onNew={this.props.newHandler}
            onSearch={this.searchHandler}>
          </Toolbar>
        </div>
      </div>
    );
    return (<Page header={header} content={content} />);
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RouteFormPageContainer);