import apiService from "../../services/api.service";
import authenticationService from "../../services/authentication.service";

export const types = {
    APP_SET_CURRENT_USER: 'appSetCurrentUser',
    APP_SET_CURRENT_COMPANY: 'appSetCurrentCompany',
    APP_SET_CURRENT_UNIT: 'appSetCurrentUnit',
    APP_SET_COMPANIES: 'appSetCurrentCompanies',
    APP_SET_UNITS: 'appSetUnits',
    APP_SET_USER_CONFIGURATIONS: 'appSetUserConfigurations',
    APP_FETCH_COMPANIES: 'appFetchCompanies',
    APP_FETCH_COMPANIES_SUCCESS: 'appFetchCompaniesSuccess',
    APP_FETCH_COMPANIES_ERROR: 'appFetchCompaniesError',
    APP_SHOW_LOADER: 'appShowLoader',
    APP_HIDE_LOADER: 'apphIDELoader',
    APP_SET_INDICATOR: 'appSetIndicator',
    APP_SET_INDICATORS: 'appSetIndicators',
    APP_SET_FAVORITES: 'appSetFavorites',
    APP_SET_TOWER_TOTALIZERS: 'appSaveTowerTotalizers',
    SET_TOWER_SHOW_TOTALIZERS: `setTowerShowTotalizers`,
    LOAD_USER_CONFIGURATION: `loadUserConfigurations`,
}


export function setTowerShowTotalizers(towerShowTotalizers) {
    return {
        type: types.SET_TOWER_SHOW_TOTALIZERS,
        towerShowTotalizers,
    };
};

export function setIndicator(field, value) {
    return {
        type: types.APP_SET_INDICATOR,
        field,
        value
    }
}
export function setIndicators(indicators) {
    return {
        type: types.APP_SET_INDICATORS,
        indicators
    }
}
export function setFavorites(favorites) {
    return {
        type: types.APP_SET_FAVORITES,
        favorites
    }
}

function showLoader() {
    return {
        type: types.APP_SHOW_LOADER,
        async: true
    }
}
function hideLoader() {
    return {
        type: types.APP_HIDE_LOADER,
        async: false
    }
}

function fetchData() {
    return {
        type: types.APP_FETCH_DATA,
        async: true
    }
}

function fetchCompanies() {
    return {
        type: types.APP_FETCH_COMPANIES,
        async: true
    }
}
function fetchCompaniesSuccess() {
    return {
        type: types.APP_FETCH_COMPANIES_SUCCESS,
        async: false
    }
}
function fetchCompaniesError() {
    return {
        type: types.APP_FETCH_COMPANIES_ERROR,
        async: false
    }
}

export function setCurrentUser(user, oid,carrier) {
    return {
        type: types.APP_SET_CURRENT_USER,
        user, oid,carrier
    }
}

export function setCurrentCompany(company) {
    return {
        type: types.APP_SET_CURRENT_COMPANY,
        company
    }
}
export function setCurrentUnit(unit) {
    return {
        type: types.APP_SET_CURRENT_UNIT,
        unit
    }
}
export function setCompanies(companies) {
    return {
        type: types.APP_SET_COMPANIES,
        companies
    }
}
export function setUnits(units) {
    return {
        type: types.APP_SET_UNITS,
        units
    }
}

export function appSetUserConfigurations(userConfigurations) {
    return {
        type: types.APP_SET_USER_CONFIGURATIONS,
        userConfigurations
    }
}


export function loadCompanies() {
    return dispatch => {
        dispatch(fetchCompanies())
        const params = {
            filters: [],
            sortFields: [],
            index: 0,
            max: 0,
            mode: 'eager'

        }
        apiService.post('/api/v1/lognex/companies/all', params)
            .then(({ data }) => {
                const records = data.records; 
                dispatch(setCompanies(records))
                dispatch(fetchCompaniesSuccess())
            })
            .catch(err => {
                dispatch(fetchCompaniesError())
            })
    }
}


function fetchUserConfigurations(dispatch) {
    const userOid = authenticationService.getUserOid()
    if (userOid) {
        dispatch(showLoader());
        apiService.get(`/api/v1/lognex/userConfigurations/${userOid}`)
            .then(({ data }) => {
                dispatch(appSetUserConfigurations(data))
                dispatch(hideLoader());
            })
            .catch(() => {
                dispatch(hideLoader());
            })
    }
}

export function loadUserConfigurations() {
    return (dispatch) => {
        fetchUserConfigurations(dispatch);
    };
}

export function appSaveTowerTotalizers(towerShowTotalizers) {
    return dispatch => {
        const userOid = authenticationService.getUserOid()
        const body = {
            user: { oid: userOid },
            towerShowTotalizers
        }
        if (userOid) {
            dispatch(showLoader());
            apiService.post(`/api/v1/lognex/userconfigurations/towerShowTotalizers`, body)
                .then(({ data }) => {
                    dispatch(loadUserConfigurations());
                    dispatch(hideLoader());
                })
                .catch(() => {
                    dispatch(hideLoader());
                });
        }
    }
}

function postUserLastAccess(dispatch, oidMenu) {
    const userOid = authenticationService.getUserOid()
    const userName = authenticationService.getUserName()

    const body = {
        user: { oid: userOid, name: userName },
        oidMenu
    }
    if (userOid) {
        dispatch(showLoader());
        apiService.post(`/api/v1/lognex/userconfigurations/lastaccesses`, body)
            .then(({ data }) => {
                dispatch(appSetUserConfigurations(data))
                dispatch(hideLoader());
            })
            .catch(() => {
                dispatch(hideLoader());
            });
    }
}

export function userLastAccess(oidMenu) {
    return (dispatch) => {
        postUserLastAccess(dispatch, oidMenu);
    };
}

function removeLastAccess(dispatch, oidMenu) {
    const userOid = authenticationService.getUserOid()
    const userName = authenticationService.getUserName()

    const body = {
        user: { oid: userOid, name: userName },
        oidMenu
    }
    if (userOid) {
        dispatch(showLoader());
        apiService.post(`/api/v1/lognex/userconfigurations/removelastaccesses`, body)
            .then(({ data }) => {
                dispatch(appSetUserConfigurations(data))
                dispatch(hideLoader());
            })
            .catch(() => {
                dispatch(hideLoader());
            });
    }
}


function addFavorite(dispatch, oidMenu) {
    const userOid = authenticationService.getUserOid()
    const userName = authenticationService.getUserName()

    const body = {
        user: { oid: userOid, name: userName },
        oidMenu
    }
    if (userOid) {
        dispatch(showLoader());
        apiService.post(`/api/v1/lognex/userconfigurations/addfavorite`, body)
            .then(({ data }) => {
                dispatch(appSetUserConfigurations(data))
                dispatch(hideLoader());
            })
            .catch(() => {
                dispatch(hideLoader());
            });
    }
}

function removeFavorite(dispatch, oidMenu) {
    const userOid = authenticationService.getUserOid()
    const userName = authenticationService.getUserName()

    const body = {
        user: { oid: userOid, name: userName },
        oidMenu
    }
    if (userOid) {
        dispatch(showLoader());
        apiService.post(`/api/v1/lognex/userconfigurations/removefavorite`, body)
            .then(({ data }) => {
                dispatch(appSetUserConfigurations(data))
                dispatch(hideLoader());
            })
            .catch(() => {
                dispatch(hideLoader());
            });
    }
}

export function toggleFavorite(isFavorite, oidMenu) {
    return (dispatch) => {
        if (isFavorite) {
            removeFavorite(dispatch, oidMenu);
        } else {
            addFavorite(dispatch, oidMenu);
        }
    };
}

export function toggleLastAccess(oidMenu) {
    return (dispatch) => {
        removeLastAccess(dispatch, oidMenu)
    };
}