import React from 'react';
import './TimelineCard.scss'
export const TimelineCard = (props) => {

    const buttonImg = (img) => {
        if (img) {
            return (
                <button
                    className="btn_image"
                    // onClick={props.showVisibleModalImage}
                    title={"Ver comprovante de entrega"}
                >
                   <i class="pi pi-images"></i>
                </button>
            )
        }
    }

    return (
        <div className="timeline__card" onClick={props.image && props.showVisibleModalImage}>
            <h4>{props.title}</h4>
            {props.subTitle && <h5>{props.subTitle}</h5>}
            <div>{props.text}</div>
            {props.image && <h4> Comprovante de entrega  { buttonImg(props.image)} </h4> }
        </div>
    );
}
