
import React from 'react';
import { InputSwitch } from 'primereact/inputswitch';
import { InputText } from 'primereact/inputtext';
import './OrderManagementForm.scss'

export const OrderManagementForm = (props) => {

    const customFields = [];
    if (props.record && props.record.customFields) {
        const keys = Object.keys(props.record.customFields);
        keys.forEach((key, index) => {
            customFields.push(
                <div className="p-col-2 form-field-container" key={index}>
                    <label>{key}</label>
                    <InputText value={props.record.customFields[key]} onChange={(event) => { }} readOnly={true} />
                </div>
            )
        })
    }
 
    return (
        <div className="order-page">
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} onScroll={(event) => event.stopPropagation()}>
                <div className="p-grid" style={{ width: "100%" }}>
                    <div className="p-col-2 form-field-container">
                        <label>Código</label>
                        <InputText value={props.record && props.record.oid ? props.record.oid : ""} onChange={(event) => { }} readOnly={true} />
                    </div>
                    <div className="p-col-2 form-field-container">
                        <label>Número</label>
                        <InputText value={props.record && props.record.code ? props.record.code : ""} onChange={(event) => { }} readOnly={true} />
                    </div>
                </div>
                <div className="p-grid" style={{ width: "100%" }}>
                    {customFields}
                </div>
                <div className="p-grid" style={{ width: "100%" }}>
                    <div className="p-col-2 form-field-container">
                        <label>Data</label>
                        <InputText value={props.record && props.record.createdAt ? props.record.createdAt.format('DD/MM/YYYY ') : ""} onChange={(event) => { }} readOnly={true} />
                    </div>
                    <div className="p-col-2 form-field-container">
                        <label>Previsão de entrega</label>
                        <InputText value={props.record && props.record.deliveryForecast ? props.record.deliveryForecast.format('DD/MM/YYYY ') : ""} onChange={(event) => { }} readOnly={true} />
                    </div>
                    <div className="p-col-2 form-field-container">
                        <label>Estoque completo</label>
                        <InputSwitch
                            checked={props.record && props.record.readyStock}
                            onChange={(event) => { }}
                            readOnly={true}
                        />
                    </div>
                </div>
                <div className="p-grid" style={{ width: "100%" }}>
                    <div className="p-col-2 form-field-container">
                        <label>Peso</label>
                        <InputText value={props.record && props.record.cargo ? props.record.cargo.summary.weight : ""} onChange={(event) => { }} readOnly={true} />
                    </div>
                    <div className="p-col-2 form-field-container">
                        <label>Valor</label>
                        <InputText value={props.record && props.record.cargo ? props.record.cargo.summary.value : ""} onChange={(event) => { }} readOnly={true} />
                    </div>
                    <div className="p-col-2 form-field-container">
                        <label>Cubagem</label>
                        <InputText value={props.record && props.record.cargo ? props.record.cargo.summary.cubage : ""} onChange={(event) => { }} readOnly={true} />
                    </div>
                </div>
                <div className="p-grid" style={{ width: "100%" }}>

                </div>
                <div className="p-grid" style={{ width: "100%" }}>
                    <div className="p-col-6 form-field-container">
                        <label>Cliente</label>
                        <InputText value={props.record && props.record.receiver ? props.record.receiver.name : ""} onChange={(event) => { }} readOnly={true} />
                    </div>
                </div>
                <div className="p-grid" style={{ width: "100%" }}>
                    <div className="p-col-6 form-field-container">
                        <label>Contato</label>
                        <InputText value={props.record && props.record.receiver ? props.record.receiver.contactName : ""} onChange={(event) => { }} readOnly={true} />
                    </div>
                </div>
                <div className="p-grid" style={{ width: "100%" }}>
                    <div className="p-col-6 form-field-container">
                        <label>Telefone contato</label>
                        <InputText value={props.record && props.record.receiver ? props.record.receiver.contactNumber : ""} onChange={(event) => { }} readOnly={true} />
                    </div>
                </div>
                <div className="p-grid" style={{ width: "100%" }}>
                    <div className="p-col-6 form-field-container">
                        <label>Rua</label>
                        <InputText value={props.record && props.record.receiver ? props.record.receiver.address.street : ""} onChange={(event) => { }} readOnly={true} />
                    </div>
                </div>
                <div className="p-grid" style={{ width: "100%" }}>
                    <div className="p-col-2 form-field-container">
                        <label>Número</label>
                        <InputText value={props.record && props.record.receiver ? props.record.receiver.address.number : ""} onChange={(event) => { }} readOnly={true} />
                    </div>
                    <div className="p-col-4 form-field-container">
                        <label>Complemento</label>
                        <InputText value={props.record && props.record.receiver ? props.record.receiver.address.complement : ""} onChange={(event) => { }} readOnly={true} />
                    </div>
                </div>
                <div className="p-grid" style={{ width: "100%" }}>
                    <div className="p-col-2 form-field-container">
                        <label>Bairro</label>
                        <InputText value={props.record && props.record.receiver ? props.record.receiver.address.district : ""} onChange={(event) => { }} readOnly={true} />
                    </div>
                    <div className="p-col-2 form-field-container">
                        <label>Cidade</label>
                        <InputText value={props.record && props.record.receiver ? props.record.receiver.address.city : ""} onChange={(event) => { }} readOnly={true} />
                    </div>
                    <div className="p-col-2 form-field-container">
                        <label>Estado</label>
                        <InputText value={props.record && props.record.receiver ? props.record.receiver.address.state : ""} onChange={(event) => { }} readOnly={true} />
                    </div>
                </div>
                <div className="p-grid" style={{ width: "100%" }}>
                    <div className="p-col-2 form-field-container">
                        <label>Cep</label>
                        <InputText value={props.record && props.record.receiver ? props.record.receiver.address.zipCode : ""} onChange={(event) => { }} readOnly={true} />
                    </div>
                </div>
            </div>
        </div>
    )
};