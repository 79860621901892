
import { Calendar } from "primereact/calendar";
import { InputTextarea } from "primereact/inputtextarea";
import React from "react";
import { connect } from "react-redux";
import { actions as actionsCollectionOrder } from "../../../../collectionOrderPortalCarrier/collectionOrderPortalCarrier.actions";
import { actions } from "../../../portalCarrier.actions";
import "./componetes.scss"
import { FileUploudScreen } from "../../../../../components/file-upload-screen/FileUploadScreen";
import FileUploaderButtonPreview from "../../../../../components/file-uploader-button-preview/FileUploaderButtonPreview";
import { CARGO_IN_TRANSITO, CARGO_SHIP_DELIVERED } from "../../../constantes";
import { makeMessages, messageActions } from "../../../../../store/message";
import storage from '../../../../../services/stoage.service';
import { InputSwitch } from "primereact/inputswitch";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import moment from "moment";
import { ButtonDialog } from "../../../../../components/button-dialog/ButtonDialog";

const mapStateToProps = store => {
  const fileImg = store.crudPortalCarrierState.fileImg;
  const linkCanhoto = store.crudPortalCarrierState.linkCanhoto;
  const cargoShipStatusInTransito = store.crudPortalCarrierState.cargoShipStatusInTransito;
  const cargoShipStatusDelivereds = store.crudPortalCarrierState.cargoShipStatusDelivereds;
  const expandedRows = store.crudPortalCarrierState.expandedRows;

  const user = storage.getUser();
  let isPermitEditarEmbarque = false;
  if (user.carrier) {
    isPermitEditarEmbarque = true;
  } 
  return {
    fileImg,
    linkCanhoto,
    currentOrderEdit: store.crudPortalCarrierState.currentOrderEdit,
    observation: store.crudPortalCarrierState.observation,
    deliveryDate: store.crudPortalCarrierState.deliveryDate,
    pickupDate: store.crudPortalCarrierState.pickupDate,
    showModalUploadImg: store.crudPortalCarrierState.showModalUploadImg,
    cargoShipStatusInTransito,
    cargoShipStatusDelivereds,
    expandedRows,
    currentScreen: store.crudPortalCarrierState.currentScreen,
    columnsProducts: store.crudPortalCarrierState.columnsProducts,
    isPermitEditarEmbarque, 
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setCodeOrder: order => dispatch(actions.setCodeOrder(order)),
    setShowModalUploadImg: visible => dispatch(actions.setShowModalUploadImg(visible)),
    changeHandlerImage: (fileImg) => dispatch(actions.changeHandlerImage(fileImg)),
    onChangeDeliveryDate: (deliveryDate) => dispatch(actions.onChangeDeliveryDate(deliveryDate)),
    onChangePickupDate: (pickupDate) => dispatch(actions.onChangePickupDate(pickupDate)),
    // onChangeQuantityCollected: (product) => dispatch(actions.onChangeQuantityCollected(product)),
    onChangeObs: (obs) => dispatch(actions.onChangeObs(obs)),
    sendReceipt: (data, calbak) => dispatch(actionsCollectionOrder.sendReceipt(data, calbak)),
    updatePedidoInfo: (dados) => dispatch(actions.updatePedidoInfo(dados)),
    getAllCargoShipmentInTransito: () => dispatch(actions.getAllCargoShipmentInTransito()),
    onChangeClientNaoPossuiMaterial: (naoPossuiMaterial) => dispatch(actions.onChangeClientNaoPossuiMaterial(naoPossuiMaterial)),

    getCargoShipmentDelivered: () => {
      dispatch(actions.loadCargoShipmentDelivered())
    },
    showMessageErro: (msg) => {
      dispatch(messageActions.messageShowMessages(makeMessages([msg], "error"))
      );
    }
  }
}


class UpdateDataEntregaPortalCarrier extends React.Component {
  constructor(props) {
    super(props);

    let entregaNormal = this.props.currentOrderEdit.deliverySuccess
    let entregaCancelada = this.props.currentOrderEdit.deliverySuccess != null ? !this.props.currentOrderEdit.deliverySuccess : false
    if (!entregaNormal && !entregaCancelada) {
      entregaNormal = true//default
    }

    this.state = {
      entregaNormal: entregaNormal,
      entregaCancelada: entregaCancelada,
      product: this.props.currentOrderEdit.product,
      clientNaoPossuiMaterial: this.props.currentOrderEdit.clientNaoPossuiMaterial
    }
  }

  checkIfAllDeliveryWithDate = () => {
    if (this.props.expandedRows && Array(this.props.expandedRows).length > 0) {
      let rowSeletect = this.props.expandedRows[0];
      if (this.props.currentScreen == CARGO_IN_TRANSITO) {

        let cargoShipStatusInTransito = this.props.cargoShipStatusInTransito;
        let indice = -1;
        for (let index = 0; index < cargoShipStatusInTransito.length; index++) {
          if (cargoShipStatusInTransito[index].oid == rowSeletect.oid) {
            indice = index;
          }
        }

        if (indice > 0) {
          let countDeliveryDate = 0;
          for (let index = 0; index < cargoShipStatusInTransito[indice].orders.length; index++) {
            if (cargoShipStatusInTransito[indice].orders[index].occurrence &&
              cargoShipStatusInTransito[indice].orders[index].occurrence.dateOccurrence) {
              countDeliveryDate++;
            }
          }
          if (countDeliveryDate == cargoShipStatusInTransito[indice].orders.length) {

            this.props.getAllCargoShipmentInTransito();
          }
        }
      } else if (this.props.currentScreen == CARGO_SHIP_DELIVERED) {

        let cargoShipStatusDelivereds = this.props.cargoShipStatusDelivereds;
        let indice = -1;
        for (let index = 0; index < cargoShipStatusDelivereds.length; index++) {
          if (cargoShipStatusDelivereds[index].oid == rowSeletect.oid) {
            indice = index;
          }
        }

        if (indice > 0) {
          let countDeliveryDate = 0;
          for (let index = 0; index < cargoShipStatusDelivereds[indice].orders.length; index++) {
            if (cargoShipStatusDelivereds[indice].orders[index].occurrence &&
              cargoShipStatusDelivereds[indice].orders[index].occurrence.dateOccurrence) {
              countDeliveryDate++;
            }
          }
          if (countDeliveryDate == cargoShipStatusDelivereds[indice].orders.length) {

            this.props.getCargoShipmentDelivered();
          }
        }

      }
    }
  }

  onSave = (e) => {
    //validar o usuario se é transp  
    if (this.props.isPermitEditarEmbarque) {

      let file = this.props.fileImg;
      const setShowModalUploadImg = this.props.setShowModalUploadImg;
      const updatePedidoInfo = this.props.updatePedidoInfo;

      const tipFile = typeof file; 
      if (this.props.currentOrderEdit.dv  && !this.props.currentOrderEdit.pickupDate) {

        this.props.showMessageErro("Você deve informar a data da coleta")
        return
      }
      if (!this.props.currentOrderEdit.deliveryDate) { 
        this.props.showMessageErro("Você deve informar a data da entrega")
        return
      }
      if (file && tipFile !== "string") {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        let fileBase64 = "";
        const extension = file.name.split('.').pop();
        reader.onloadend = () => {
          fileBase64 = reader.result
            .replace("data:", "")
            .replace(/^.+,/, "");

          let product = []
          if (this.props.currentOrderEdit.dv) {
            for (let index = 0; index < this.state.product.length; index++) {
              if (this.state.product[index].add) {
                if (this.state.product[index].quantityCollected > 0) {
                  product.push(this.state.product[index])
                }
              } else {
                product.push(this.state.product[index])
              }
            }
          } else {
            product = this.state.product
          }

          const data = {
            "link": this.props.linkCanhoto,
            "code": this.props.currentOrderEdit.code,
            "deliveryDate":this.props.currentOrderEdit.deliveryDate != "" ? this.props.currentOrderEdit.deliveryDate  :null,
            "pickupDate": this.props.currentOrderEdit.pickupDate != "" ? this.props.currentOrderEdit.pickupDate : null,
            "observation": this.props.currentOrderEdit.observation,
            "extension": extension,
            "deliverySuccess": this.state.entregaNormal,
            "file": fileBase64,
            "type": file.type,
            "product": product,
            "clientNaoPossuiMaterial": this.state.clientNaoPossuiMaterial,
            "dv":this.props.currentOrderEdit.dv,
          }
          this.props.sendReceipt(data, (sucess) => {
            if (sucess) {
              setShowModalUploadImg(false);
              updatePedidoInfo(data);
              setTimeout(() => { this.checkIfAllDeliveryWithDate(); }, 1000);
            }
          });
        }
      }
      else {
        let product = []
        if (this.props.currentOrderEdit.dv) {
          for (let index = 0; index < this.state.product.length; index++) {
            if (this.state.product[index].add) {
              if (this.state.product[index].quantityCollected > 0) {
                product.push(this.state.product[index])
              }
            } else {
              product.push(this.state.product[index])
            }
          }
        } else {
          product = this.state.product
        }

        const data = {
          "link": this.props.linkCanhoto,
          "code": this.props.currentOrderEdit.code,
          "deliveryDate": this.props.currentOrderEdit.deliveryDate != "" ? this.props.currentOrderEdit.deliveryDate  :null,
          "observation": this.props.currentOrderEdit.observation,
          "deliverySuccess": this.state.entregaNormal,
          "pickupDate": this.props.currentOrderEdit.pickupDate != "" ? this.props.currentOrderEdit.pickupDate : null,
          "product": product,
          "clientNaoPossuiMaterial": this.state.clientNaoPossuiMaterial,
          "dv":this.props.currentOrderEdit.dv,
        }

        this.props.sendReceipt(data, (sucess) => {
          if (sucess) {
            setShowModalUploadImg(false);
            updatePedidoInfo({ ...data, "file": this.props.fileImg });
            setTimeout(() => { this.checkIfAllDeliveryWithDate(); }, 1000);
          }
        });
      }
    } else {
      this.props.showMessageErro("Somente a transportadora pode alterar o status do embarque")
    }
  }
  onUploadImage = (e) => {
    if (e.target && e.target.files.length > 0) {
      this.props.changeHandlerImage(e.target.files[0])
    }
  }

  showModalUploadImg = (visible) => {
    this.props.setShowModalUploadImg(visible);
    if (!visible) {
      this.props.setCodeOrder(null);
    }
  }

  onChangeDeliveryDate = (event) => {
    const deliveryDate = moment(event.target.value).set("hours", 11).toDate()
    this.props.onChangeDeliveryDate(deliveryDate)
  }
  onChangePickupDate = (event) => {
    const pickupDate = moment(event.target.value).set("hours", 11).toDate()
    this.props.onChangePickupDate(pickupDate)
  }
  onChangeObs = (event) => {
    this.props.onChangeObs(event.target.value)
  }

  onChangeEntregaNormal = (value) => {
    this.setState({ entregaNormal: value });
    this.setState({ entregaCancelada: !value });
    if (this.props.observation && String(this.props.observation).trim() == "Entrega Recusada") {
      this.props.onChangeObs("")
    }
  }
  onChangeEntregaCancelada = (value) => {
    this.setState({ entregaCancelada: value });
    this.setState({ entregaNormal: !value });

    const observation = this.props.observation && String(this.props.observation).trim() != "" ? this.props.observation : "Entrega Recusada"
    this.props.onChangeObs(observation)
  }
  onChangeClientNaoPossuiMaterial = (clientNaoPossuiMaterial) => {
    this.setState({ clientNaoPossuiMaterial });
    const observation = this.props.observation && String(this.props.observation).trim() != "" ? this.props.observation : "Cliente não possui o material"
    this.props.onChangeObs(observation)
    this.props.onChangeClientNaoPossuiMaterial(clientNaoPossuiMaterial)
  }

  onChangeQuantityCollected = (rowData) => {
    // 
    const product = this.state.product;
    if (product) {
      for (let index = 0; index < product.length; index++) {
        if (product[index].code == rowData.code && product[index].batchNumber == rowData.batchNumber
          && product[index].brand == rowData.brand && product[index].name == rowData.name && product[index].quantity == rowData.quantity
          && product[index].id == rowData.id) {
          product[index].quantityCollected = rowData.quantityCollected;
          break
        }
      }
      this.setState({ product })
    }
  }
  onChangePackingName = (rowData) => {
    // 
    const product = this.state.product;
    if (product) {
      for (let index = 0; index < product.length; index++) {
        if (product[index].code == rowData.code && product[index].id == rowData.id) {
          product[index].packingName = rowData.packingName;
          break
        }
      }
      this.setState({ product })
    }
  }
  onChangeName = (rowData) => {
    // 
    const product = this.state.product;
    if (product) {
      for (let index = 0; index < product.length; index++) {
        if (product[index].code == rowData.code && product[index].id == rowData.id) {
          product[index].name = rowData.name;
          break
        }
      }
      this.setState({ product })
    }
  }
  onChangeBatchNumber = (rowData) => {
    // 
    const product = this.state.product;
    if (product) {
      for (let index = 0; index < product.length; index++) {
        if (product[index].code == rowData.code && product[index].id == rowData.id) {
          product[index].batchNumber = rowData.batchNumber;
          break
        }
      }
      this.setState({ product })
    }
  }
  onRemoveMaterial = (rowData) => {
    // 
    if (this.state.product) {
      const product = this.state.product.filter(p => p.code == rowData.code && p.id == rowData.id)
      this.setState({ product })
    }
  }
  onAddNewProduct = () => {
    // 
    const product = this.state.product;
    if (product) {
      const id = product.length - 1
      const newProduct = {
        "code": "add_" + id,
        "add": true,
        "name": "",
        "weight": 0,
        "quantity": 0,
        "quantityCollected": 0,
        "bagToPallet": 0,
        "bbgToPallet": 0,
        "bags": 0,
        "brand": "",
        "batchNumber": "",
        "packingName": "",
        "id": id
      }
      product.push(newProduct);

      this.setState({ product })
    }
  }

  render() {

    const deliveryDate = this.props.deliveryDate// ? this.props.currentOrderEdit.deliveryDate : "";
    const pickupDate = this.props.pickupDate// ? this.props.currentOrderEdit.deliveryDate : "";
    const observation = this.props.observation// ? this.props.currentOrderEdit.observation : "";
    const btnSaveEnabled = this.state.entregaNormal || this.state.entregaCancelada;


    const imputQtdeColetadaTemplate = (rowData, column) => {
      try {
        return (
          <div style={{ textAlign: "center" }}>
            <InputText type="number" style={{ width: "100%" }} placeholder="Informe qtd coletada" value={rowData.quantityCollected} onChange={(e) => {
              try {
                let qtd = parseFloat(e.target.value)
                if (qtd != NaN) {
                  rowData.quantityCollected = qtd
                  this.onChangeQuantityCollected(rowData)
                } else {
                  rowData.quantityCollected = 0
                  this.onChangeQuantityCollected(rowData)
                }

              } catch (error) {
                console.log("Erro:", error)
              }
            }} />
          </div>
        );
      } catch (error) {
        return <div></div>;
      }
    };
    const packingNameTemplate = (rowData, column) => {
      try {
        return (
          <div style={{ textAlign: "center" }}>
            <InputText type="text" style={{ width: "100%" }} disabled={!rowData.add} placeholder="Informe Big Bag, Saco..." value={rowData.packingName} onChange={(e) => {
              try {
                let packingName = e.target.value
                rowData.packingName = packingName
                this.onChangePackingName(rowData)
              } catch (error) {
                console.log("Erro:", error)
              }
            }} />
          </div>
        );
      } catch (error) {
        return <div></div>;
      }
    };
    const nameTemplate = (rowData, column) => {
      try {
        return (
          <div style={{ textAlign: "center" }}>
            <InputText type="text" style={{ width: "100%" }} disabled={!rowData.add} placeholder="Informe nome material" value={rowData.name} onChange={(e) => {
              try {
                let name = e.target.value
                rowData.name = name
                this.onChangeName(rowData)
              } catch (error) {
                console.log("Erro:", error)
              }
            }} />
          </div>
        );
      } catch (error) {
        return <div></div>;
      }
    };
    const batchNumberTemplate = (rowData, column) => {
      try {
        return (
          <div style={{ textAlign: "center" }}>
            <InputText type="text" style={{ width: "100%" }} disabled={!rowData.add} placeholder="Informe nome lote" value={rowData.batchNumber} onChange={(e) => {
              try {
                let batchNumber = e.target.value
                rowData.batchNumber = batchNumber
                this.onChangeBatchNumber(rowData)
              } catch (error) {
                console.log("Erro:", error)
              }
            }} />
          </div>
        );
      } catch (error) {
        return <div></div>;
      }
    };
    const btnRemoveTemplate = (rowData, column) => {
      try {
        return (
          <div>
            <ButtonDialog
              onClick={(e) => {
                this.onRemoveMaterial(rowData);
              }}
              button={{
                title: "Remover",
                className: "lognex-btn-danger",
                disabled: !rowData.add
              }}
              dialog={{
                header: "Confirmação",
                modal: false,
                message: "Deseja realmente remover esse material",
              }}
            ></ButtonDialog>
          </div>
        );
      } catch (error) {
        return <div></div>;
      }
    };

    let products = []
    if (this.state.product) {
      products = this.state.product;
      console.log("products:", products)
    }
    const columnsProducts = this.props.columnsProducts.map((col) => {

      if (col.field === "quantityCollected") {
        return <Column {...col} key={col.field} body={imputQtdeColetadaTemplate} />;
      }
      if (col.field === "quantity") {
        return <Column {...col} key={col.field} style={{ width: "80px" }} />;
      }
      if (col.field === "packingName") {
        return <Column {...col} key={col.field} body={packingNameTemplate} />;
      }
      if (col.field === "name") {
        return <Column {...col} key={col.field} body={nameTemplate} />;
      }
      if (col.field === "batchNumber") {
        return <Column {...col} key={col.field} body={batchNumberTemplate} />;
      }
      if (col.field === "btnRemove") {
        return <Column {...col} key={col.field} body={btnRemoveTemplate} />;
      }
      return <Column {...col} key={col.field} />;
    })
    return (

      <FileUploudScreen
        visible={this.props.showModalUploadImg}
        onHide={(e) => this.showModalUploadImg(false)}>

        <div className="container-comprovante">
          {this.state.entregaNormal &&
            <div className="content-upload-portal">
              <FileUploaderButtonPreview
                title="Enviar Comprovante de Entrega"
                onUpload={(e) => this.onUploadImage(e)}
                fileImg={this.props.fileImg}
                label="Imagem do comprovante aqui!"
                accept="image/png, image/jpeg, image/jpg"
              >
              </FileUploaderButtonPreview>
            </div>
          }

          <div className="p-grid" style={{ width: "400px", margin: "auto" }}>
            <div className="p-col-12 content-check">

              <div className="align-inline-up-canhoto" onClick={(e) => { this.onChangeEntregaNormal(true); }}>
                <InputSwitch
                  checked={this.state.entregaNormal}
                  onChange={(e) => { this.onChangeEntregaNormal(true); }}
                  key={"canhoto-success"}
                  id="canhoto-success"
                />
                <div className="space-10px"></div>
                <label htmlFor="canhoto-success">Entrega realizada</label>
              </div>


              <div className="align-inline-up-canhoto" onClick={(e) => { this.onChangeEntregaCancelada(true) }} >
                <InputSwitch
                  checked={this.state.entregaCancelada}
                  onChange={(e) => { this.onChangeEntregaCancelada(true); }}
                  key={"canhoto-cancelada"}
                  id="canhoto-cancelada"
                />
                <div className="space-10px"></div>
                <label htmlFor="canhoto-cancelada">Entrega Recusada</label>
              </div>
            </div>


            <div className={"p-col-12 form-field-container"}>

              <div className="p-col-12 form-field-container">
                <label>{this.props.currentOrderEdit.dv? "Data da entrega ou Previsão entrega": "Data da entrega"}</label>
                <Calendar
                  showIcon={true}
                  name="deliveryDate"
                  value={deliveryDate}
                  hourFormat="24"
                  showTime={false}
                  dateFormat="dd/mm/yy"
                  onChange={this.onChangeDeliveryDate}
                  disabled={false}
                />
              </div>

              <div className="p-col-12 form-field-container">
                <label>Observação</label>
                <InputTextarea
                  rows={10}
                  cols={30}
                  name="observation"
                  value={observation}
                  onChange={this.onChangeObs}
                />
              </div>

              {this.props.currentOrderEdit.dv &&
                <div>
                  <div className="p-col-12 form-field-container">
                    <label>Data da coleta</label>
                    <Calendar
                      showIcon={true}
                      name="pickupDate"
                      value={pickupDate}
                      hourFormat="24"
                      showTime={false}
                      dateFormat="dd/mm/yy"
                      onChange={this.onChangePickupDate}
                      disabled={false}
                    />
                  </div>
                  <div className="align-inline-up-canhoto checkbox-inline-right" onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.onChangeClientNaoPossuiMaterial(!this.state.clientNaoPossuiMaterial);

                  }}>
                    <InputSwitch
                      checked={this.state.clientNaoPossuiMaterial}
                      key={"clientNaoPossuiMaterial"}
                      id="clientNaoPossuiMaterial"
                    />
                    <div className="space-10px"></div>
                    <label htmlFor="clientNaoPossuiMaterial">Cliente não possui o material</label>
                  </div>
                </div>
              }

            </div>

          </div>

          {this.props.currentOrderEdit.dv &&
            <div className="p-grid" style={{ width: "100%", marginTop: "5px" }}>
              <div className="p-col-12 lxActionButtonsComprovant">
                <button
                  className={"lognex-btn-highlighted-inverse"}
                  style={{
                    width: "190px",
                    cursor: "pointer",
                    height: "27px"
                  }}
                  onClick={this.onAddNewProduct}
                >
                  <div className="">
                    <i className="pi pi-plus">INCLUIR MATERIAL</i>
                  </div>
                </button>
              </div>

              <div className={"p-col-12 form-field-container"}>
                <DataTable
                  selectionMode="single"
                  value={products}
                  emptyMessage="Não foram encontrados registros"
                >
                  {columnsProducts}
                </DataTable>
              </div>
            </div>
          }

          <div className="p-grid" style={{ width: "100%" }}>
            <div className="p-col-12 lxActionButtonsComprovant">
              <div>
                <button
                  disabled={!btnSaveEnabled}
                  title="Enviar Comprovante"
                  onClick={this.onSave}
                  style={{ marginRight: ".25em" }}
                  className="lognex-btn-highlighted">
                  <i className="pi pi-save"></i>
                </button>
                Salvar
              </div>
            </div>
          </div>
        </div>
      </FileUploudScreen>
    )

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateDataEntregaPortalCarrier);
