import React from "react";
import { InputText } from "primereact/inputtext";

export const VehicleTypeForm = (props) => {
  const record = props.record;

  return (
    <div className="vehicletype-form">
      <div className="p-grid">
        <div className="p-col-6">
          <div className="p-grid">
            <div className="p-col-3 form-field-container">
              <label>Código</label>
              <InputText value={record.oid} onChange={props.changeHandler} name="oid" disabled={true} />
            </div>
            <div className="p-col-12 form-field-container">
              <label cassName="lxRequiredField">Nome</label>
              <InputText value={props.record.name} onChange={props.changeHandler} name="name" />
            </div>
            <div className="p-col-12 form-field-container">
              <label cassName="lxRequiredField">Label</label>
              <InputText value={props.record.code} onChange={props.changeHandler} name="code" />
            </div>
            <div className="p-col-12 form-field-container">
              <label cassName="lxRequiredField">Velocidade média (KM)</label>
              <InputText value={props.record.averageSpeed} onChange={props.changeHandler} name="averageSpeed" />
            </div>
            <div className="p-col-12 form-field-container">
              <label cassName="lxRequiredField">Capacidade de Peso (Kg)</label>
              <InputText value={props.record.weight} onChange={props.changeHandler} type={"Number"} name="weight" />
            </div>

            <div className="p-col-12 form-field-container">
              <label cassName="lxRequiredField">Capacidade de Pallets</label>
              <InputText value={props.record.pallets} onChange={props.changeHandler} type={"Number"} name="pallets" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
