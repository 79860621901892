import { types } from "./driver.actions";
import { Reducer } from "../../services/reducer.factory";



const initialState = {
    currentRecord: {
        oid: "",
        name: "",
        code: "",
        password: "",
        documentNumber: "",
        dateValidateCNH: "",
    },
    columns: [
        {
            field: "oid",
            header: "Código",
            columnKey: "oid",
            sortable: false,
            filter: true
        },
        {
            field: "name",
            header: "Nome",
            columnKey: "name",
            sortable: false,
            filter: true
        },
        {
            field: "documentNumber",
            header: "Número do documento",
            columnKey: "documentNumber",
            sortable: false,
            filter: true
        },
    ],

}


const reducer = new Reducer(initialState, types);

export function CRUDDriverReducer(state, action) {
    state = reducer.execute(state, action);
    return execute(state, action);
}

function execute(state, action) {
    let result = state;
    switch (action.type) {
        case types.SET_USER_ID:
            return setUserId(state, action)
        case types.SET_VALIDATE_CNH:
            return setValidateCNH(state, action)
        default:
            result = state;
            break;
    }
    return result;
}

function setValidateCNH(state, action) {
    let currentRecord = state.currentRecord;
    currentRecord.dateValidateCNH = action.date;
    return { ...state, currentRecord }
}


function setUserId(state, action) {
    let currentRecord = state.currentRecord;
    currentRecord.userId = action.userId;
    return { ...state, currentRecord }
}

