import React from "react";
import { Dialog } from 'primereact/dialog';
import { Calendar } from "primereact/calendar";
import Moment from 'moment';

import "./ModalDate.scss";

export class ModalDate extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            date: props.initialDate,
            visible: props.visible ? props.visible : false
        }
    }

    onHide = (e) => {
        if (this.props.onHide) {
            this.props.onHide(e);
        }
    }

    onConfirm = () => {
        if(this.props.onConfirm){
            this.props.onConfirm(this.state.date);
        }
    }
    componentWillUpdate(){}



    componentWillReceiveProps(props){
        if(props.visible != this.state.visible){
            this.setState((prevState)=>{return{ visible: props.visible  }})
        }
    }

    render() {
        const footer = (
            <div>
              <button
                title="Confirmar"
                className={"lognex-btn-highlighted"}
                onClick={this.onConfirm}
              >
                <i className="pi pi-check"></i>
              </button>
              <button
                title="Cancelar"
                className="lognex-btn-danger"
                onClick={this.onHide}
              >
                <i className="pi pi-times"></i>
              </button>
            </div>
        )

        return (
            <div className="modal-date">
                <Dialog
                header={this.props.header}
                onHide={this.onHide}
                visible={this.state.visible}
                footer={footer}>
                    
                    <div>
                        <p className="data">{ Moment(this.state.date).format("DD/MM/YYYY") || " " }</p>
                        <Calendar 
                            inline={true} 
                            dateFormat="dd/mm/yy" 
                            readOnlyInput={true}
                            disabledDays={[0]}
                            value={this.state.date} 
                            onChange={(e) => this.setState({date: e.value})}
                        />
                    </div>
                    
                
                </Dialog>
            </div>
        );
    }
} 