import React from "react";
import { connect } from "react-redux";
import { Page } from "../../../components/page/Page";
import { actions } from "../dashboard-nfe.actions";
import { MapContainer, Marker, Polyline, Popup, TileLayer, useMap } from 'react-leaflet';
import L, { Icon, map, marker, point } from "leaflet";
import polyUtil from "@mapbox/polyline";
import { actions as accountConfigurationActions } from "../../accountConfiguration/accountConfiguration.actions";
import loader from "../../../assets/images/loader.svg";
import { AppService, COMPANY_CHANGE_EVENT_NAME, BUSINESS_UNIT_CHANGE_EVENT_NAME } from "../../../services/app.service";
import moment from "moment";
import "./DashboardNfeListPageContainer.scss";
import { DashboardNfeFilter } from "../components/DashboardNfeFilter";
import { InputText } from "primereact/inputtext";
import pin_vehicle_origem from "../../../assets/images/icons/truck_origem.png";
import pin_home_origin from "../../../assets/images/icons/icon-cd.svg";



function ChangeView({ polyline }) {
  const map = useMap();

  React.useEffect(() => {
    if (polyline.length > 0) {
      const bounds = L.latLngBounds(polyline);
      map.fitBounds(bounds);
    }
  }, [polyline, map]);

  return null;
}
const mapStateToProps = (store) => {
  const record = store.crudDashboardNfeState.currentRecord;
  const accountConfiguration = store.crudAccountConfigurationState.currentRecord;
  const { cargoManager = { useNfeDashboard: false } } = store.crudAccountConfigurationState.currentRecord;

  // console.log("accountConfiguration:", accountConfiguration)
  return {
    nfeNumberFilter: store.crudDashboardNfeState.nfeNumberFilter,
    isLoading: store.crudDashboardNfeState.isLoading,
    first: store.crudDashboardNfeState.index,
    rows: store.crudDashboardNfeState.max,
    totalRecords: store.crudDashboardNfeState.count,
    sortField: store.crudDashboardNfeState.sortField,
    order: store.crudDashboardNfeState.sortOrder,
    record,
    filters: store.crudDashboardNfeState.filters,
    accountConfiguration,
    cargoManager
  };
};
const positionCenter = [-16.4677031, -49.707108];

const mapDispatchToProps = (dispatch) => {
  return {
    applyFilter: (filterParams) => {
      dispatch(actions.findNfe(filterParams));
    },

    nfeNumberChangeHandler: ({ target }) => {
      dispatch(actions.onChangeNfeNumberFilter(target.value));
    },

    setExpandedTrackingRows: (expandedTrackingRows) => {
      const record = expandedTrackingRows[1]; //* By now we will always have just one expanded
      dispatch(actions.setRecord(record));
    },

    showModalDeliveryDate: () => {
      dispatch(actions.showModalDeliveryDateVisible(true));
    },

    selectedRecord: (record) => {
      dispatch(actions.selectedRecord(record));
    },
    accountConfigurationLoad: () => dispatch(accountConfigurationActions.loadAccountConfigurationsByCompany()),
  };
};

class DashboardNfeListPageContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expandedRows: null,
      showViewModalImage: false,
      spin: false,
      currentImagePath: "",
      countRotation: 0,

      positions: [],
      initialized: false, // Controla se a vista já foi inicializada
      route: null,
      polyline: [],
      postionStart: [],
      iconSize: point(34, 34),
      // iconAnchor: [30, 28],
      iconAnchor: null,
      stepsPikupPosition: [],
    };
  }

  componentDidMount() {
    this.applyTrackingFilter();
    AppService.on(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.on(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
    this.props.accountConfigurationLoad();

  }
  componentWillUnmount() {
    AppService.off(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.off(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
  }

  onReceiveAppNotifications = (eventName, params) => {
    if (eventName === COMPANY_CHANGE_EVENT_NAME || eventName === BUSINESS_UNIT_CHANGE_EVENT_NAME) {
      this.applyTrackingFilter();
      this.props.accountConfigurationLoad();
    }
  };

  getIconTruck = () => {
    return new Icon({
      iconUrl: pin_vehicle_origem,
      iconRetinaUrl: pin_vehicle_origem,
      iconSize: this.state.iconSize,
      // iconAnchor: iconAnchor,
    })
  }
  getIconPickup = (index) => {
    return new Icon({
      iconUrl: pin_home_origin,
      iconRetinaUrl: pin_home_origin,
      iconSize: point(38, 38),
      iconAnchor: null,
    })
  }
  getFilterTrackingFilter = () => {
    const nfeNumberFilter = this.props.nfeNumberFilter && this.props.nfeNumberFilter !== "" ? this.props.nfeNumberFilter : null;

    const filters = [];
    if (nfeNumberFilter) {
      filters.push({
        campo: "nfeNumber",
        valor: nfeNumberFilter,
        tipoFiltro: "IGUAL_A",
      });
    }
    return filters;
  };

  applyTrackingFilter = () => {
    const filterParamsTrackingFilter = this.getFilterTrackingFilter();
    if (filterParamsTrackingFilter.length > 0) {
      this.props.applyFilter(filterParamsTrackingFilter);
    }
  };

  onClickSelectedDeliveryDate = (record) => {
    this.props.showModalDeliveryDate();
    this.props.selectedRecord(record);
  };

  showVisibleModalImage = (visible) => {
    this.setState({
      showViewModalImage: visible,
    });
  };

  render() {

    const renderNfeData = () => {

      if (this.props.record) {
        const carrier = this.props.record.carrier;
        return (
          <div className="content-lines-inf">
            {/* <DashboardNfeFilter
              nfeNumberFilter={this.props.nfeNumberFilter}
              nfeNumberChangeHandler={this.props.nfeNumberChangeHandler}
              applyTrackingFilter={this.applyTrackingFilter}
            /> */}

            <div style={{ display: "inline-flex" }}>
              <div className="line-dash-inf-nfe" style={{ width: "136px" }}>
                <label>Nota Fiscal</label>
                <InputText value={this.props.nfeNumberFilter ? this.props.nfeNumberFilter : ""} onChange={this.props.nfeNumberChangeHandler} name="nfeNumberFilter" />
              </div>
              <div className="line-dash-inf-nfe ">
                <br />
                <button style={{ width: "120px" }}
                  className="lognex-btn-highlighted"
                  title="Filtrar"
                  onClick={this.applyTrackingFilter}>
                  Filtrar
                </button>
              </div>
            </div>
            <div >
              {this.props.isLoading && (
                <div className="loading-content">
                  <div className="loader-center">
                    <img src={loader} alt="loader"></img>
                    <span>Carregando..</span>
                  </div>
                </div>
              )}
            </div>
            <div className="line-dash-inf-nfe">
              <p>CLIENTE</p>
              <div className="line-dash-inf-nfe-item">{this.props.record.receiver}</div>
            </div>
            <div className="line-dash-inf-nfe">
              <p>NFe Nº</p>
              <div className="line-dash-inf-nfe-item">{this.props.record.number}</div>
            </div>

            <div className="line-dash-inf-nfe">
              <p>NFe Série</p>
              <div className="line-dash-inf-nfe-item">{this.props.record.sequence}</div>
            </div>
            <div className="line-dash-inf-nfe">
              <p>NFe Chave</p>
              <div className="line-dash-inf-nfe-item">{this.props.record.key}</div>
            </div>
            <div className="line-dash-inf-nfe">
              <p>Destino</p>
              <div className="line-dash-inf-nfe-item">{this.props.record.destination}</div>
            </div>
            <div className="line-dash-inf-nfe">
              <p>Transportadora</p>
              <div className="line-dash-inf-nfe-item">{carrier?.name}</div>
            </div>
          </div>
        )
      } else {
        return <></>
      }
    }
    const renderNfeDataCards = () => {
      if (this.props.record && this.props.record.issueDate) {
        // console.log("this.props.record:", this.props.record)
        let dataImissao = moment(this.props.record.issueDate).format("DD/MM/YYYY")
        let dataPrevissao = "Falta cadastro"

        if (this.props.record.deliveryDeadlineDate) {
          dataPrevissao = moment(this.props.record.deliveryDeadlineDate).format("DD/MM/YYYY")
        }
        let emissor = this.props.record.emitter
        if (String(emissor).length > 30) {
          emissor = String(emissor).substring(0, 25)
          emissor += "..."
        }

        let situacao = "PENDENTE"
        if (this.props.record.deliveryStatus) {
          switch (this.props.record.deliveryStatus) {
            case "IN_TIME":
              situacao = "No prazo";
              break;
            case "ON_TIME":
              situacao = "Prazo vence hoje";
              break;
            case "LATE":
              situacao = "Prazo vencido";
              break;
            case "DELIVERED_LATE":
              situacao = "Entregue fora do prazo";
              break;
            case "DELIVERED_IN_TIME":
              situacao = "Entregue no prazo";
              break;
            default:
              break;
          }
        }
        let numberCte = "";
        if (this.props.record.financeiro && this.props.record.financeiro.numberCte) {
          numberCte = this.props.record.financeiro.numberCte
        }
        let financeiro = "CT-e indisponível"
        if (this.props.record.financeiro && this.props.record.financeiro.auditSituation != null && this.props.record.financeiro.auditSituation.status) {
          switch (this.props.record.financeiro.auditSituation.status) {
            case "NOT_AUDITED":
              financeiro = "Pendente";
              break;
            case "APPROVED":
              financeiro = "Aprovado";
              break;
            default:
              financeiro = this.props.record.financeiro.auditSituation.status
              break;
          }
        }
        let numTabelaFrete = "";
        let nameTabelaFrete = "CT-e indisponível";
        if (this.props.record.tabelaFrete && this.props.record.tabelaFrete.idTabelaFrete) {
          numTabelaFrete = this.props.record.tabelaFrete.idTabelaFrete
          nameTabelaFrete = this.props.record.tabelaFrete.nameTabelaFrete
          if (String(nameTabelaFrete).length > 18) {
            nameTabelaFrete = String(nameTabelaFrete).substring(0, 15)
            nameTabelaFrete += "..."
          }
        }
        return (
          <div className="content-cads-nfe">
            <div className="grid-box" >
              <h1 className="grid-box__title">{"EMISSÃO"}</h1>

              <p className="content">{dataImissao}</p>

              <div className="footer">{emissor}</div>
            </div>

            <div className="grid-box" >
              <h1 className="grid-box__title">{"PREVISÃO"}</h1>

              <p className="content">{dataPrevissao}</p>

              <div className="footer">{ }</div>
            </div>

            <div className="grid-box" >
              <h1 className="grid-box__title">{"SITUAÇÃO"}</h1>

              <p className="content">{situacao}</p>

              <div className="footer">{ }</div>
            </div>

            <div className="grid-box" >
              <h1 className="grid-box__title">{"FINANCEIRO"}</h1>

              <p className="content">{financeiro}</p>

              <div className="footer">{numberCte}</div>
            </div>

            <div className="grid-box" >
              <h1 className="grid-box__title">{"TABELA DE FRETE"}</h1>
              <p className="content">{numTabelaFrete}</p>
              <div className="footer">{nameTabelaFrete}</div>
            </div>
          </div>
        )
      } else {
        return <></>
      }
    }
    const getMap = () => {
      if (this.props.record && this.props.record.issueDate) {
        let polylines = polyUtil.decode(this.props.record.geometry, 6)
        return (
          <div className="map-dash-nfe">
            <MapContainer center={positionCenter} zoom={4} style={{ height: '400px' }}>
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />

              {this.props.record.originLocation &&
                <Marker icon={this.getIconTruck()} zIndexOffset={2} key={"startP"} position={this.props.record.originLocation}>
                </Marker>
              }

              {this.props.record.destinationLocation &&
                <Marker zIndexOffset={2} key={"endP"} position={this.props.record.destinationLocation}>
                </Marker>
              }
              <Polyline positions={polylines} weight={8} />
              <ChangeView polyline={polylines} />

            </MapContainer>
          </div>
        );
      } else {
        return <></>
      }
    };

    const getInfoNfeDashboad = () => {
      return (
        <div className="p-grid">

          {/* <div className="p-col-12">
              <DashboardNfeFilter
                nfeNumberFilter={this.props.nfeNumberFilter}
                nfeNumberChangeHandler={this.props.nfeNumberChangeHandler}
                applyTrackingFilter={this.applyTrackingFilter}
              />
            </div> */}
          <div className="p-col-12">
            {renderNfeData()}
          </div>
          <div className="p-col-6">
            {renderNfeDataCards()}
          </div>
          <div className="p-col-6">
            {getMap()}
          </div>
        </div>
      );
    };

    if (this.props.cargoManager.useNfeDashboard) {
      const content = (
        <div className="lxDashboardNfe">
          {getInfoNfeDashboad()}
        </div>
      );
      return content;
    } else {
      return <div></div>
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardNfeListPageContainer);
