
import React from 'react';
import "./portal-form.scss"
import { TowerTravelVisualizeMapPortalListPage } from '../../towerTravelVisualizeMap';
import { ORIGEM_PORTAL_TRANSPORTADOR } from '../../towerDashboard/constants';
import { LookupField } from '../../../components/lookup/LookupField';
export const PortalCarrierForm = (props) => {

    return (
        <div className="portal-form">
            <div className="p-grid">
                {!props.userIsCarrier &&
                    <div className="p-col-12">
                        <div className="p-col-6 form-field-container">
                            <label>Transportadora</label>
                            <LookupField
                                value={props.carrier ? props.carrier.name : ""}
                                placeholder="Transportadora"
                                name="carrier.name"
                                field="name"
                                onComplete={props.lookupCarrier.lookupCarrierOnComplete}
                                onInputFilter={props.lookupCarrierOnInputFilter}
                                editDisabled={props.lookupCarrier.editDisabled}
                                onEditField={props.lookupCarrierOnEdit}
                                onClick={props.lookupCarrier.lookupCarrierOnClick}

                                visible={props.lookupCarrier.visible}
                                modal={props.lookupCarrier.modal}
                                header={props.lookupCarrier.header}
                                onHide={props.lookupCarrier.lookupCarrierOnHide}
                                onConfirm={props.lookupCarrier.lookupCarrierOnConfirm}
                                onCancel={props.lookupCarrier.lookupCarrierOnCancel}
                                records={props.lookupCarrier.records}
                                columns={props.lookupCarrier.columns}
                                totalRecords={props.lookupCarrier.totalRecords}
                                sortOrder={props.lookupCarrier.order}
                                sortField={props.lookupCarrier.sortField}
                                first={props.lookupCarrier.first}
                                rows={props.lookupCarrier.rows}
                                onPage={props.lookupCarrier.lookupCarrierOnPage}
                                onFilter={props.lookupCarrier.lookupCarrierOnFilter}
                                onSort={props.lookupCarrier.lookupCarrierOnOnSort}
                                paginator={true}
                                rowsPerPageOptions={[5, 10, 20, 50]}
                            />
                        </div>
                    </div>
                }
                <div className="p-col-6">

                    <div className="p-grid container-interno">
                        <div className="p-col-12">
                            <h2>Gerencie suas cargas</h2>
                        </div>
                        <div className="p-col-12 backgroundColor">
                            <div className="p-grid-custom">
                                <div className="card-portal" onClick={props.onClickCollectionOrders}>

                                    <div className={`card-circulo ${props.collectionOrdersPendentAceite > 0 ? 'card-circulo-green' : ''} `}>
                                        <span className='span-value'>{props.collectionOrdersPendentAceite}</span>
                                    </div>

                                    <h2>Ordem de Coleta</h2>
                                    <div className='content-item'>
                                        <h5>Verifique suas novas solicitações de coletas e realize os aceite</h5>
                                    </div>
                                </div>
                                <div className="card-portal" onClick={props.onClickCollectionOrdersWithPendences}>

                                    <div className={`card-circulo ${props.totalPendencias > 0 ? 'card-circulo-red' : ''} `}>
                                        <span className='span-value'>{props.totalPendencias}</span>
                                    </div>

                                    <h2>Pendências</h2>
                                    <div className='content-item'>
                                        <h5>{"Verifique suas pendências"}</h5>
                                        <h5>{"-Informar placa de veículos"}</h5>
                                        <h5>{"-Informar de motoritas"}</h5>
                                        <h5>{"-Verifica integração com rastreadores"}</h5>
                                    </div>
                                </div>
                                <div className="card-portal" onClick={props.onClickInCollectionOrder}>

                                    <div className={`card-circulo ${props.totalInCollectionOrder > 0 ? 'card-circulo-orange' : ''} `}>
                                        <span className='span-value'>{props.totalInCollectionOrder}</span>
                                    </div>

                                    <h2>Em Coleta</h2>
                                    <div className='content-item'>
                                        <h5>{"Verifique se as coletas foram realizadas"}</h5>
                                        <h5>-Altere o status da carga para <b>Em Trânsito</b></h5>
                                    </div>
                                </div>

                                <div className="card-portal" onClick={props.onClickInCargoShipmentIntTransito}>

                                    <div className={`card-circulo ${props.totalInTravel > 0 ? 'card-circulo-orange' : ''} `}>
                                        <span className='span-value'>{props.totalInTravel}</span>
                                    </div>

                                    <h2>Em Trânsito</h2>
                                    <div className='content-item'>
                                        <h5>Verifique as entregas</h5>
                                        <h5>Inclua a data da entrega</h5>
                                        <h5>Inclua a imagem do canhoto</h5>
                                    </div>
                                </div>

                                <div className="card-portal" onClick={props.onClickInCargoShipmentDelivered}>

                                    <div className={`card-circulo  `}>
                                        <span className={`span-value`}>{props.totalEntregas}</span>
                                    </div>

                                    <h2>Entregas</h2>
                                    <div className='content-item'>
                                        <h5>Verifique as cargas que já foram entregues</h5>
                                        <h5>Período ultimos <b>90 dias</b></h5>
                                    </div>
                                </div>

                                <div className="card-portal">

                                    <div className={`card-circulo`}>
                                        <span className='span-value'>{props.totalAgendamentos}</span>
                                    </div>

                                    <h2>Agendamentos</h2>
                                    <div className='content-item'>
                                        <h5>Verifique seus agendamentos em aberto</h5>
                                        <h5>-Docas</h5>
                                        <h5>-Horarios</h5>
                                    </div>
                                </div>
                                <div className="card-portal">

                                    <div className={`card-circulo ${props.totalMensagens > 0 ? 'card-circulo-red' : ''} `}>
                                        <span className='span-value'>{props.totalMensagens}</span>
                                    </div>

                                    <h2>Mensagens</h2>
                                    <div className='content-item'>
                                        <h5>Verifique suas mensagens</h5>
                                        <h5>-Veículos atrasados</h5>
                                        <h5>-Divergências finaceiras</h5>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="p-col-12">
                            <h2>Históricos de cargas</h2>
                        </div>
                        <div className="p-col-12 backgroundColor">
                            <div className="p-grid-custom">
                                <div className="card-portal">

                                    <div className={`card-circulo`}>
                                        <span className='span-value'>{props.totalConfirmadas}</span>
                                    </div>

                                    <h2>Confirmadas</h2>
                                    <div className='content-item'>
                                        <h5>Coletas confirmadas nos ultimos 30 dias</h5>
                                    </div>
                                </div>
                                <div className="card-portal">

                                    <div className={`card-circulo ${props.totalRecusados > 0 ? 'card-circulo-red' : ''} `}>
                                        <span className='span-value'>{props.totalRecusados}</span>
                                    </div>

                                    <h2>Recusadas</h2>
                                    <div className='content-item'>
                                        <h5>{"Coletas recusadas nos ultimos 30 dias"}</h5>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-col-6">

                    <div className=" p-grid container-interno">
                        <div className="p-col-12">
                            <h2>Gerencie sua conta</h2>
                        </div>
                        <div className="p-col-12 backgroundColor">
                            <div className="p-grid-custom">
                                <div className="card-portal" onClick={props.onClickCadastroVeiculos}>

                                    <div className={`card-circulo`}>
                                        <span className='span-value'>{props.totalVeiculosCadastrados}</span>
                                    </div>

                                    <h2>Cadastro de Veículo</h2>
                                    <div className='content-item'>
                                        <h5>Verifica e inclua ou exclua</h5>
                                        <h5>Veículos</h5>
                                    </div>
                                </div>
                                <div className="card-portal" onClick={props.onClickRegisterDriver}>

                                    <div className={`card-circulo`}>
                                        <span className='span-value'>{props.totalRegisteredDriver}</span>
                                    </div>

                                    <h2>Cadastro de Motorista</h2>
                                    <div className='content-item'>
                                        <h5>Verifica e inclua ou exclua</h5>
                                        <h5>Motoristas</h5>
                                    </div>
                                </div>
                                <div className="card-portal">

                                    <div className={`card-circulo`}>
                                        <span className='span-value'>{props.totalVeiculosViagens}</span>
                                    </div>

                                    <h2>Consultas</h2>
                                    <div className='content-item'>
                                        <h5>Veículos em viagens abertas</h5>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    {/* Torre de controle */}
                    <div className=" p-grid container-interno">
                        <div className="p-col-12">
                            <h2>Torre de controle</h2>
                        </div>
                        <div className="p-col-12 backgroundColor">
                            <div className="p-grid-custom">
                                <TowerTravelVisualizeMapPortalListPage origem={ORIGEM_PORTAL_TRANSPORTADOR} />
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    )
};

/*
 <div className="p-grid">
                <div className="p-col-6">

                    <div className="p-grid container-interno">
                        <div className="p-col-12">
                            <h2>Gerencie suas cargas</h2>
                        </div>
                        <div className="p-col-12 backgroundColor">
                            <div className=" p-grid">
                                dsdf
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-col-6">

                    <div className=" p-grid container-interno">
                        <div className="p-col-12">
                            <h2>Gerencie sua conta</h2>
                        </div>
                        <div className="p-col-12 backgroundColor">
                            <div className="p-grid">
                                dsdf
                            </div></div>
                    </div>
                </div>
            </div>*/