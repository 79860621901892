
import React from 'react';
import { connect } from 'react-redux';
import { actions } from '../portalCarrier.actions';
import { actions as actionsMapTowerControl } from '../../towerTravelVisualizeMap/tower_travel_visualize_map.actions';
import { actions as carrierActions } from "../../carrier/carrier.actions";
import storage from '../../../services/stoage.service'
import { PortalCarrierForm } from './PortalCarrierForm';
import { AppService, COMPANY_CHANGE_EVENT_NAME } from '../../../services/app.service';
import { routes } from '../../../constants';
import moment from 'moment';
import { Column } from 'primereact/column';
import { ORIGEM_PORTAL_TRANSPORTADOR, TRAVELING } from '../../towerDashboard/constants';

const mapStateToProps = state => {
  const record = state.crudPortalCarrierState.currentRecord;


  const user = storage.getUser();
  let userIsCarrier = true;
  if (!user.carrier) {
    userIsCarrier = false;
  }

  const lookupCarrierBase = {
    records: state.crudCarrierState.records,
    columns: state.crudPortalCarrierState.lookupCarrier.visible
      ? state.crudCarrierState.columns.filter((col) => col.field === "oid" || col.field === "name" || col.field === "tradeName").map((col) => <Column key={col.field} {...col} />)
      : [<Column key={"columnKeyCarrier"} />],
    first: state.crudCarrierState.index,
    rows: state.crudCarrierState.max,
    totalRecords: state.crudCarrierState.count,
    sortField: state.crudCarrierState.sortField,
    order: state.crudCarrierState.sortOrder,
    editDisabled: true,
  };
  const lookupCarrier = { ...state.crudPortalCarrierState.lookupCarrier, ...lookupCarrierBase };


  const currentUserCarrier = state.appState.currentUserCarrier;
  return {
    record,
    currentUserCarrier,
    collectionOrdersPendentAceite: state.crudPortalCarrierState.collectionOrdersPendentAceite,
    totalPendencias: state.crudPortalCarrierState.totalPendencias,
    totalEntregas: state.crudPortalCarrierState.totalEntregas,
    filters: state.crudPortalCarrierState.filters,
    totalMensagens: state.crudPortalCarrierState.totalMensagens,
    totalAgendamentos: state.crudPortalCarrierState.totalAgendamentos,
    totalConfirmadas: state.crudPortalCarrierState.totalConfirmadas,
    totalRecusados: state.crudPortalCarrierState.totalRecusados,
    totalVeiculosCadastrados: state.crudPortalCarrierState.totalVeiculosCadastrados,
    totalRegisteredDriver: state.crudPortalCarrierState.totalRegisteredDriver,
    totalVeiculosViagens: state.crudPortalCarrierState.totalVeiculosViagens,
    totalInCollectionOrder: state.crudPortalCarrierState.totalInCollectionOrder,
    totalInTravel: state.crudPortalCarrierState.totalInTravel,
    lookupCarrier,
    carrier: state.crudPortalCarrierState.carrier,
    userIsCarrier,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setField: (field, value) => dispatch(actions.setField(field, value)),
    changeHandler: event => {
      const target = event.target;
      const field = target.name;
      let value = target.value;
      dispatch(actions.removeErrors([{ field }]));
      dispatch(actions.setField(field, value));
    },

    loadById: oid => dispatch(actions.loadById(oid)),
    getCountCollectionOrders: () => dispatch(actions.getCountCollectionOrders()),
    getCountCollectionOrdersConfirmadas: () => dispatch(actions.getCountCollectionOrdersConfirmadas()),
    countCollectionOrderWithPendences: () => dispatch(actions.countCollectionOrderWithPendences()),
    countInCollectionOrder: () => dispatch(actions.countInCollectionOrder()),
    countInTravel: () => dispatch(actions.countInTravel()),
    countCargoShipmentDelivered: () => dispatch(actions.countCargoShipmentDelivered()),
    countRegisteredDriver: () => dispatch(actions.countRegisteredDriver()),
    setFilters: (filters) => dispatch(actions.setFilters(filters)),

    // Tower Control 
    loadTotalizersPortal: (filters) => {
      dispatch(actionsMapTowerControl.loadTotalizersPortal(filters));
    },
    getAllTravelsPortal: (filters) => {
      dispatch(actionsMapTowerControl.getAllTravelsPortal(filters));
    },
    setSelectedTypeFilter: (selectedTypeFilter) => {
      dispatch(actionsMapTowerControl.setSelectedTypeFilter(selectedTypeFilter));
    },


    //lookup carrier
    lookupCarrierSetVisible: (visible) => dispatch(actions.setLookupCarrierVisible(visible)),
    lookupCarrierPaginate: (paginationParams) => dispatch(carrierActions.paginate(paginationParams)),
    lookupCarrierSortOrder: (sortParams) => dispatch(carrierActions.sortOrder(sortParams)),
    lookupCarrierLoad: () => dispatch(carrierActions.load()),
    setCarrierFilter: (carrier) => dispatch(actions.setCarrierFilter(carrier)),
    lookupCarrierApplyFilter: (filterParams) => {
      dispatch(carrierActions.setType(null));
      dispatch(carrierActions.applyFilter(filterParams));
    },
    //lookup carrier 
    setCurrentScreen: (currentScreen) => {
      dispatch(actions.setCurrentScreen(currentScreen));
    },


  }
}

class PortalCarrierFormPageContainer extends React.Component {

  constructor(props) {
    super(props);
    this.intervalRefresh = null;
    this.intervalRefresh5s = null;
  }


  componentDidMount() {

    AppService.on(COMPANY_CHANGE_EVENT_NAME, this);

    this.props.setCurrentScreen();
    this.props.setSelectedTypeFilter("andamento");

    if (this.props.filters && Array(this.props.filters).length > 0) {
      // this.props.setFilters(this.getFilters())
      this.refresh();
      this.props.getCountCollectionOrdersConfirmadas();
      this.onClickShowAllMap();
    }
    const user = storage.getUser();
    let timeRefress = 1 * (60 * 1000);//1 minutos
    let timeRefressConfirm = 3 * (60 * 1000);//3 minutos
    if (user.carrier) {
      //tempo para quando for transportadora
      timeRefress = 1 * (60 * 1000); //1 minutos
      timeRefressConfirm = 1 * (60 * 1000);//1 minutos
    }
    if (this.intervalRefresh) {
      clearInterval(this.intervalRefresh);
      clearInterval(this.intervalRefresh5s);
      this.intervalRefresh = null
      this.intervalRefresh5s = null
    }
    //atualiza os pedidos a cada 3 segundos
    this.intervalRefresh = setInterval(() => {
      this.refresh();
    }, timeRefress);

    this.intervalRefresh5s = setInterval(() => {
      this.props.getCountCollectionOrdersConfirmadas();
    }, timeRefressConfirm);

  }

  componentWillUnmount() {
    AppService.off(COMPANY_CHANGE_EVENT_NAME, this);
    clearInterval(this.intervalRefresh);
    clearInterval(this.intervalRefresh5s);
    this.intervalRefresh = null
    this.intervalRefresh5s = null
  }

  onReceiveAppNotifications = (eventName, params) => {
    if (eventName === COMPANY_CHANGE_EVENT_NAME) {
      this.refresh();
      this.props.getCountCollectionOrdersConfirmadas();
      this.onClickShowAllMap();
    }
  };
  refresh = () => {
    this.props.getCountCollectionOrders();
    this.props.countCollectionOrderWithPendences();
    this.props.countInCollectionOrder();
    this.props.countInTravel();
    this.props.countCargoShipmentDelivered();
    this.props.countRegisteredDriver();
  }
  onClickShowAllMap = () => {
    const filtersMapParams = this.getMapFilters();
    this.props.loadTotalizersPortal(filtersMapParams)

    const filterTravels = [...filtersMapParams]
    filterTravels.push({
      field: "travel_status",
      value: [TRAVELING],
      type: "IN",
    });
    filterTravels.push({
      campo: "currentPercent",
      valor: 0,
      tipoFiltro: "MAIOR",
    });
    this.props.getAllTravelsPortal(filterTravels);

  };

  getMapFilters = () => {
    let filters = [];
    const initialDateFilter = moment().startOf('month').toDate();
    const finalDateFilter = moment().toDate();

    filters.push({
      field: "eventRecord.createdAt",
      startValue: moment(initialDateFilter).startOf("day").toDate(),
      endValue: moment(finalDateFilter).endOf("day").toDate(),
      type: "BETWEEN",
    });

    const user = storage.getUser();

    if (user.carrier) {
      filters.push({
        campo: "carrier.documentNumber",
        valor: user.carrier.cnpj,
        tipoFiltro: 'IGUAL_A'
      })
    } else {
      const carrier = this.props.carrier;

      if (carrier && carrier.documentNumber && carrier.documentNumber != "") {
        filters.push({
          campo: "carrier.documentNumber",
          valor: carrier.documentNumber,
          tipoFiltro: 'IGUAL_A'
        })
      }
    }
    return filters;
  };


  onClickCollectionOrders = () => {
    this.props.history.push(`${routes.PATH_CARRIER_PORTAL}/open-collection-orders`);
  }
  onClickCollectionOrdersWithPendences = () => {
    this.props.history.push(`${routes.PATH_CARRIER_PORTAL}/open-collection-orders-pendences`);
  }
  //embarques que ainda não receberam as NFs para mudar para transito
  onClickInCollectionOrder = () => {
    this.props.history.push(`${routes.PATH_CARRIER_PORTAL}/shipment-in-coleta`);
  }
  //embarques em transito
  onClickInCargoShipmentIntTransito = () => {
    this.props.history.push(`${routes.PATH_CARRIER_PORTAL}/shipment-in-transito`);
  }
  //embarques em transito
  onClickInCargoShipmentDelivered = () => {
    this.props.history.push(`${routes.PATH_CARRIER_PORTAL}/shipment-delivered`);
  }
  //cad-veiculos
  onClickCadastroVeiculos = () => {
    this.props.history.push(`${routes.PATH_CARRIER_PORTAL}/cad-veiculos`);
  }

  //cad-motoristas
  onClickRegisterDriver = () => {
    this.props.history.push(`${routes.PATH_DRIVER}/novo`);
  }

  //lookup carrier

  lookupCarrierOnPage = (e) => {
    this.props.lookupCarrierPaginate({
      max: e.rows,
      index: e.first,
    });
  };
  lookupCarrierOnSort = (e) => {
    this.props.lookupCarrierSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField,
    });
  };
  lookupCarrierOnFilter = (e) => {
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach((key) => {
      filters.push({
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: "CONTEM",
      });
    });
    this.props.lookupCarrierApplyFilter(filters);
  };
  lookupCarrierOnClick = (e) => {
    this.props.lookupCarrierLoad();
    this.props.lookupCarrierSetVisible(true);
  };
  lookupCarrierOnHide = (e) => {
    this.props.lookupCarrierSetVisible(false);
  };
  lookupCarrierOnConfirm = (e) => {
    this.props.lookupCarrierApplyFilter([]);
    this.props.setCarrierFilter(e.selection);
    this.props.lookupCarrierSetVisible(false);

    setTimeout(() => {
      this.refresh();
      this.props.getCountCollectionOrdersConfirmadas();
      this.onClickShowAllMap();
    }, 1000);
  };
  lookupCarrierOnCancel = (e) => {
    this.props.setCarrierFilter(null);
    this.props.lookupCarrierSetVisible(false);
    setTimeout(() => {
      this.refresh();
      this.props.getCountCollectionOrdersConfirmadas();
      this.onClickShowAllMap();
    }, 1000);
  };

  lookupCarrierOnEdit = () => { };

  lookupCarrierOnComplete = (e) => { };

  lookupCarrierOnInputFilter = (e) => {
    const value = e.value;
    const filters = [];
    if (typeof value === "string") {
      filters.push({
        campo: "tradeName",
        valor: value,
        tipoFiltro: "CONTEM",
      });
      this.props.setCarrierFilter({ tradeName: value, name: value });
    } else {
      this.props.setCarrierFilter(value);
    }
    this.props.lookupCarrierApplyFilter(filters);
  };

  getCarrierFilter = () => {
    return this.props.carrier ? this.props.carrier.documentNumber : null;
  };
  //lookup carrier


  render() {
    const lookupCarrier = {
      ...this.props.lookupCarrier,
      lookupCarrierOnComplete: this.lookupCarrierOnComplete,
      lookupCarrierOnInputFilter: this.lookupCarrierOnInputFilter,
      lookupCarrierOnEdit: this.lookupCarrierOnEdit,
      lookupCarrierOnClick: this.lookupCarrierOnClick,
      lookupCarrierOnConfirm: this.lookupCarrierOnConfirm,
      lookupCarrierOnCancel: this.lookupCarrierOnCancel,
      lookupCarrierOnPage: this.lookupCarrierOnPage,
      lookupCarrierOnFilter: this.lookupCarrierOnFilter,
      lookupCarrierOnSort: this.lookupCarrierOnSort,
      lookupCarrierOnHide: this.lookupCarrierOnHide,
    };


    const content = (
      <PortalCarrierForm
        changeHandler={this.props.changeHandler}
        record={this.props.record}
        collectionOrdersPendentAceite={this.props.collectionOrdersPendentAceite}
        totalPendencias={this.props.totalPendencias}
        totalEntregas={this.props.totalEntregas}
        onClickCollectionOrders={this.onClickCollectionOrders}
        onClickCollectionOrdersWithPendences={this.onClickCollectionOrdersWithPendences}
        onClickInCollectionOrder={this.onClickInCollectionOrder}
        onClickInCargoShipmentIntTransito={this.onClickInCargoShipmentIntTransito}
        onClickInCargoShipmentDelivered={this.onClickInCargoShipmentDelivered}
        onClickCadastroVeiculos={this.onClickCadastroVeiculos}
        onClickRegisterDriver={this.onClickRegisterDriver}
        totalMensagens={this.props.totalMensagens}
        totalAgendamentos={this.props.totalAgendamentos}
        totalConfirmadas={this.props.totalConfirmadas}
        totalRecusados={this.props.totalRecusados}
        totalVeiculosCadastrados={this.props.totalVeiculosCadastrados}
        totalRegisteredDriver={this.props.totalRegisteredDriver}
        totalVeiculosViagens={this.props.totalVeiculosViagens}
        totalInCollectionOrder={this.props.totalInCollectionOrder}
        totalInTravel={this.props.totalInTravel}
        lookupCarrier={lookupCarrier}
        carrier={this.props.carrier}
        userIsCarrier={this.props.userIsCarrier}
      />
    );

    return content;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PortalCarrierFormPageContainer);