import React from 'react';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from "primereact/dropdown";
import { LookupField } from '../../../components/lookup/LookupField';
import '../../../index.scss'
import FileUploaderButton from '../../../components/file-uploader-button/FileUploaderButton';
import { InputSwitch } from 'primereact/inputswitch';

export const TrackingFilter = (props) => {

    const btnInputXLSX = React.useRef(null);
    const importField = () => {
        // btnInputXLSX.current.value = "";
        btnInputXLSX.current.click(props.downloadHandler)
    }
    const onChangeFile = (e) => {
        props.importFile(e)
        btnInputXLSX.current.value = "";
    }

    return (
        <div className="carrier-form">
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="p-grid">
                        <div className="p-col-2 form-field-container">
                            <label>Nota Fiscal</label>
                            <InputText value={props.nfeNumberFilter ? props.nfeNumberFilter : ""} onChange={props.nfeNumberChangeHandler} name="nfeNumberFilter" />
                        </div>
                        <div className="p-col-2 form-field-container">
                            <label>Campos Customizados</label>
                            <InputText value={props.customFields ? props.customFields : ""} onChange={props.customFieldChangeHandler} name="customFields" />
                        </div>
                        <div className="p-col-2 form-field-container" style={{
                            display: "flex",
                            justifyContent: "center"
                        }}>
                            <label>Contém</label>
                            <InputSwitch checked={props.containCustomField} onChange={props.changeContainCustomField} name="containCustomField" />
                        </div>



                    </div>
                    <div className="p-grid">
                        <div className="p-col-2 form-field-container">
                            <label className="lxRequiredField">De</label>
                            <Calendar showIcon={true} value={props.initialDateFilter} showTime={false} onChange={props.initialDateChangeHandler} disabled={false} name="initialDateFilter" dateFormat="dd/mm/yy" />
                        </div>
                        <div className="p-col-2 form-field-container">
                            <label className="lxRequiredField">Até</label>
                            <Calendar showIcon={true} value={props.finalDateFilter} showTime={false} onChange={props.finalDateChangeHandler} disabled={false} name="finallDateFilter" dateFormat="dd/mm/yy" />
                        </div>
                        <div className="p-col-2 form-field-container">
                            <label>Transportadora</label>
                            <LookupField
                                value={props.carrierFilter ? props.carrierFilter : null}
                                placeholder="Transportadora"
                                name="tradeName"
                                field="tradeName"
                                onComplete={props.lookupCarrierOnComplete}
                                onInputFilter={props.lookupCarrierOnInputFilter}
                                editDisabled={props.lookupCarrier.editDisabled}
                                onEditField={props.lookupCarrierOnEdit}
                                onClick={props.lookupCarrierOnClick}
                                visible={props.lookupCarrier.visible}
                                modal={props.lookupCarrier.modal}
                                header={props.lookupCarrier.header}
                                onHide={props.lookupCarrierOnHide}
                                onConfirm={props.lookupCarrierOnConfirm}
                                onCancel={props.lookupCarrierOnCancel}
                                records={props.lookupCarrier.records}
                                columns={props.lookupCarrier.columns}
                                totalRecords={props.lookupCarrier.totalRecords}
                                sortOrder={props.lookupCarrier.order}
                                sortField={props.lookupCarrier.sortField}
                                first={props.lookupCarrier.first}
                                rows={props.lookupCarrier.rows}
                                onPage={props.lookupCarrierOnPage}
                                onFilter={props.lookupCarrierOnFilter}
                                onSort={props.lookupCarrierOnOnSort}
                            />
                        </div>
                        <div className="p-col-2 form-field-container">
                            <label>Ocorrências</label>
                            <Dropdown
                                style={{ width: "100%" }}
                                name={"totalOccurrences"}
                                key={'k1'}
                                value={props.totalOccurrencesFilter}
                                options={props.totalOccurrencesOptions}
                                onChange={props.totalOccurrencesChangeHandler}
                            />
                        </div>
                        <div className="p-col-1 form-field-container">
                            <br />
                            <button
                                className="lognex-btn-highlighted"
                                style={{ width: "100%" }}
                                title="Filtrar"
                                onClick={props.applyTrackingFilter}>
                                Filtrar
                            </button>
                        </div>
                        <div className="p-col-1 form-field-container">
                            <br />
                            <button
                                className="lognex-btn-highlighted-inverse"
                                style={{ width: "100%" }}
                                title="Download"
                                onClick={props.onDownloadCSV}
                                disabled={!props.enabledExportCSV}
                            >
                                Exportar
                            </button>
                        </div>
                        <div className="p-col-1 form-field-container">
                            <br />
                            <button
                                className="lognex-btn-highlighted-inverse"
                                style={{ width: "100%" }}
                                title="Baixar template"
                                onClick={props.downloadHandler}
                                disabled={false}
                            >
                                <i className="pi pi-download"></i>
                            </button>
                        </div>
                        <div className="p-col-1 form-field-container">
                            <br />
                            <button
                                className="lognex-btn-highlighted-inverse"
                                style={{ width: "100%" }}
                                title="Enviar Arquivo"
                                onClick={importField}
                                disabled={false}
                            >

                                <i className="pi pi-upload"></i>
                                <input type='file' style={{ display: "none" }} ref={btnInputXLSX} onChange={onChangeFile} />
                            </button>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    );
}
