import { types } from "./delivery.actions";
import { Reducer } from "../../services/reducer.factory";


const initialState = {
  columns: [
    {
      field: "oid",
      header: "Código",
      columnKey: "oid",
      sortable: false,
      filter: true
    },
    {
      field: "carrier.name",
      header: "Transportadora",
      columnKey: "carrier.name",
      sortable: false,
      filter: true
    },
    {
      field: "origin.name",
      header: "Origem",
      columnKey: "origin",
      sortable: false,
      filter: true
    },
    {
      field: "destination.name",
      header: "Destino",
      columnKey: "destination",
      sortable: false,
      filter: true
    },
    {
      field: "destination.uf",
      header: "UF",
      columnKey: "uf",
      sortable: false,
      filter: true
    },
    {
      field: "deadline.value",
      header: "Prazo",
      columnKey: "deadline",
      sortable: false,
      filter: true
    },
    {
      field: "route",
      header: "Rota",
      columnKey: "route",
      sortable: false,
      filter: true
    },
  ],

  lookupCarrier: {
    visible: false,
    modal: false,
    header: "Transportadoras"
  },
  lookupOrigin: {
    visible: false,
    modal: true,
    header: "Origem"
  },
  lookupDestination: {
    visible: false,
    modal: true,
    header: "Destino"
  },
  options: [{ label: "Horas", value: "HOUR" }, { label: "Dias", value: "DAY" }],
  currentRecord: {
    oid: "",
    name: "",
    carrier:null,
    description: "",
    deadline: {
      value: '',
      type: 'DAY',
    },
    delayAfterCollect: {
      value: '',
      type: 'DAY',
    },
    onlyWorkingDays: false,
    tag: "",
    tags: [],
    weekDays: {
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
  
    },
  },
  files: [],
  fileName: "",
  fileExtension: "",
  importFilesVisible: false,
  tableErrors : {
    columns: [
      {
        field: "error",
        header: "Erro",
        columnKey: "error",
        sortable: false,
        filter: true
      },
    ]    
  },
  log: {
    errorsDialogVisible: false,
    errors: [],
    totalRecords: "",
    totalSuccessRecords: "",
    totalFailedRecords: "",  
  },
  filter: {
    filters: [],
    sortField: "",
    sortOrder: 0,
    index: 0,
    max: 20,
    count: 2,
    errors: {}
  },
  lookupCarrierExportXLSX: {
    visible: false,
    modal: false,
    header: "Transportadoras"
  },
  modalExportPrazo: false,
  carrierExport: null,
}

const reducer = new Reducer(initialState, types);

export function CRUDDeliveryDeadlineReducer(state, action) {
  state = reducer.execute(state, action)
  return execute(state, action)
}

function execute(state, action) {
  let result = state;
  switch (action.type) {
    case types.SET_LOOKUP_CARRIER_VISIBLE:
      return setLookupCarrierVisible(state, action);
    case types.SET_LOOKUP_ORIGIN_VISIBLE:
      return setLookupOriginVisible(state, action);
    case types.SET_LOOKUP_DESTINATION_VISIBLE:
      return setLookupDestinationVisible(state, action);
    case types.ADD_TAG_TO_TAGS_LIST:
      return addTagToTagsList(state, action);
    case types.REMOVE_TAG_FROM_TAGS_LIST:
      return removeTagFromTagsList(state, action);
    case types.SET_FILES_VISIBLE:
      return setFilesVisible(state, action);
    case types.SET_ERRORS_DIALOG_VISIBLE:
      return setErrorsDialogVisible(state, action);
    case types.SET_IMPORT_LOG_DATA:
      return setImportLogData(state, action);
    case types.SET_MODAL_EXPORT_PRAZO_VISIBLE:
      return setModalExportPrazoVisible(state, action); 
    case types.SET_LOOKUP_CARRIER_EXPORT_VISIBLE:
      return setLookupCarrierExportVisible(state, action);
    case types.SET_CARRIER_EXPORT:
      return setCarrierExport(state, action);
    default:
      return result;
  }
}

function setCarrierExport(state, action){
  let carrierExport = state.carrierExport;
  carrierExport = action.carrierExport || {};
  return { ...state, carrierExport};
}

function setLookupCarrierExportVisible(state, action){
  let lookupCarrierExportXLSX = state.lookupCarrierExportXLSX;
  lookupCarrierExportXLSX.visible = action.visible;
  return { ...state, lookupCarrierExportXLSX};
}

function setModalExportPrazoVisible(state, action){
  let modalExportPrazo = state.modalExportPrazo;
  modalExportPrazo = action.visible;
  return { ...state, modalExportPrazo};

}



function setLookupCarrierVisible(state, action) {
  let lookupCarrier = state.lookupCarrier;
  lookupCarrier = Object.assign({}, lookupCarrier, { visible: action.visible });
  return Object.assign({}, state, { lookupCarrier })
}

function setLookupOriginVisible(state, action) {
  let lookupOrigin = state.lookupOrigin
  lookupOrigin = Object.assign({}, lookupOrigin, { visible: action.visible });
  return Object.assign({}, state, { lookupOrigin })
}

function setLookupDestinationVisible(state, action) {
  let lookupDestination = state.lookupDestination
  lookupDestination = Object.assign({}, lookupDestination, { visible: action.visible })
  return Object.assign({}, state, { lookupDestination })
}

function addTagToTagsList(state, action) {
  let currentRecord = state.currentRecord
  const newTag = currentRecord.tag
  const oldTags = currentRecord.tags || []

  if (newTag) {
    if (!oldTags.includes(newTag)) {
      let tags = [...oldTags, newTag]
      currentRecord = Object.assign({}, currentRecord, { tags, tag: "" })
      return Object.assign({}, state, { currentRecord })
    }
    else {
      currentRecord = Object.assign({}, currentRecord, { tags: oldTags })
      return Object.assign({}, state, { currentRecord })
    }
  } else {
    currentRecord = Object.assign({}, currentRecord, { tags: oldTags })
    return Object.assign({}, state, { currentRecord })
  }
}

function removeTagFromTagsList(state, action) {
  let currentRecord = state.currentRecord  
  const tags = currentRecord.tags
  let newtags = tags.filter(tag => tag !== action.tag)
  currentRecord = Object.assign({}, currentRecord, { tags: newtags})
  return Object.assign({}, state, { currentRecord })
}

function setFilesVisible(state, action) {
  let importFilesVisible = state.importFilesVisible
  importFilesVisible = action.visible
  console.log(action.visible)
  return Object.assign({}, state, { importFilesVisible })  
}

function setErrorsDialogVisible(state, action) {
  let log = state.log
  log = Object.assign({}, log, {errorsDialogVisible: action.visible})
  return Object.assign({}, state, {log})
}

function setImportLogData(state, action) {
  const data = action.data
  let log = state.log
  log = Object.assign({}, log, {errors: data.errors, totalRecords: data.totalRecords, totalSuccessRecords: data.totalSuccessRecords, totalFailedRecords: data.totalFailedRecords})
  return Object.assign({}, state, {log})

  

  
  
}
