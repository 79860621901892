import React from "react";
import { connect } from "react-redux";
import { Column } from "primereact/column";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import { Page } from "../../../components/page/Page";
import { TrackingFilter } from "../components/TrackingFilter";
import { ModalDate } from "../../../components/modal-date/ModalDate";
import { actions } from "../tracking.actions";
import { actions as carrierActions } from "../../carrier/carrier.actions";
import { AppService, COMPANY_CHANGE_EVENT_NAME, BUSINESS_UNIT_CHANGE_EVENT_NAME } from "../../../services/app.service";
import { TrackingList } from "./TrackingList";
import { Timeline } from "../../../components/timeline/Timeline";
import { actions as appActions } from "../../../store/app";
import * as menuActions from "../../../store/menu/menu.actions";
import * as utils from "../../../utils";
import * as IconFactory from "../../../services/icons.factory";
import { ModalFields } from "../components/ModalFields";
import moment from "moment";
import "./TrackingListPageContainer.scss";
import { ModalCustomFields } from "../components/ModalCustomFields";
import { DataTable } from "primereact/datatable";
import { actions as accountConfigurationActions } from "../../accountConfiguration/accountConfiguration.actions";

let timer = null;

const mapStateToProps = (store) => {
  const lookupCarrierBase = {
    records: store.crudCarrierState.records,
    columns: store.trackingState.trackingFilter.lookupCarrier.visible ? (
      store.crudCarrierState.columns.filter((col) => col.field === "oid" || col.field === "tradeName").map((col) => <Column key={col.field} {...col} />)
    ) : (
      <Column />
    ),
    first: store.crudCarrierState.index,
    rows: store.crudCarrierState.max,
    totalRecords: store.crudCarrierState.count,
    sortField: store.crudCarrierState.sortField,
    order: store.crudCarrierState.sortOrder,
    visible: store.trackingState.trackingFilter.lookupCarrier.visible,
    editDisabled: true,
    modal: false,
  };
  const deliveryStatusOptions = store.trackingState.deliveryStatusOptions;
  const lookupCarrier = Object.assign({}, store.trackingState.trackingFilter.lookupCarrier, lookupCarrierBase);
  const currentMenu = store.menuState.currentRecord;
  const userConfigurations = store.appState.userConfigurations;
  const currentFilterDeliveryStatus = store.trackingState.currentFilterDeliveryStatus;
  let isFavorite = utils.isFavorite(currentMenu, userConfigurations);

  const { customColumns = [] } = store.crudAccountConfigurationState.currentRecord;
  //injetar colunas customizadas por clientes  
  const columnsPlus = [];
  for (const colPath of customColumns) {
    if (colPath.path == "shipment_tracking") {
      colPath.columns.forEach(col => {
        columnsPlus.push(col)
      });
    }
  }
  const columns2 = [...store.trackingState.columns2, ...columnsPlus];
  return {
    totalOccurrencesFilter: store.trackingState.trackingFilter.totalOcccurrences,
    totalOccurrencesOptions: [
      { value: "", label: "TODOS", key: "todos" },
      { value: "noOccurrences", label: "Sem Ocorrências", key: "sem" },
      { value: "hasOccurrences", label: "Com Ocorrências", key: "com" },
    ],
    lookupCarrier,
    initialDateFilter: store.trackingState.trackingFilter.initialDateFilter,
    finalDateFilter: store.trackingState.trackingFilter.finalDateFilter,
    carrierFilter: store.trackingState.trackingFilter.carrierFilter,
    nfeNumberFilter: store.trackingState.trackingFilter.nfeNumberFilter,
    containCustomField: store.trackingState.trackingFilter.containCustomField,
    customFields: store.trackingState.trackingFilter.customFields,
    columns: columns2,
    records: store.trackingState.records,
    first: store.trackingState.index,
    rows: store.trackingState.max,
    totalRecords: store.trackingState.count,
    sortField: store.trackingState.sortField,
    order: store.trackingState.sortOrder,
    record: store.trackingState.currentRecord,
    menuItems: store.menuState.items,
    currentMenu,
    isFavorite,
    deliveryStatusOptions,
    currentFilterDeliveryStatus,
    modalDeliveryDate: store.trackingState.modalDeliveryDate,
    modalCustomFields: store.trackingState.modalCustomFields,
    dateColumnFilter: store.trackingState.dateColumnFilter,
    filters: store.trackingState.filters,
    enabledExportCSV: store.trackingState.records && store.trackingState.records.length > 0,
    modalCubageAndVehicleType: store.trackingState.modalCubageAndVehicleType,
    selectedRow: store.trackingState.selectedRow || [],
    listVehiclesType: store.trackingState.listVehiclesType || [],
    selectCubageVehicleType: store.trackingState.selectCubageVehicleType,
    recordWithCustomFields: store.trackingState.recordWithCustomFields,
    columnsPlus
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    paginate: (paginationParams) => dispatch(actions.paginate(paginationParams)),
    sortOrder: (sortParams) => dispatch(actions.sortOrder(sortParams)),
    applyFilter: (filterParams) => {
      dispatch(actions.setType(null));
      dispatch(actions.applyFilter(filterParams));
    },
    initialDateChangeHandler: (e) => {
      dispatch(actions.setFieldTrackingFilter("initialDateFilter", e.value));
    },
    finalDateChangeHandler: (e) => {
      dispatch(actions.setFieldTrackingFilter("finalDateFilter", e.value));
    },

    nfeNumberChangeHandler: ({ target }) => {
      dispatch(actions.setFieldTrackingFilter("nfeNumberFilter", target.value));
    },

    customFieldChangeHandler: ({ target }) => {
      dispatch(actions.setFieldTrackingFilter("customFields", String(target.value).trim()));
    },

    changeContainCustomField: (e) => {
      dispatch(actions.setFieldTrackingFilter("containCustomField", e.value));
    },

    lookupCarrierSetVisible: (visible) => dispatch(actions.setLookupCarrierVisible(visible)),
    lookupCarrierPaginate: (paginationParams) => dispatch(carrierActions.paginate(paginationParams)),
    lookupCarrierSortOrder: (sortParams) => dispatch(carrierActions.sortOrder(sortParams)),
    lookupCarrierApplyFilter: (filterParams) => {
      dispatch(carrierActions.setType(null));
      dispatch(carrierActions.applyFilter(filterParams));
    },
    lookupCarrierLoad: () => dispatch(carrierActions.load()),
    setCarrierFilter: (carrier) => {
      dispatch(actions.setFieldTrackingFilter("carrierFilter", carrier));
    },
    load: () => dispatch(actions.load()),
    setExpandedTrackingRows: (expandedTrackingRows) => {
      const record = expandedTrackingRows[1]; //* By now we will always have just one expanded
      dispatch(actions.setRecord(record));
    },
    setField: (field, value) => dispatch(actions.setField(field, value)),
    userLastAccess: (oidMenu) => dispatch(appActions.userLastAccess(oidMenu)),
    setCurrentMenu: (menuPath) => dispatch(menuActions.setCurrentRecord(menuPath)),
    toggleFavorite: (isFavorite, oidMenu) => {
      dispatch(appActions.toggleFavorite(isFavorite, oidMenu));
    },
    setCurrentFilterDeliveryStatus: (value) => dispatch(actions.setCurrentFilterDeliveryStatus(value)),
    showModalDeliveryDate: () => {
      dispatch(actions.showModalDeliveryDateVisible(true));
    },
    hideModalDeliveryDate: () => {
      dispatch(actions.showModalDeliveryDateVisible(false));
    },
    selectedRecord: (record) => {
      dispatch(actions.selectedRecord(record));
    },
    confirmDeliveryDate: (deliveryDate) => {
      dispatch(actions.setFieldSelectedRecord("deliveryDate", deliveryDate));
      dispatch(actions.updateDeliveryDateOnServer());
    },
    backupDeliveryDate: (deliveryDate) => {
      dispatch(actions.backupDeliveryDate(deliveryDate));
    },
    clearAllInputDateHeaderTable: (dateColumnFilter) => {
      for (let field in dateColumnFilter) {
        dispatch(actions.setDateColumnFilter(field, null));
      }
    },

    changeDateColumnFilter: (filters) => {
      dispatch(actions.applyFilter(filters));
    },
    downloadHandler: (filters) => {
      dispatch(actions.download(filters));
    },
    totalOccurrencesChangeHandler: (e) => {
      dispatch(actions.setFieldTrackingFilter("totalOcccurrences", e.value));
    },
    setDateColumnFilter: (name, value) => {
      dispatch(actions.setDateColumnFilter(name, value));
    },

    changeCubage: (event) => {
      const target = event.target;
      let formatedValue = parseFloat(target.value);
      const cubage = { value: formatedValue };
      dispatch(actions.setCubage(cubage));
    },
    setCubage: (value) => {
      dispatch(actions.setCubage(value));
    },
    setNFeId: (value) => {
      dispatch(actions.setNFeID(value));
    },
    setVehicleType: (vehicleType) => {
      dispatch(actions.setVehicleType(vehicleType));
    },
    changeVehicleType: (event) => {
      const target = event.target;
      let value = target.value;
      dispatch(actions.setVehicleType(value));
    },

    showModalCubageAndVehicleType: () => {
      dispatch(actions.loadVehiclesType());
      dispatch(actions.showModalCubageAndVehicleTypeVisible(true));
    },
    hideModalCubageAndVehicleType: () => {
      dispatch(actions.showModalCubageAndVehicleTypeVisible(false));
    },
    setSelectedRow: (data) => {
      dispatch(actions.setSelectedRow(data));
    },

    confirmCubageAndVehicleType: () => {
      dispatch(actions.updateCubageAndVehicleType());
      dispatch(actions.showModalCubageAndVehicleTypeVisible(false));
    },
    importFileXLSX: (fileXSLX) => {
      dispatch(actions.importFileXLSX(fileXSLX));
    },
    setShowModalCustomField: (visible) => dispatch(actions.showModalCustomFields(visible)),
    setRecordWithCustomFields: (data) => dispatch(actions.setRecordWithCustomFields(data)),
    setCustomFields: (uuid, field, value) => dispatch(actions.setCustomFields(uuid, field, value)),
    updateCustomFields: () => dispatch(actions.updateCustomFields()),
    removeItemCustomFieldsHandler: (item) => dispatch(actions.removeItemCustomFieldsHandler(item)),
    onAddNewCustomFieldsHandler: () => dispatch(actions.onAddNewCustomFieldsHandler()),
    showMessage: (msg, msgType) => dispatch(actions.messageActions.messageShowMessages(actions.makeMessages(msg, msgType))),
    accountConfigurationLoad: () => dispatch(accountConfigurationActions.loadAccountConfigurationsByCompany()),
  };
};

class TrackingListPageContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expandedRows: null,
      showViewModalImage: false,
      spin: false,
      currentImagePath: "",
      countRotation: 0,
    };
  }

  componentWillReceiveProps(newProps) {
    if ((!this.props.menuItems || this.props.menuItems.length === 0) && newProps.menuItems && newProps.menuItems.length > 0) {
      const menuPath = this.props.history.location.pathname;
      this.props.setCurrentMenu(menuPath);
    }

    if ((!this.props.currentMenu && newProps.currentMenu) || this.props.currentMenu !== newProps.currentMenu) {
      const oidMenu = newProps.currentMenu.oid;
      this.props.userLastAccess(oidMenu);
    }
  }

  componentDidMount() {
    this.applyTrackingFilter();
    this.props.load();
    AppService.on(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.on(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
    const menuPath = this.props.history.location.pathname;
    this.props.accountConfigurationLoad();
    this.props.setCurrentMenu(menuPath);
  }
  componentWillUnmount() {
    AppService.off(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.off(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
  }

  onReceiveAppNotifications = (eventName, params) => {
    if (eventName === COMPANY_CHANGE_EVENT_NAME || eventName === BUSINESS_UNIT_CHANGE_EVENT_NAME) {
      this.applyTrackingFilter();
      this.props.load && this.props.load();
      this.props.accountConfigurationLoad();
    }
  };

  lookupCarrierOnPage = (e) => {
    this.props.lookupCarrierPaginate({
      max: e.rows,
      index: e.first,
    });
  };
  lookupCarrierOnSort = (e) => {
    this.props.lookupCarrierSortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField,
    });
  };
  lookupCarrierOnFilter = (e) => {
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach((key) => {
      filters.push({
        campo: key,
        valor: e.filters[key].value,
        tipoFiltro: "CONTEM",
      });
    });
    this.props.lookupCarrierApplyFilter(filters);
  };
  lookupCarrierOnClick = (e) => {
    this.props.lookupCarrierApplyFilter([]);
    this.props.lookupCarrierLoad();
    this.props.lookupCarrierSetVisible(true);
  };
  lookupCarrierOnHide = (e) => {
    this.props.lookupCarrierApplyFilter([]);
    this.props.lookupCarrierSetVisible(false);
  };
  lookupCarrierOnConfirm = (e) => {
    this.props.lookupCarrierApplyFilter([]);
    this.props.setCarrierFilter(e.selection);
    this.props.lookupCarrierSetVisible(false);
  };
  lookupCarrierOnCancel = (e) => {
    this.props.lookupCarrierApplyFilter([]);
    this.props.setCarrierFilter(null);
    this.props.lookupCarrierSetVisible(false);
  };

  lookupCarrierOnComplete = (e) => {
    // this.props.lookupCarrierApplyFilter([]);
  };

  lookupCarrierOnInputFilter = (e) => {
    const value = e.value;
    if (typeof value === "string") {
      const filters = [
        {
          campo: "tradeName",
          valor: e.value,
          tipoFiltro: "CONTEM",
        },
      ];
      this.props.setCarrierFilter({ tradeName: value });
      this.props.lookupCarrierApplyFilter(filters);
    } else {
      this.props.setCarrierFilter(value);
    }
  };
  onPage = (e) => {
    this.props.paginate({
      max: e.rows,
      index: e.first,
    });
  };
  onSort = (e) => {
    this.props.sortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField,
    });
  };

  onHideModalDeliveryDate = (e) => {
    this.props.hideModalDeliveryDate();
  };

  onShowModalCubageAndVehicleType = (e) => {
    this.props.showModalCubageAndVehicleType();
  };
  onHideModalCubageAndVehicleType = (e) => {
    this.props.setNFeId(0);
    this.props.setCubage({ value: 0 });
    this.props.setVehicleType(null);
    this.props.hideModalCubageAndVehicleType();
  };

  onVehicleTypeChangeHandler = (e) => {
    this.props.changeVehicleType(e);
  };
  onCubageChangeHandler = (e) => {
    this.props.changeCubage(e);
  };

  onDownload = (e) => {
    this.props.downloadHandler(this.props.filters);
  };

  getCarrierFilterOptions = () => {

    const options = [];
    const carrierFilter = this.props.carrierFilter || {};
    const { documentNumber = null, branches = [] } = carrierFilter;
    if (documentNumber && documentNumber != "") {
      options.push(documentNumber);
    }
    branches.forEach((branch) => {
      if (branch && branch.documentNumber) {
        options.push(branch.documentNumber);
      }
    });
    return options;
  };
  getFilterTrackingFilter = () => {
    const normalizeDate = (date) => {
      return date && typeof date === "object" ? date : null;
    };
    const totalOccurrencesFilter = this.props.totalOccurrencesFilter;
    const initialDateFilter = normalizeDate(this.props.initialDateFilter);
    const finalDateFilter = normalizeDate(this.props.finalDateFilter);
    const nfeNumberFilter = this.props.nfeNumberFilter && this.props.nfeNumberFilter !== "" ? this.props.nfeNumberFilter : null;

    const containCustomField = this.props.containCustomField;
    const customFields = this.props.customFields;

    const carrierOptions = this.getCarrierFilterOptions() || [];
    const filters = [];
    if (initialDateFilter && finalDateFilter) {
      filters.push({
        campo: "nfeDate",
        campoComplementar: "nfeDate",
        valor: initialDateFilter,
        valorComplementar: finalDateFilter,
        tipoFiltro: "ENTRE",
      });
    }

    if (nfeNumberFilter) {
      filters.push({
        campo: "nfeNumber",
        valor: nfeNumberFilter,
        tipoFiltro: "IGUAL_A",
      });
    }

    if (totalOccurrencesFilter) {
      filters.push({
        campo: totalOccurrencesFilter,
        valor: 0,
      });
    }

    if (carrierOptions.length > 0) {
      filters.push({
        campo: "carrier.cnpj",
        valor: carrierOptions,
        tipoFiltro: "ESTA_CONTIDO_EM",
      });
    }

    if (customFields && customFields.length > 0) {
      filters.push({
        campo: "customFields." + customFields,
        valor: containCustomField,
        tipoFiltro: "EXISTS",
      });

    }
    return filters;
  };

  onFilter = (e) => {
    if (timer) {
      window.clearTimeout(timer);
    }
    timer = setTimeout(() => {
      const filters = [];
      const keys = Object.keys(e.filters) || [];
      keys.forEach((key) => {
        if (key != "deliveryStatus") {
          this.props.setCurrentFilterDeliveryStatus(null);
        }
        if (key == "occurrencesQuantity") {
          filters.push({
            campo: key,
            valor: e.filters[key].value,
            tipoFiltro: "SIZE",
          });
        } else {
          filters.push({
            campo: key,
            valor: e.filters[key].value,
            tipoFiltro: "CONTEM",
          });
        }
      });
      this.props.setDateColumnFilter("issueDate", null);
      this.props.setDateColumnFilter("deliveryDate", null);
      this.props.setDateColumnFilter("deliveryDeadlineDate", null);
      const filtersTrackingFilter = this.getFilterTrackingFilter();
      this.props.applyFilter(filtersTrackingFilter.concat(filters));
    }, 700);
  };

  applyTrackingFilter = () => {
    const filterParamsTrackingFilter = this.getFilterTrackingFilter();
    this.props.applyFilter(filterParamsTrackingFilter);
    this.props.setCurrentFilterDeliveryStatus(null);

    const dateColumnFilter = this.props.dateColumnFilter;
    this.props.clearAllInputDateHeaderTable(dateColumnFilter);
  };

  onFavorite = (e) => {
    if (this.props.currentMenu) {
      const oidMenu = this.props.currentMenu.oid;
      const isFavorite = this.props.isFavorite;
      this.props.toggleFavorite(isFavorite, oidMenu);
    }
  };
  handleChangeDeliveryStatus = ({ value }) => {
    this.props.setCurrentFilterDeliveryStatus(value);
    if (value) {
      let filters = [
        {
          campo: `deliveryStatus`,
          valor: value,
          tipoFiltro: "IGUAL_A",
        },
      ];

      const filtersTrackingFilter = this.getFilterTrackingFilter();
      this.props.applyFilter(filtersTrackingFilter.concat(filters));
    } else {
      const filtersTrackingFilter = this.getFilterTrackingFilter();
      this.props.applyFilter(filtersTrackingFilter);
    }
  };

  onClickSelectedDeliveryDate = (record) => {
    this.props.showModalDeliveryDate();
    this.props.selectedRecord(record);
  };

  confirmDeliveryDate = (deliveryDate) => {
    const oldDeliveryDate = this.props.record != null ? this.props.record.deliveryDate : null;
    this.props.backupDeliveryDate(oldDeliveryDate);
    this.props.confirmDeliveryDate(deliveryDate);
    this.props.hideModalDeliveryDate();
  };

  setCurrentImage = (image) => {
    const path = `${image}`;//data:image/jpeg;base64,
    const spin = !!this.state.spin;
    image = <img className={utils.classNames({ "rotate-image": true, spin: spin })} src={path} />;
    this.setState({
      spin,
      currentImagePath: path,
      image,
    });
  };

  showVisibleModalImage = (visible) => {
    this.setState({
      showViewModalImage: visible,
    });
  };

  changeTransiction = () => {
    let count = this.state.countRotation;
    let cssClassName = "rotate-image";
    if (count == 0) {
      cssClassName = cssClassName + " spin";
      count++;
    } else if (count == 1) {
      cssClassName = cssClassName + " spin180";
      count++;
    } else if (count == 2) {
      cssClassName = cssClassName + " spin360";
      count = 0;
    }

    this.setState({ countRotation: count });
    this.setState((prevState) => {
      const spin = !prevState.spin;
      return {
        spin,
        // image: (<img className={utils.classNames({ 'rotate-image': true, 'spin': spin })} src={prevState.currentImagePath} />)
        image: <img className={cssClassName} src={prevState.currentImagePath} />,
      };
    });
  };

  onConfirmCubageAndVehicleType = (e) => {
    this.props.confirmCubageAndVehicleType();
  };

  setFieldCubageAndVehicleType = (data) => {
    if (data) {
      this.props.setNFeId(data["oid"]);
      this.props.setCubage({ value: data["cubage"] });
      this.props.setVehicleType({
        name: data["vehicleType"],
      });
    }
  };

  onDownloadTemplate = (e) => {
    // criar um link apontando para o arquivo de download
    const anchor = document.createElement("a");
    anchor.href = "/resources/templates/template_nfe_custon_fields.xlsx";
    anchor.target = "_blank";
    document.body.append(anchor);
    anchor.click();
    anchor.remove();
  };

  onImportFile = (event) => {
    const fileXSLX = event.target.files;
    this.props.importFileXLSX(fileXSLX);
    event.target.files = null;
  };

  onSetShowModalCustomField = (visible) => {
    if (!visible) {
      this.props.setRecordWithCustomFields(null);
    }
    this.props.setShowModalCustomField(visible);
  };

  onSetRecordWithCustomFields = (data) => {
    this.props.setRecordWithCustomFields(data);
  };

  onChangeCustomFieldsHandler = (e, field, uuid) => {
    const value = e.target.value;
    //chamar a função para setar o campo dentro do customFields do registro
    this.props.setCustomFields(uuid, field, value);
  };

  onUpdateCustomFields = () => {
    //validar se existe um campo com o mesmo nome
    const listCustomFields = this.props.recordWithCustomFields.listCustomFields;
    let isDuplicate = false;

    for (let i = 0; i < listCustomFields.length; i++) {
      for (let j = 0; j < listCustomFields.length; j++) {
        if (i !== j) {
          if (listCustomFields[i].campo === listCustomFields[j].campo) {
            isDuplicate = true;
            break;
          }
        }
        if (isDuplicate) {
          break;
        }
      }
      if (isDuplicate) {
        break;
      }
    }

    if (isDuplicate) {
      //existe outro campo com o mesmo nome
      this.props.showMessage(["Um ou mais campos já ta sendo usado, o nome do campo não pode ser duplicado!"], "error");
      return;
    } else {
      this.props.updateCustomFields();
      this.props.setShowModalCustomField(false);
    }
  };

  changeDateColumnFilter = (e, dateColumnFilter) => {


    const target = e.target;
    for (let field in dateColumnFilter) {
      if (target.name === field) {
        this.props.setDateColumnFilter(target.name, target.value);
      } else {
        this.props.setDateColumnFilter(field, null);
      }
    }

    this.props.setDateColumnFilter(target.name, target.value);
    let filters = [];
    if (target.value) {
      filters = [
        {
          campo: `${target.name}`,
          valor: target.value,
          tipoFiltro: "EQUALS",
        },
      ];
    }
 
    const filtersTrackingFilter = this.getFilterTrackingFilter();
    this.props.applyFilter(filtersTrackingFilter.concat(filters));
 
  };

  render() {
    const isFavorite = this.props.isFavorite;
    const header = (
      <div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h1>Acompanhamento de entregas</h1>
          <i className={`pi pi-star lxFavoriteStar ${isFavorite ? "lxFavorite" : ""}`} title={isFavorite ? "Remover dos favoritos" : "Adicionar aos favoritos"} onClick={this.onFavorite}></i>
        </div>
        <hr className="lxSplitter"></hr>
        <div className="p-grid">
          <div className="p-col-12">
            <TrackingFilter
              initialDateFilter={this.props.initialDateFilter}
              finalDateFilter={this.props.finalDateFilter}
              nfeNumberFilter={this.props.nfeNumberFilter}
              carrierFilter={this.props.carrierFilter}
              lookupCarrier={this.props.lookupCarrier}
              totalOccurrencesFilter={this.props.totalOccurrencesFilter}
              totalOccurrencesChangeHandler={this.props.totalOccurrencesChangeHandler}
              totalOccurrencesOptions={this.props.totalOccurrencesOptions}
              initialDateChangeHandler={this.props.initialDateChangeHandler}
              finalDateChangeHandler={this.props.finalDateChangeHandler}
              nfeNumberChangeHandler={this.props.nfeNumberChangeHandler}
              containCustomField={this.props.containCustomField}
              customFields={this.props.customFields}
              changeContainCustomField={this.props.changeContainCustomField}
              customFieldChangeHandler={this.props.customFieldChangeHandler}
              lookupCarrierOnComplete={this.lookupCarrierOnComplete}
              lookupCarrierOnInputFilter={this.lookupCarrierOnInputFilter}
              lookupCarrierOnEdit={this.lookupCarrierOnEdit}
              lookupCarrierOnClick={this.lookupCarrierOnClick}
              lookupCarrierOnConfirm={this.lookupCarrierOnConfirm}
              lookupCarrierOnCancel={this.lookupCarrierOnCancel}
              lookupCarrierOnPage={this.lookupCarrierOnPage}
              lookupCarrierOnFilter={this.lookupCarrierOnFilter}
              lookupCarrierOnSort={this.lookupCarrierOnSort}
              lookupCarrierOnHide={this.lookupCarrierOnHide}
              applyTrackingFilter={this.applyTrackingFilter}
              onDownloadCSV={this.onDownload}
              enabledExportCSV={this.props.enabledExportCSV}
              downloadHandler={this.onDownloadTemplate}
              importFile={this.onImportFile}
            />
          </div>
        </div>
      </div>
    );

    const centeredTemplate = (rowData, column) => {
      const value = utils.getFieldValueFromStringRecursively(rowData, column.field);
      return <div style={{ textAlign: "center" }}>{value}</div>;
    };

    const dateTemplate = (rowData, column) => {
      const clickHandler = (e) => {
        this.onClickSelectedDeliveryDate(rowData);
      };
      const date = rowData[column.field];
      let formattedDate = moment(date).utc().format("DD/MM/YYYY");
      if (formattedDate === "Invalid date" && column.field === "deliveryDate") {
        formattedDate = "Pendente";
        if(rowData.situacaoNfe === "CANCELED"){
          formattedDate = "Cancelado"
        }
      } else if (formattedDate === "Invalid date" && column.field === "deliveryDeadlineDate") {
        formattedDate = "Prazo não cadastrado";
      } else if (formattedDate === "Invalid date" && column.field === "issueDate") {
        formattedDate = "Prazo não cadastrado";
      }
      if (formattedDate === "Pendente") {
        return (
          <div style={{ textAlign: "center" }}>
            <a onClick={clickHandler}>{formattedDate}</a>
          </div>
        );
      }else if (formattedDate === "Cancelado") {
        return <div style={{ textAlign: "center", color:"red" }}>{formattedDate}</div>;
      }else {
        return <div style={{ textAlign: "center" }}>{formattedDate}</div>;
      }
    };

    const getTruckStatusIconLabel = (status) => {
      let truckIcon = null;
      let title = "";
      switch (status) {
        case "IN_TIME":
          truckIcon = IconFactory.getIcon("truck_normal", false);
          title = "No prazo";
          break;
        case "ON_TIME":
          truckIcon = IconFactory.getIcon("truck_warning", false);
          title = "Prazo vence hoje";
          break;
        case "LATE":
          truckIcon = IconFactory.getIcon("truck_failure", false);
          title = "Prazo vencido";
          break;
        case "DELIVERED_LATE":
          truckIcon = IconFactory.getIcon("truck_success_pending", false);
          title = "Entregue fora do prazo";
          break;
        case "DELIVERED_IN_TIME":
          title = "Entregue no prazo";
          truckIcon = IconFactory.getIcon("truck_success", false);
          break;
        case "DELIVERY_CANCELED":
          title = "Pedido cancelado";
          truckIcon = IconFactory.getIcon("truck_canceled", false);
          break;
        default:
          truckIcon = IconFactory.getIcon("truck_normal", false);
          break;
      }
      return {
        icon: truckIcon,
        title: title,
      };
    };
    const statusFilter = (field) => {
      const deliveryStatusOptions = this.props.deliveryStatusOptions.map(
        (option, index) => {
          const iconObj = getTruckStatusIconLabel(option.value);
          return {
            value: option.value,
            label: !option.value ? (
              "TODOS"
            ) : (
              <div className="lxTruck_status" key={option.label}>
                {" "}
                <img
                  src={iconObj.icon}
                  alt={option.label}
                  title={option.label}
                />{" "}
              </div>
            ),
          };
        }
      );
      return (
        <Dropdown
          style={{ width: "100%" }}
          name={field}
          key={"deliveryStatus" + field}
          value={this.props.currentFilterDeliveryStatus}
          options={deliveryStatusOptions}
          onChange={this.handleChangeDeliveryStatus}
        />
      );
    };

    const statusTemplate = (rowData, column) => {
      const status = rowData["deliveryStatus"];
      const iconObj = getTruckStatusIconLabel(status);
      return (
        <div className="lxTruck_status">
          {" "}
          <img src={iconObj.icon} alt={iconObj.title} title={iconObj.title} />
        </div>
      );
    };

    const dateFilter = (field) => {
      const dateColumnFilter = this.props.dateColumnFilter;
      return (
        <div className="p-calendar">
          <Calendar name={field} key={crypto.randomUUID()} dateFormat="dd/mm/yy" value={this.props.dateColumnFilter[field]} onChange={(e) => this.changeDateColumnFilter(e, dateColumnFilter)} />
        </div>
      );
    };

    const setCubageValue = (rowData, column) => {
      const button = (
        <button
          className="lognex-btn-highlighted"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            this.setFieldCubageAndVehicleType(rowData);
            this.onShowModalCubageAndVehicleType();
          }}
        >
          <i className="pi pi-pencil"></i>
        </button>
      );
      const cubage = rowData[column.field] && rowData[column.field] > 0 ? rowData[column.field] : button;
      return <div style={{ textAlign: "center" }}>{cubage}</div>;
    };
    const setVehicleTypeValue = (rowData, column) => {
      const button = (
        <button
          className="lognex-btn-highlighted"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            this.setFieldCubageAndVehicleType(rowData);
            this.onShowModalCubageAndVehicleType();
          }}
        >
          <i className="pi pi-pencil"></i>
        </button>
      );
      const vehicleType = rowData[column.field] && rowData[column.field] != "" ? rowData[column.field] : button;
      return <div style={{ textAlign: "center" }}>{vehicleType}</div>;
    };

    const btnCustomFieldsTemplate = (rowData, column) => {
      const button = (
        <button
          className="lognex-btn-highlighted"
          key={crypto.randomUUID()}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            this.onSetRecordWithCustomFields(rowData);
            this.onSetShowModalCustomField(true);
          }}
        >
          <i className="pi pi-search-plus"></i>
        </button>
      );
      return <div style={{ textAlign: "center" }}>{button}</div>;
    };

    const columns = this.props.columns.filter(col => col.field != "cubage" && col.field != "vehicleType" && col.field != "customFields").map((col, index) => {
      const key = crypto.randomUUID();
      if (col.field === "issueDate") {
        return <Column {...col} key={key} body={dateTemplate} style={{ width: "100px" }} filterElement={dateFilter(col.field)} />;
      } else if (col.field === "deliveryDate") {
        return <Column {...col} key={key} body={dateTemplate} style={{ width: "100px" }} filterElement={dateFilter(col.field)} />;
      } else if (col.field === "deliveryDeadlineDate") {
        return <Column {...col} key={key} body={dateTemplate} style={{ width: "100px" }} filterElement={dateFilter(col.field)} />;
      } else if (col.field === "totalOccurrences") {
        return <Column {...col} key={key} body={centeredTemplate} style={{ width: "85px" }} />;
      } else if (col.field === "number") {
        return <Column {...col} key={key} body={centeredTemplate} style={{ width: "100px" }} />;
      } else if (col.field === "sequence") {
        return <Column {...col} key={key} body={centeredTemplate} style={{ width: "50px" }} />;
      } else if (col.field === "carrier.name") {
        return <Column {...col} key={key} body={centeredTemplate} />;
      } else if (col.field === "receiver") {
        return <Column {...col} key={key} body={centeredTemplate} />;
      } else if (col.field == "deliveryStatus") {
        return <Column {...col} key={key} body={statusTemplate} filterElement={statusFilter(col.field)} style={{ width: "105px" }}></Column>;
      } else if (col.field == "cubage") {
        return (
          <Column
            {...col}
            key={key}
            body={setCubageValue}
            // filterElement={statusFilter(col.field)}
            style={{ width: "6%" }}
          ></Column>
        );
      } else if (col.field == "vehicleType") {
        return (
          <Column
            {...col}
            key={key}
            body={setVehicleTypeValue}
            // filterElement={statusFilter(col.field)}
            style={{ width: "8%" }}
          ></Column>
        );
      } else if (col.field == "customFields") {
        return (
          <Column
            {...col}
            key={key}
            body={btnCustomFieldsTemplate}
            // filterElement={statusFilter(col.field)}
            style={{ width: "8%" }}
          ></Column>
        );
      }
      return <Column {...col} key={key} />;
    });


    const templateTrackingTbExpandedRow = (rowData, e) => {

      const columnsSub = this.props.columns.filter(col => col.field == "cubage" || col.field == "vehicleType" || col.field == "customFields").map((col, index) => {
        const key = crypto.randomUUID();
        if (col.field == "cubage") {
          return (<Column {...col} key={key} body={setCubageValue} style={{ width: "6%" }} />
          );
        } else if (col.field == "vehicleType") {
          return (<Column {...col} key={key} body={setVehicleTypeValue} style={{ width: "8%" }} />
          );
        } else if (col.field == "customFields") {
          return (<Column {...col} key={key} body={btnCustomFieldsTemplate} style={{ width: "8%" }} />
          );
        }
        return <Column {...col} key={key} />;
      });

      let isOccurrences = true;
      if (!rowData.occurrences || rowData.occurrences.length <= 0) {
        isOccurrences = false;
        // return <div>Esta Nota Fiscal não possui ocorrências até o momento.</div>;
      }
      if (isOccurrences) {
        rowData.occurrences.sort((occurrence1, occurrence2) => {
          const date1 = new Date(occurrence1.dateTime);
          const date2 = new Date(occurrence2.dateTime);

          if (date1 === date2) {
            return 0;
          } else if (date1 > date2) {
            return -1;
          }
          return 1;
        });
      }

      const milestones = !rowData.occurrences
        ? []
        : rowData.occurrences.map((occurrence) => {
          const formattedDate = moment(occurrence.dateTime).format("DD/MM/YYYY HH:mm:ss");

          let image = "";
          if (occurrence.code === "01" && occurrence.image != "") {
            image = occurrence.image;
          }

          return {
            title: formattedDate,
            text: occurrence.description,
            image: image,
          };
        });
      return (
        <div>
          <div className="p-grid">
            <div className="p-md-8">
              {isOccurrences &&
                <Timeline
                  milestones={milestones}
                  showVisibleModalImage={() => {
                    let result = milestones.map((item) => {
                      if (item.image) {
                        return item.image;
                      }
                    });
                    this.setCurrentImage(result[0]);
                    this.showVisibleModalImage(true);
                  }}
                ></Timeline>
              }
            </div>
            <div className="p-md-4">
              <DataTable
                className="lxSubTrackingTable"
                selectionMode="single"
                value={[rowData]}
                emptyMessage="Não foram encontrados registros"
              >
                {columnsSub}
              </DataTable>
            </div>
          </div>

          {!isOccurrences &&
            <div>Esta Nota Fiscal não possui ocorrências até o momento.</div>
          }
        </div>
      );
    };

    const setExpandedTrackingRows = (e) => {
      this.props.setExpandedTrackingRows(e.data);
    };

    const buttonRotation = (
      <div>
        <button className="lognex-btn-highlighted" onClick={() => this.changeTransiction()} title={"Girar imagem"}>
          <i className="pi pi-undo"></i>
        </button>
      </div>
    );

    const viewImagemModal = (
      <div>
        <Dialog
          className="lxLookupDialog"
          header="Comprovante de Entrega"
          visible={this.state.showViewModalImage}
          style={{ width: "530px" }}
          modal={true}
          key={crypto.randomUUID()}
          onHide={() => this.showVisibleModalImage(false)}
        >
          {buttonRotation}

          {this.state.image}
        </Dialog>
      </div>
    );

    const content = (
      <div>
        <TrackingList
          {...this.props}
          onFilter={this.onFilter}
          onPage={this.onPage}
          onSort={this.onSort}
          columns={columns}
          trackingTbRowToggleHandler={setExpandedTrackingRows}
          trackingTbRowExpasionTemplateHandler={templateTrackingTbExpandedRow}
          trackingTBExpandedRows={[this.props.record]} //* By now we will always have just one expanded
        ></TrackingList>

        {this.props.modalDeliveryDate.visible && (
          <ModalDate
            header={
              <span
                style={{
                  textAlign: "center",
                  display: "inline-block",
                  width: "80%",
                }}
              >
                Data de entrega
              </span>
            }
            visible={this.props.modalDeliveryDate.visible}
            onConfirm={this.confirmDeliveryDate}
            onHide={this.onHideModalDeliveryDate}
          />
        )}

        {this.props.modalCubageAndVehicleType.visible && (
          <ModalFields
            header={
              <span
                style={{
                  textAlign: "center",
                  display: "inline-block",
                  width: "80%",
                }}
              >
                Cadastrar Cubagem e Tipo de Veículo
              </span>
            }
            visible={this.props.modalCubageAndVehicleType.visible}
            onVehicleTypeChangeHandler={this.onVehicleTypeChangeHandler}
            onCubageChangeHandler={this.onCubageChangeHandler}
            record={this.props.selectCubageVehicleType}
            vehiclesTypeRecords={this.props.listVehiclesType}
            onConfirm={this.onConfirmCubageAndVehicleType}
            onHide={this.onHideModalCubageAndVehicleType}
          />
        )}

        {this.props.modalCustomFields.visible && (
          <ModalCustomFields
            header={"Campos Customizados"}
            onConfirm={(e) => this.onUpdateCustomFields()}
            onHide={(e) => this.onSetShowModalCustomField(false)}
            visible={this.props.modalCustomFields.visible}
            record={this.props.recordWithCustomFields.listCustomFields}
            onChange={(e, field, uuid) => this.onChangeCustomFieldsHandler(e, field, uuid)}
            onAddNewCustomFieldsHandler={this.props.onAddNewCustomFieldsHandler}
            removeItemCustomFieldsHandler={this.props.removeItemCustomFieldsHandler}
          />
        )}

        {this.state.showViewModalImage && viewImagemModal}
      </div>
    );
    return <Page header={header} content={content} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TrackingListPageContainer);
