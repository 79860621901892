import React from "react";
import { InputText } from "primereact/inputtext";
import { LookupField } from "../../../components/lookup/LookupField";
import moment from "moment";
import "./orderDelivery.scss";
import { InputSwitch } from "primereact/inputswitch";
import { OrderRouteList } from "../components/order-route/OrderRouteList";
import { OrderRouteListPage } from "../components/order-route/OrderRouteListPage";
import { formatNumber, numberToCurrency } from "../../../utils";
import { OrderCargoVolumeListPage } from "../components/order-cargo-volume/OrderCargoVolumeListPage";
import { OrderCargoBarcodesListPage } from "../components/order-cargo-barcodes/OrderCargoBarcodesListPage";
import { OrderCargoProductsListPage } from "../components/order-cargo-products/OrderCargoProductsListPage";
import { OrderAttemdanceWindowListPage } from "../components/order-service-attemdance/OrderAttemdanceWindowListPage";

export const OrderDeliveryForm = (props) => {
  let status = "";
  const createdAt = moment(props.record.createdAt).isValid() ? moment(props.record.createdAt).format("DD/MM/YYYY hh:mm") : "";
  const deliveryForecast = moment(props.record.deliveryForecast).isValid() ? moment(props.record.deliveryForecast).format("DD/MM/YYYY hh:mm") : "";
 
  //dados do emitente
  let senderDocumentNumber, senderName, street, streetNumber, district, city, state, zipCode, latitude, longitude, provider, complement;
  if (props.record.sender) {
    senderDocumentNumber = props.record.sender && props.record.sender.documentNumber ? props.record.sender.documentNumber : "";
    senderName = props.record.sender && props.record.sender.name ? props.record.sender.name : "";
    street = props.record.sender.address && props.record.sender.address.street ? props.record.sender.address.street : "";
    streetNumber = props.record.sender.address && props.record.sender.address.number ? props.record.sender.address.number : "";
    district = props.record.sender.address && props.record.sender.address.district ? props.record.sender.address.district : "";
    city = props.record.sender.address && props.record.sender.address.city ? props.record.sender.address.city : "";
    state = props.record.sender.address && props.record.sender.address.state ? props.record.sender.address.state : "";
    zipCode = props.record.sender.address && props.record.sender.address.zipCode ? props.record.sender.address.zipCode : "";
    complement = props.record.sender.address && props.record.sender.address.complement ? props.record.sender.address.complement : "";
    if (props.record.sender.address && props.record.sender.address.georeference) {
      latitude = props.record.sender.address.georeference.latitude;
      longitude = props.record.sender.address.georeference.longitude;
      provider = props.record.sender.address.georeference.provider;
    }
  }

  //dados do destinatario
  const destinatario = {
    documentNumber: "",
    name: "",
    nickName: "",
    contactName: "",
    contactPhoneNumber: "",
    street: "",
    streetNumber: "",
    district: "",
    city: "",
    state: "",
    zipCode: "",
    latitude: "",
    longitude: "",
    provider: "",
    complement: "",
  };
  if (props.record.receiver) {
    destinatario.documentNumber = props.record.receiver.documentNumber ? props.record.receiver.documentNumber : "";
    destinatario.name = props.record.receiver.name ? props.record.receiver.name : "";
    destinatario.nickName = props.record.receiver.nickName ? props.record.receiver.nickName : "";
    destinatario.contactName = props.record.receiver.contactName ? props.record.receiver.contactName : "";

    destinatario.street = props.record.receiver.address && props.record.receiver.address.street ? props.record.receiver.address.street : "";
    destinatario.streetNumber = props.record.receiver.address && props.record.receiver.address.number ? props.record.receiver.address.number : "";
    destinatario.district = props.record.receiver.address && props.record.receiver.address.district ? props.record.receiver.address.district : "";
    destinatario.city = props.record.receiver.address && props.record.receiver.address.city ? props.record.receiver.address.city : "";
    destinatario.state = props.record.receiver.address && props.record.receiver.address.state ? props.record.receiver.address.state : "";
    destinatario.zipCode = props.record.receiver.address && props.record.receiver.address.zipCode ? props.record.receiver.address.zipCode : "";
    destinatario.complement = props.record.receiver.address && props.record.receiver.address.complement ? props.record.receiver.address.complement : "";
    if (props.record.receiver.address && props.record.receiver.address.georeference) {
      destinatario.latitude = props.record.receiver.address.georeference.latitude;
      destinatario.longitude = props.record.receiver.address.georeference.longitude;
      destinatario.provider = props.record.receiver.address.georeference.provider;
    }
  }

  const readyStock = Boolean(props.record.readyStock) ? "Sim" : "Não";

  const shipment = { pickupDate: "", deliveryForecastDate: "", tags: "", calculationCriteria: { type: "", tags: "" }, usaRota: false };

  if (props.record.shipment) {
    shipment.pickupDate = moment(props.record.shipment.pickupDate).isValid() ? moment(props.record.shipment.pickupDate).format("DD/MM/YYYY hh:mm") : "";
    shipment.deliveryForecastDate = moment(props.record.shipment.deliveryForecastDate).isValid()
      ? moment(props.record.shipment.deliveryForecastDate).format("DD/MM/YYYY hh:mm")
      : "";
    shipment.tags = [...props.record.shipment.tags].join(",");
    shipment.calculationCriteria.type = props.record.shipment.calculationCriteria ? props.record.shipment.calculationCriteria.type : "";
    shipment.calculationCriteria.tags = props.record.shipment.calculationCriteria ? [...props.record.shipment.calculationCriteria.tags].join(",") : "";
    shipment.usaRota = props.record.shipment.route ? props.record.shipment.route.useCargoRouting : false;
  }

  //dados da carga
  const dadosCarga = { weight: "", value: 0, cubage: "", volumes: [], barcodes: [], groupBarcode: "" };
  if (props.record.cargo && props.record.cargo.summary) {
    dadosCarga.weight = props.record.cargo.summary.weight;
    dadosCarga.value = props.record.cargo.summary.value;
    dadosCarga.cubage = props.record.cargo.summary.cubage;
    dadosCarga.barcodes = props.record.cargo.summary.barcodes;
    dadosCarga.groupBarcode = props.record.cargo.summary.groupBarcode;
  }

  const service = { type: "", deadline: "", duration: "", observations: "", abilities: "" };
  if (props.record.service) {
    service.type = props.record.service.type;
    service.deadline = moment(props.record.service.deadline).isValid() ? moment(props.record.service.deadline).format("DD/MM/YYYY") : "";
    service.duration = props.record.service.duration;
    service.observations = props.record.service.observations;
    service.abilities = Array.from(props.record.service.abilities).join(",");
  }

  if (props.record.scriptStatus === "NOT_SCRIPTED") {
    if (props.record.readyStock === false) {
      status = "Pedido sem estoque";
    } else {
      status = "Pedido com estoque";
    }
  } else if (props.record.scriptStatus === "SCRIPTING") {
    status = "Em roteirização";
  } else if (props.record.scriptStatus === "SCRIPTED") {
    status = "Roteirizado";
  }

  return (
    <div className="order-delivery-form">
      <div className="p-grid">
        <div className="p-col-6">
          <div className="p-grid">
            <div className="p-col-12">
              <div className="p-grid">
                <div className="p-col-3 form-field-container">
                  <label>Código</label>
                  <InputText value={props.record.code} name="code" />
                </div>
                <div className="p-col-6 form-field-container">
                  <label>Status</label>
                  <InputText value={status} name="scriptStatus" />
                </div>
                <div className="p-col-3 form-field-container">
                  <label>Distribuição</label>
                  <InputText value={props.record.depotCode} name="depotCode" />
                </div>
              </div>
            </div>

            <div className="p-col-12">
              <div className="p-grid">
                <div className="p-col-4 form-field-container">
                  <label>Estoque completo</label>
                  <InputText value={readyStock} name="readyStock" />
                </div>

                <div className="p-col-4 form-field-container">
                  <label>Data de Cadastro</label>
                  <InputText value={createdAt} name="createdAt" />
                </div>

                <div className="p-col-4 form-field-container">
                  <label>Previsão Entrega</label>
                  <InputText value={deliveryForecast} name="deliveryForecast" />
                </div>
              </div>
            </div>

            {/* virtualCD */}

            
            <div className="p-col-12">
              <h3>Emitente</h3>
              <div className="p-grid">
                <div className="p-col-4 form-field-container">
                  <label>Documento</label>
                  <InputText value={senderDocumentNumber} name="sender.documentNumber" />
                </div>

                <div className="p-col-8 form-field-container">
                  <label>Nome</label>
                  <InputText value={senderName} name="sender.name" />
                </div>
              </div>

              <div className="p-grid">
                <div className="p-col-9 form-field-container">
                  <label>Rua</label>
                  <InputText value={street} name="address.street" />
                </div>

                <div className="p-col-3 form-field-container">
                  <label>Nº</label>
                  <InputText value={streetNumber} name="address.number" />
                </div>
              </div>

              <div className="p-grid">
                <div className="p-col-9 form-field-container">
                  <label>Bairro</label>
                  <InputText value={district} name="address.district" />
                </div>

                <div className="p-col-3 form-field-container">
                  <label>CEP</label>
                  <InputText value={zipCode} name="address.zipCode" />
                </div>
              </div>

              <div className="p-grid">
                <div className="p-col-9 form-field-container">
                  <label>Cidade</label>
                  <InputText value={city} name="address.city" />
                </div>

                <div className="p-col-3 form-field-container">
                  <label>Estado</label>
                  <InputText value={state} name="address.state" />
                </div>
              </div>

              <div className="p-grid">
                <div className="p-col-12 form-field-container">
                  <label>Complemento</label>
                  <InputText value={complement} name="address.complement" />
                </div>
              </div>

              <div className="p-grid">
                <div className="p-col-4 form-field-container">
                  <label>Latitude</label>
                  <InputText value={latitude} name="latitude" />
                </div>

                <div className="p-col-4 form-field-container">
                  <label>Longitude</label>
                  <InputText value={longitude} name="longitude" />
                </div>

                <div className="p-col-4 form-field-container">
                  <label>Origem/Provider</label>
                  <InputText value={provider} name="provider" />
                </div>
              </div>
            </div>

            {/* Destinatario */}
            <div className="p-col-12">
              <h3>Destinatário</h3>
              <div className="p-grid">
                <div className="p-col-4 form-field-container">
                  <label>Documento</label>
                  <InputText value={destinatario.documentNumber} name="receive.documentNumber" />
                </div>

                <div className="p-col-8 form-field-container">
                  <label>Nome</label>
                  <InputText value={destinatario.name} name="receive.name" />
                </div>
              </div>

              <div className="p-grid">
                <div className="p-col-3 form-field-container">
                  <label>Apelido</label>
                  <InputText value={destinatario.nickName} name="receive.nickName" />
                </div>

                <div className="p-col-6 form-field-container">
                  <label>Nome Contato</label>
                  <InputText value={destinatario.contactName} name="receive.contactName" />
                </div>

                <div className="p-col-3 form-field-container">
                  <label>Telefone</label>
                  <InputText value={destinatario.contactPhoneNumber} name="receive.contactPhoneNumber" />
                </div>
              </div>

              <div className="p-grid">
                <div className="p-col-9 form-field-container">
                  <label>Rua</label>
                  <InputText value={destinatario.street} name="receive.address.street" />
                </div>

                <div className="p-col-3 form-field-container">
                  <label>Nº</label>
                  <InputText value={destinatario.streetNumber} name="receive.address.number" />
                </div>
              </div>

              <div className="p-grid">
                <div className="p-col-9 form-field-container">
                  <label>Bairro</label>
                  <InputText value={destinatario.district} name="receive.address.district" />
                </div>

                <div className="p-col-3 form-field-container">
                  <label>CEP</label>
                  <InputText value={destinatario.zipCode} name="receive.address.zipCode" />
                </div>
              </div>

              <div className="p-grid">
                <div className="p-col-9 form-field-container">
                  <label>Cidade</label>
                  <InputText value={destinatario.city} name="receive.address.city" />
                </div>

                <div className="p-col-3 form-field-container">
                  <label>Estado</label>
                  <InputText value={destinatario.state} name="receive.address.state" />
                </div>
              </div>

              <div className="p-grid">
                <div className="p-col-12 form-field-container">
                  <label>Complemento</label>
                  <InputText value={destinatario.complement} name="receive.address.complement" />
                </div>
              </div>

              <div className="p-grid">
                <div className="p-col-4 form-field-container">
                  <label>Latitude</label>
                  <InputText value={destinatario.latitude} name="receive.latitude" />
                </div>

                <div className="p-col-4 form-field-container">
                  <label>Longitude</label>
                  <InputText value={destinatario.longitude} name="receive.longitude" />
                </div>

                <div className="p-col-4 form-field-container">
                  <label>Origem/Provider</label>
                  <InputText value={destinatario.provider} name="receive.provider" />
                </div>
              </div>
            </div>

            {/* shipment dados do embarque */}
            <div className="p-col-12">
              <h3>Dados do Embarque</h3>
              <div className="p-grid">
                <div className="p-col-3 form-field-container">
                  <label>Data da Coleta</label>
                  <InputText value={shipment.pickupDate} name="shipment.pickupDate" />
                </div>

                <div className="p-col-3 form-field-container">
                  <label>Previsão de Entrega</label>
                  <InputText value={shipment.deliveryForecastDate} name="shipment.deliveryForecastDate" />
                </div>

                <div className="p-col-6 form-field-container">
                  <label>Tags</label>
                  <InputText value={shipment.tags} name="shipment.tags" />
                </div>
              </div>

              <div className="p-grid">
                <div className="p-col-4 form-field-container">
                  <label>Critérios de cálculo - Tipo</label>
                  <InputText value={shipment.calculationCriteria.type} name="shipment.calculationCriteria.type" />
                </div>

                <div className="p-col-8 form-field-container">
                  <label>Critérios de cálculo - Tags</label>
                  <InputText value={shipment.calculationCriteria.tags} name="shipment.calculationCriteria.tags" />
                </div>
              </div>

              {/* rota */}

              <div className="p-grid">
                <div className="p-col-12 form-field-container">
                  <label>Usa Rota</label>
                  <InputSwitch checked={shipment.usaRota} name="shipment.usaRota" />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* grid Rota */}
        <div className="p-col-12 form-field-container">
          <label>Detalhes da Rota</label>
          <OrderRouteListPage />
        </div>

        {/* Servicos */}
        <div className="p-col-12">
          <h3>Serviços</h3>
          <div className="p-grid">
            <div className="p-col-6">
              <div className="p-grid">
                <div className="p-col-12">
                  <div className="p-grid">
                    <div className="p-col-2 form-field-container">
                      <label>Tipo</label>
                      <InputText value={service.type} name="service.type" />
                    </div>
                    <div className="p-col-2 form-field-container">
                      <label>Prazo Final</label>
                      <InputText value={service.deadline} name="service.deadline" />
                    </div>
                    <div className="p-col-2 form-field-container">
                      <label>Duração</label>
                      <InputText value={service.duration} name="service.duration" />
                    </div>

                    <div className="p-col-6 form-field-container">
                      <label>Habilidades</label>
                      <InputText value={service.abilities} name="service.abilities" />
                    </div>
                  </div>
                </div>

                <div className="p-col-12 form-field-container">
                  <label>Observação</label>
                  <InputText value={service.observations} name="service.observations" />
                </div>

                <div className="p-col-12 form-field-container">
                  <label>Janela de tentativa</label>
                  <OrderAttemdanceWindowListPage />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="p-col-12">
          <h3>Dados da Carga</h3>
          <div className="p-grid">
            <div className="p-col-6">
              <div className="p-grid">
                <div className="p-col-12">
                  <div className="p-grid">
                    <div className="p-col-3 form-field-container">
                      <label>Peso</label>
                      <InputText value={formatNumber(dadosCarga.weight, 3)} name="cargo.summary.weight" />
                    </div>

                    <div className="p-col-3 form-field-container">
                      <label>Valor</label>
                      <InputText value={numberToCurrency(dadosCarga.value, "REAL")} name="cargo.summary.value" />
                    </div>

                    <div className="p-col-2 form-field-container">
                      <label>Cubagem</label>
                      <InputText value={formatNumber(dadosCarga.cubage)} name="cargo.summary.cubage" />
                    </div>

                    <div className="p-col-4 form-field-container">
                      <label>Código de barras(Grupo)</label>
                      <InputText value={dadosCarga.groupBarcode} name="cargo.summary.groupBarcode" />
                    </div>
                  </div>

                  <div className="p-grid">
                    <div className="p-col-12 form-field-container">
                      <label>Código de barras</label>
                      <OrderCargoBarcodesListPage />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="p-col-12 form-field-container">
              <label>Volumes</label>
              <OrderCargoVolumeListPage />
            </div>
            <div className="p-col-12 form-field-container">
              <label>Produtos</label>
              <OrderCargoProductsListPage />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
