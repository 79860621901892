import React from 'react'
import { connect } from "react-redux";
import LoginForm from './login-form.component';
import * as loginActions from './login.actions';
import './LoginPageContainer.scss'
import background from '../../assets/images/login/login_background.jpeg';

const mapStateToProps =  state =>{
    return {
        credential: state.loginState.credential,
        errorMessage: state.loginState.errorMessage
    }
}
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        authenticate:()=>{
            dispatch(loginActions.authenticate());
        },
        inputHandler:(event) =>{
            const value = event.target.value;
            const key = event.target.name;
            const credential = {[key]:value};
            dispatch(loginActions.loginSetCredential(credential));
            
        }
    }
}



class LoginContainer extends React.Component{
    
    render(){
        return (
            <div className="login-page">
            <div className="login-page__content">
                <div className="login-page__content-form">
                    <LoginForm
                    errorMessage={this.props.errorMessage}
                    login={this.props.credential.login}
                    password={this.props.credential.password}
                    changeValue={this.props.inputHandler}
                    authenticate={this.props.authenticate}></LoginForm>
                </div>
               
                <img className="login-page__img-backgroud" src={background} alt="background"/>

            </div>
           
            </div>
        )
    }
}

export default  connect(mapStateToProps,mapDispatchToProps)(LoginContainer);