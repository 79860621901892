import React from 'react';
import './Timeline.scss'
import { TimelineBallon } from './TimelineBallon'
export const Timeline = (props) => {

    const timelineBallons = props.milestones.map((milestone, index) => {
        return (
            <div className="lxTimeline" key={crypto.randomUUID()}>
                <div className="lxTimeline__milestone">
                    <div className="circle"></div>
                    <TimelineBallon
                        title={milestone.title}
                        subTitle={milestone.subTitle}
                        text={milestone.text}
                        image={milestone.image}
                        showVisibleModalImage={props.showVisibleModalImage}
                        right={index % 2 !== 0}
                    >
                    </TimelineBallon>
                </div>
                <div className="line"></div>
            </div>
        )
    })
    return (
        <div className="timeline" key={crypto.randomUUID()}>
            {timelineBallons}
        </div>
    );
}
