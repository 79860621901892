
import React from "react";
import { connect } from "react-redux";
import Toolbar from "../../../components/toolbar/Toolbar";
import { Column } from 'primereact/column';
import { Calendar } from 'primereact/calendar';
import { routes } from '../../../constants';
import { Page } from "../../../components/page/Page";
import { FreightTableList } from "./FreightTableList";
import { actions } from '../freightTable.actions'
import { AppService, COMPANY_CHANGE_EVENT_NAME, BUSINESS_UNIT_CHANGE_EVENT_NAME } from "../../../services/app.service";
import moment from 'moment';
import { actions as appActions } from '../../../store/app';
import * as menuActions from '../../../store/menu/menu.actions';
import * as utils from '../../../utils'
import { Dropdown } from "primereact/dropdown";

const mapStateToProps = state => {
  const currentMenu = state.menuState.currentRecord;
  const userConfigurations = state.appState.userConfigurations;
  let isFavorite = utils.isFavorite(currentMenu, userConfigurations);
  const fields = state.crudFreightTableState.columns.map(col => col.field)
  return {
    fields,
    columns: state.crudFreightTableState.columns,
    records: state.crudFreightTableState.records,
    first: state.crudFreightTableState.index,
    rows: state.crudFreightTableState.max,
    totalRecords: state.crudFreightTableState.count,
    sortField: state.crudFreightTableState.sortField,
    order: state.crudFreightTableState.sortOrder,
    selectedFreightTables: state.crudFreightTableState.selectedRecords,
    menuItems: state.menuState.items,    
    currentMenu,
    isFavorite,
    active: state.crudFreightTableState.active,
    activeFilterOptions:[
      { label: "TODOS", value: '' },
      { label: "SIM", value: 'true' },
      { label: "NÃO", value: 'false' },
    ],
    initialValidity: state.crudFreightTableState.initialValidity,
    finalValidity: state.crudFreightTableState.finalValidity,
  }
}
const mapDispatchToProps = dispatch => {
  return {
    paginate: paginationParams => dispatch(actions.paginate(paginationParams)),
    sortOrder: sortParams => dispatch(actions.sortOrder(sortParams)),
    applyFilter: (filterParams,fields) => {
      dispatch(actions.setType(null));
      dispatch(actions.applyFilter(filterParams,{fields}))
    },
    load: (params) => dispatch(actions.load(params)),
    userLastAccess: (oidMenu) => dispatch(appActions.userLastAccess(oidMenu)),
    setCurrentMenu: (menuPath) => dispatch(menuActions.setCurrentRecord(menuPath)),
    toggleFavorite: (isFavorite, oidMenu) => {
      dispatch(appActions.toggleFavorite(isFavorite, oidMenu))
    },
    setSelection: (e) => {
      dispatch(actions.setSelectedRecords(e.value))
    },
    duplicate:()=>{
      dispatch(actions.duplicateSelectedRecords());
    },
    setActiveFilter: (value) => dispatch(actions.setActiveFilter(value)),
    setInitialValidatyFilter: (value) => dispatch(actions.setInitialValidityFilter(value)),
    setFinalValidatyFilter: (value) => dispatch(actions.setFinalValidityFilter(value)),
    changeDateColumnFilter: (e, field,fields) => {  
      const target = e.target;
      if(field === "initialValidity"){
        dispatch(actions.setInitialValidityFilter(target.value));
      } else {
        dispatch(actions.setFinalValidityFilter(target.value));
      }       
      let filters = [];
      if (target.value  || target.value === false) {
        filters = [{
          campo: `${target.name}`,
          valor: target.value,
          tipoFiltro: 'IGUAL_A'
        }];
      }
      dispatch(actions.applyFilter(filters,{fields}));
    },
  }
}


class FreightTableListPageContainer extends React.Component {

  newHandler = () => {
    this.props.history.push(`${routes.PATH_FREIGHT_TABLE}/novo`)
  }

  duplicateHandler = () => {
    this.props.duplicate();
  }

  componentWillReceiveProps(newProps) {
    if ((!this.props.menuItems || this.props.menuItems.length === 0) && newProps.menuItems && newProps.menuItems.length > 0) {
      const menuPath = this.props.history.location.pathname;
      this.props.setCurrentMenu(menuPath)
    }

    if ((!this.props.currentMenu && newProps.currentMenu) ||
      (this.props.currentMenu !== newProps.currentMenu)) {
      const oidMenu = newProps.currentMenu.oid;
      this.props.userLastAccess(oidMenu)
    }
  }

  componentDidMount() {
    this.props.load({fields:this.props.fields});
    AppService.on(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.on(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
    const menuPath = this.props.history.location.pathname;
    this.props.setCurrentMenu(menuPath)
  }

  componentWillUnmount() {
    AppService.off(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.off(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
  }

  onReceiveAppNotifications = (eventName, params) => {
    if (eventName === COMPANY_CHANGE_EVENT_NAME || eventName === BUSINESS_UNIT_CHANGE_EVENT_NAME) {
      this.props.load({fields:this.props.fields});
    }
  }

  onPage = (e) => {
    this.props.paginate({
      max: e.rows,
      index: e.first
    })
  }

  onSort = (e) => {
    this.props.sortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField
    })
  }

  onFilter = (e) => {
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach(key => {
      if(key === "oid"){
        filters.push({
          campo: key,
          valor: parseInt(e.filters[key].value),
          tipoFiltro: 'IGUAL_A'
        });
      }else{
        filters.push({
          campo: key,
          valor: e.filters[key].value,
          tipoFiltro: 'CONTEM'
        });
      }
    });
    this.props.applyFilter(filters,this.props.fields);
  }

  onRowClick = (e) => {
    const oid = e && e.data && e.data.oid;
    if (oid) {
      this.props.history.push(`${routes.PATH_FREIGHT_TABLE}/${oid}`)
    }
  }

  onSelectionChange = (e) => {    
    this.props.setSelection(e)
  }

  onFavorite = (e) => {
    if (this.props.currentMenu) {
      const oidMenu = this.props.currentMenu.oid
      const isFavorite = this.props.isFavorite;
      this.props.toggleFavorite(isFavorite, oidMenu)
    }
  }

  onActiveFilterChange = (e) => {
    const target = e.target;
    const field = target.name;
    const value = target.value;
    let filters = [];
    this.props.setActiveFilter(value);
    if(value){
      filters.push({
        campo: field,
        valor: value,
        tipoFiltro: 'IGUAL_A'
      });
    }
    this.props.applyFilter(filters,this.props.fields);
  }

  render() {
    const isFavorite = this.props.isFavorite;
    const header = (
      <div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h1>Tabelas de frete</h1>

          <i className={`pi pi-star lxFavoriteStar ${isFavorite ? 'lxFavorite' : ''}`}
            title={isFavorite ? 'Remover dos favoritos' : 'Adicionar aos favoritos'} onClick={this.onFavorite}></i>
        </div>
        <hr className="lxSplitter" ></hr>
        <div>
          <Toolbar onNew={this.newHandler} newButtonStyle='highlighted'
                   onDuplicate={this.duplicateHandler} duplicateButtonStyle='highlighted' >
          </Toolbar >
        </div>
      </div>
    );

    const negotiationTypeTemplate = (rowData, column) => {
      const data = {
        BOTH: "Ambos", EARLYBILLOFLADING: "Pré-conhecimento", SHIPMENT: "Embarque"
      }
      const value = data[rowData.negotiationType] || '';
      return (
        <div style={{ textAlign: 'center' }}>{value}</div>
      )
    }

    const booleanFieldsTemplate = (rowData, column) => {
      const result = rowData[column.field];
      if (result) {
        return <div style={{ color: 'green', display: 'flex', justifyContent: 'center' }}><span className="pi pi-check-circle" style={{ 'fontSize': '1.8rem' }} /> </div>;
      } else {
        return <div style={{ color: 'red', display: 'flex', justifyContent: 'center' }}><span className="pi pi-times-circle" style={{ 'fontSize': '1.8rem' }} /></div>;
      }
    };

    const activeFilter = (field) => {
      return (
        <Dropdown
          style = {{ width: "100%" }}
          name = {field}
          value={this.props.active}
          options={this.props.activeFilterOptions}
          onChange={(e) => this.onActiveFilterChange(e)}
        />  
      )
    };

    const dateTemplate = (rowData, column) => {
      const date = rowData[column.field];
      const formattedDate = moment(date).format('DD/MM/YYYY')
      return <div style={{ textAlign: 'center', color: 'black' }}>{formattedDate}</div>
    };

    const dateFilter = (field) => {
      return (
        <div className="p-calendar">
          <Calendar name={field}
            dateFormat="dd/mm/yy"
            value={this.props[field]}
            onChange={(e)=> this.props.changeDateColumnFilter(e, field)}
          />
        </div>
      )  
    };


    
    let columns = [<Column key="selectorColumn" selectionMode="multiple" style={{ width: '3em' }} />]
      columns = [...columns,
          ...this.props.columns.map(col => {
        if ('active' === col.field) {
          return <Column {...col} key={col.field} body={booleanFieldsTemplate} filterElement={activeFilter(col.field)} />;
        } else if (col.field === 'negotiationType') {
          return (<Column {...col} key={col.field} body={negotiationTypeTemplate} />);
        } else if (col.field === 'initialValidity') {
          return (<Column {...col} key={col.field} body={dateTemplate} filterElement={dateFilter(col.field)}  />);
        } else if (col.field === 'finalValidity') {
          return (<Column {...col} key={col.field} body={dateTemplate} filterElement={dateFilter(col.field)}  />);  
        } 
        return (<Column {...col} key={col.field} />)
      }
    )];

    const content = <FreightTableList  {...this.props} onSelectionChange={this.onSelectionChange} onRowClick={this.onRowClick} onFilter={this.onFilter} onPage={this.onPage} onSort={this.onSort} columns={columns} />;
    return <Page header={header} content={content} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FreightTableListPageContainer);
