import moment from 'moment';
import { Reducer } from '../../services/reducer.factory';
import { types } from './collectionOrder.actions';

const initialState = {
    currentRecord: {
        pickupDate: "",
        vehicle: {
            plate: "",
            vehicleType: ""
        },
        origin: "",
        destination: "",
        collectionAddress: {},
        sender: {
            name: "",
            documentNumber: ""
        },
        cargo: {},
        driver: {},
        shippingCarrier: "",
        status: "",
        disabledBtnConfirmm: false,
        disabledBtnReject: false,
        towerIntegration: {},
        deliveryDate: "",
        observation: "",
        codeOrder: "",
        dockScheduling: [
            {
                sigla: "",
                documentNumber: "",
                rangeTime: [],
                useDocas: false,
            }
        ]
    },
    orders: [],
    ordersColumns: [
        {
            field: "code",
            header: "Código",
            columnKey: "code",
            sortable: false,
            filter: false
        },
        {
            field: "origin",
            header: "Origem",
            columnKey: "origin",
            sortable: false,
            filter: false
        },
        {
            field: "destination",
            header: "Destino",
            columnKey: "destination",
            sortable: false,
            filter: false
        },
        {
            field: "image",
            header: "Comprovante de entrega",
            columnKey: "image",
            sortable: false,
            filter: false
        },
    ],
    status: "",
    equipmentTypes: [],
    docasHourSchedules: [],
    showMessageErro: false,
    errorMessage: "",
    fileImg: null,
    currentOrderEdit: null,
    observation: "",
    deliveryDate: null,
    showModalUploadImg: false
}


const reducer = new Reducer(initialState, types);

export function CollectionOrderReducer(state, action) {
    state = reducer.execute(state, action);
    return execute(state, action);
}

function execute(state, action) {
    let result = state;
    switch (action.type) {
        case types.SET_COLLECTION_ORDER:
            return setCollectionOrder(state, action);
        case types.SET_REQUEST_STATUS:
            return setStatus(state, action);
        case types.SET_DISABLED_BTN_CONFIRM:
            return setDisabledBtnConfirm(state, action);
        case types.SET_DISABLED_BTN_REJECT:
            return setDisabledBtnReject(state, action);
        case types.SET_CODE_ORDER:
            return setCodeOrder(state, action);
        case types.SET_ORDERS:
            return setOrders(state, action);
        case types.SET_SHOW_MODAL_UPLOAD_IMG:
            return setShowModalUploadImg(state, action);
        case types.SET_EQUIPMENT_TYPE:
            return setEquipmentType(state, action);
        case types.SET_HOURS_DOCKS_RECODRS:
            return setHoursDocks(state, action);
        case types.SET_ERROR_MESSAGE:
            return setErrorMessage(state, action);
        case types.ON_SHOW_MESSAGE_ERROR:
            return setShowMessageErro(state, action);
        case types.SET_SELECTED_DOCK_SCHEDULING:
            return setDockScheduling(state, action);
        case types.ON_CHANGE_HANDLE_IMAGE:
            return changeHandlerImage(state, action);
        case types.SET_DELIVERY_DATE:
            return onChangeDeliveryDate(state, action);
        case types.SET_OBSERVATION:
            return onChangeObs(state, action);
        default:
            return result
    }
}


function onChangeObs(state, action) {
    let currentOrderEdit = state.currentOrderEdit
    currentOrderEdit.observation = action.obs;
    return { ...state, currentOrderEdit, showModalUploadImg: true, observation: action.obs };
}
function onChangeDeliveryDate(state, action) {
    let currentOrderEdit = state.currentOrderEdit
    currentOrderEdit.deliveryDate = action.deliveryDate;

    return { ...state, currentOrderEdit, showModalUploadImg: true, deliveryDate: action.deliveryDate };
}
function changeHandlerImage(state, action) {

    let fileImg = action.fileImg;
    return { ...state, fileImg };
}

function setDockScheduling(state, action) {

    let currentRecord = state.currentRecord

    if (!currentRecord.dockScheduling) {
        currentRecord.dockScheduling = [];
    }

    let dockSelected = action.dockSelected;
    // console.log("set dockSelected=", dockSelected)
    //verificar se ja foi add 
    for (let index = 0; index < currentRecord.dockScheduling.length; index++) {
        if (currentRecord.dockScheduling[index].sigla == dockSelected.sigla && currentRecord.dockScheduling[index].documentNumber == dockSelected.documentNumber) {
            currentRecord.dockScheduling[index].rangeTime = dockSelected;
            break;
        }
    }
    return Object.assign({}, state, { currentRecord })
    // return { ...state, currentRecord };
}

function setHoursDocks(state, action) {
    const docasHourSchedules = []
    let currentRecord = state.currentRecord

    let dockScheduleAdd = false;
    if (currentRecord.dockScheduling && currentRecord.dockScheduling.length > 0) {
        currentRecord.dockScheduling.forEach(element => {
            if (element.dockScheduleId) {
                dockScheduleAdd = true;
            }
        });
    }


    if (!dockScheduleAdd) {
        currentRecord.dockScheduling = []
        let docasHoursRangeTime = action.docasHoursRangeTime;
        if (docasHoursRangeTime) {

            docasHoursRangeTime.forEach(docaCd => {
                // console.log("docasHoursRangeTime docaCd=", docaCd)
                if (docaCd.useDocas) {
                    const docaHoraios = {
                        sigla: docaCd.sigla,
                        label: docaCd.label,
                        rangeTime: [],
                        useDocas: docaCd.useDocas,
                        documentNumber: docaCd.documentNumber,
                    }
                    if (docaCd.docaHoraios) {
                        docaCd.docaHoraios.forEach(d => {
                            d.rangeTime.forEach(r => {
                                const dateStart = moment(r.dateStart)
                                const dateEnd = moment(r.dateEnd)
                                docaHoraios.rangeTime.push({
                                    index: docaHoraios.rangeTime.length + 1,
                                    docaId: d.docaId,
                                    sigla: docaCd.sigla,
                                    name: d.docaName,
                                    label: docaCd.label,
                                    labelView: `${d.docaName} ${dateStart.format("DD/MM/YYYY")} (${dateStart.format("HH:mm")} às ${dateEnd.format("HH:mm")})`,
                                    dateStart: dateStart.toDate(),
                                    dateEnd: dateEnd.toDate(),
                                    operationType: d.operationType,
                                    packagingType: d.packagingType,
                                    operationTime: d.operationTime,
                                    documentNumber: docaCd.documentNumber,
                                })
                            });
                        });
                    }

                    docaHoraios.rangeTime.sort((a, b) => a.dateStart - b.dateStart);
                    docasHourSchedules.push(docaHoraios)
                    //ordernar pela doca q tem a menor data de agendamento 
                    currentRecord.dockScheduling.push(
                        {
                            "sigla": docaHoraios.sigla,
                            "label": docaHoraios.label,
                            "labelView": docaHoraios.label,
                            "useDocas": docaHoraios.useDocas,
                            "rangeTime": docaHoraios.rangeTime[0],
                            "documentNumber": docaHoraios.documentNumber,
                        }
                    )
                }
            });
        }

    } else {
        const newListShed = []
        currentRecord.dockScheduling.forEach((scheduling, index) => {
            const dateStart = moment(scheduling.dateStart)
            const dateEnd = moment(scheduling.dateEnd)
            const docaHoraios = {
                sigla: scheduling.sigla,
                label: scheduling.label,
                rangeTime: [],
                useDocas: true,
                documentNumber: scheduling.documentNumber
            }

            docaHoraios.rangeTime = [{
                index: index,
                docaId: scheduling.docaId,
                name: scheduling.name,
                label: scheduling.label,
                dockScheduleId: scheduling.dockScheduleId,
                labelView: `${scheduling.name} - ${dateStart.format("DD/MM/YYYY")} (${dateStart.format("HH:mm")} às ${dateEnd.format("HH:mm")})`,
                dateStart: dateStart.toDate(),
                dateEnd: dateEnd.toDate(),
                sigla: scheduling.sigla,
                documentNumber: scheduling.documentNumber,
            }]
            docasHourSchedules.push(docaHoraios)
            newListShed.push(
                {
                    "sigla": docaHoraios.sigla,
                    "label": docaHoraios.label,
                    "labelView": docaHoraios.labelView,
                    "useDocas": docaHoraios.useDocas,
                    "rangeTime": docaHoraios.rangeTime[0],
                    "documentNumber": docaHoraios.documentNumber,
                    dockScheduleId: scheduling.dockScheduleId,
                }
            )
        });
        currentRecord.dockScheduling = newListShed;
    }
    return { ...state, docasHourSchedules, currentRecord };
}
function setEquipmentType(state, action) {
    let equipmentTypes = state.equipmentTypes;
    equipmentTypes = action.equipmentTypes;
    let currentRecord = state.currentRecord;

    //caso tenaha somente um equipamento cadastrado, deixar selecionado
    if (equipmentTypes && equipmentTypes.length == 1) {
        const { name, oid } = equipmentTypes[0];
        currentRecord.towerIntegration.equipmentType = { name, oid };
    }
    return { ...state, equipmentTypes, currentRecord };
}

function setCollectionOrder(state, action) {
    let currentRecord = state.currentRecord;
    currentRecord = { ...currentRecord, ...action.collectionOrder };
    return { ...state, currentRecord };
}

function setShowModalUploadImg(state, action) {
    let showModalUploadImg = action.visible;
    return { ...state, showModalUploadImg };
}

function setCodeOrder(state, action) {
    let currentOrderEdit = action.order;
    const deliveryDate = currentOrderEdit && currentOrderEdit.deliveryDate ? moment(currentOrderEdit.deliveryDate).toDate() : "";
    const observation = currentOrderEdit && currentOrderEdit.observation ? currentOrderEdit.observation : "";


    return { ...state, currentOrderEdit, observation, deliveryDate };
}

function setOrders(state, action) {
    return { ...state, orders: action.orders };
}

function setStatus(state, action) {
    const status = action.status;
    return { ...state, status };
}

function setDisabledBtnConfirm(state, action) {
    let currentRecord = { ...state.currentRecord };
    currentRecord.disabledBtnConfirmm = action.disabled;
    return { ...state, currentRecord }
}

function setDisabledBtnReject(state, action) {
    let currentRecord = { ...state.currentRecord };
    currentRecord.disabledBtnReject = action.disabled;
    return { ...state, currentRecord };
}
function setErrorMessage(state, action) {
    let errorMessage = action.message;
    return { ...state, errorMessage };
}
function setShowMessageErro(state, action) {
    let showMessageErro = action.visible;
    return { ...state, showMessageErro };
}