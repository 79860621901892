import React from 'react';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from "primereact/dropdown";
import { AutoComplete } from 'primereact/autocomplete';
import { LookupField } from '../../../components/lookup/LookupField';
import { InputWhitSearchPluss } from '../../../components/input-with-search-pluss/InputWhitSearchPluss'
import './Filter.scss';
import { InputSwitch } from 'primereact/inputswitch';

export const Filter = (props) => {

    const businessUnitGroup = props.filterFields.businessUnitGroup ? props.filterFields.businessUnitGroup : null;
    const canFilter = props.canFilter || true;

    return (
        <div className="order-management-form">
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="p-grid">
                        <div className="p-col-3 form-field-container">
                            <label className="lxRequiredField">Unidade</label>
                            <Dropdown
                                placeholder="Selecione uma unidade"
                                value={props.filterFields.currentBusinessUnit}
                                dataKey={"oid"}
                                optionLabel="label"
                                options={props.filterFields.dropdownBusinessUnitsOptions}
                                onChange={props.actions.onDropdownBusinessUnitChange} />
                        </div>
                        <div className="p-col-3 form-field-container">
                            <label className="lxRequiredField">Agrupador</label>
                            <InputWhitSearchPluss
                                onClickSearchHandler={props.actions.clickSearchHandler}
                                onClickAddHandler={props.actions.clickAddHandler}
                                onComplete={props.actions.onComplete}
                                onInputFilter={props.actions.onInputFilter}
                                records={props.records}
                                placeholder={"Selecione o Agrupador"}
                                value={businessUnitGroup.label || ""}
                                name="businessUnitGroup"
                                field="label"
                            ></InputWhitSearchPluss>
                        </div>
                        <div className="p-col-2 form-field-container">
                            <label className="lxRequiredField">De</label>
                            <Calendar showIcon={true} value={props.filterFields.initialDate} showTime={false} onChange={props.actions.initialDateChangeHandler} disabled={false} name="initialDate" dateFormat="dd/mm/yy" />
                        </div>
                        <div className="p-col-2 form-field-container">
                            <label className="lxRequiredField">Até</label>
                            <Calendar showIcon={true} value={props.filterFields.finalDate} showTime={false} onChange={props.actions.finalDateChangeHandler} disabled={false} name="finallDate" dateFormat="dd/mm/yy" />
                        </div>
                        <div className="p-col-2 form-field-container">
                            <label>Número</label>
                            <InputText value={props.filterFields.number ? props.filterFields.number : ""} onChange={props.actions.numberChangeHandler} name="number" />
                        </div>

                    </div>
                    <div className="p-grid">

                        <div className="p-col-3 form-field-container">
                            <label>Origem</label>
                            <LookupField
                                value={props.filterFields.origin ? props.filterFields.origin : null}
                                placeholder="Origem"
                                name="description"
                                field="description"
                                records={props.lookupOrigin.records}
                                columns={props.lookupOrigin.columns}
                                totalRecords={props.lookupOrigin.totalRecords}
                                sortOrder={props.lookupOrigin.order}
                                sortField={props.lookupOrigin.sortField}
                                first={props.lookupOrigin.first}
                                rows={props.lookupOrigin.rows}
                                visible={props.lookupOrigin.visible}
                                modal={props.lookupOrigin.modal}
                                header={props.lookupOrigin.header}
                                editDisabled={props.lookupOrigin.editDisabled}
                                onEditField={props.lookupOrigin.lookupOriginOnEdit}
                                onClick={props.lookupOrigin.lookupOriginOnClick}
                                onHide={props.lookupOrigin.lookupOriginOnHide}
                                onConfirm={props.lookupOrigin.lookupOriginOnConfirm}
                                onCancel={props.lookupOrigin.lookupOriginOnCancel}
                                onComplete={props.lookupOrigin.lookupOriginOnComplete}
                                onInputFilter={props.lookupOrigin.lookupOriginOnInputFilter}
                                onPage={props.lookupOrigin.lookupOriginOnPage}
                                onFilter={props.lookupOrigin.lookupOriginOnFilter}
                                onSort={props.lookupOrigin.lookupOriginOnOnSort}
                            />
                        </div>
                        <div className="p-col-3 form-field-container">
                            <label>Destino</label>
                            <LookupField
                                value={props.filterFields.destination ? props.filterFields.destination : null}
                                placeholder="Destino"
                                name="description"
                                field="description"
                                records={props.lookupDestination.records}
                                columns={props.lookupDestination.columns}
                                totalRecords={props.lookupDestination.totalRecords}
                                sortOrder={props.lookupDestination.order}
                                sortField={props.lookupDestination.sortField}
                                first={props.lookupDestination.first}
                                rows={props.lookupDestination.rows}
                                visible={props.lookupDestination.visible}
                                modal={props.lookupDestination.modal}
                                header={props.lookupDestination.header}
                                editDisabled={props.lookupDestination.editDisabled}
                                onEditField={props.lookupDestination.lookupDestinationOnEdit}
                                onClick={props.lookupDestination.lookupDestinationOnClick}
                                onHide={props.lookupDestination.lookupDestinationOnHide}
                                onConfirm={props.lookupDestination.lookupDestinationOnConfirm}
                                onCancel={props.lookupDestination.lookupDestinationOnCancel}
                                onComplete={props.lookupDestination.lookupDestinationOnComplete}
                                onInputFilter={props.lookupDestination.lookupDestinationOnInputFilter}
                                onPage={props.lookupDestination.lookupDestinationOnPage}
                                onFilter={props.lookupDestination.lookupDestinationOnFilter}
                                onSort={props.lookupDestination.lookupDestinationOnOnSort}
                            />
                        </div>
                        <div className="p-col-3 form-field-container">
                            <label>Transportadora</label>
                            <LookupField
                                value={props.filterFields.carrier ? props.filterFields.carrier : null}
                                placeholder="Transportadora"
                                name="tradeName"
                                field="tradeName"
                                records={props.lookupCarrier.records}
                                columns={props.lookupCarrier.columns}
                                totalRecords={props.lookupCarrier.totalRecords}
                                sortOrder={props.lookupCarrier.order}
                                sortField={props.lookupCarrier.sortField}
                                first={props.lookupCarrier.first}
                                rows={props.lookupCarrier.rows}
                                visible={props.lookupCarrier.visible}
                                modal={props.lookupCarrier.modal}
                                header={props.lookupCarrier.header}
                                editDisabled={props.lookupCarrier.editDisabled}
                                onEditField={props.lookupCarrier.lookupCarrierOnEdit}
                                onClick={props.lookupCarrier.lookupCarrierOnClick}
                                onHide={props.lookupCarrier.lookupCarrierOnHide}
                                onConfirm={props.lookupCarrier.lookupCarrierOnConfirm}
                                onCancel={props.lookupCarrier.lookupCarrierOnCancel}
                                onComplete={props.lookupCarrier.lookupCarrierOnComplete}
                                onInputFilter={props.lookupCarrier.lookupCarrierOnInputFilter}
                                onPage={props.lookupCarrier.lookupCarrierOnPage}
                                onFilter={props.lookupCarrier.lookupCarrierOnFilter}
                                onSort={props.lookupCarrier.lookupCarrierOnOnSort}
                            />
                        </div>

                        <div className="p-col-3 form-field-container">
                            <label>Somente devolução</label>
                            <InputSwitch checked={props.filterFields.onlyDevolucao ?? false} onChange={props.actions.onlyDevolucaoChangeHandler} />
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-10 form-field-container">
                            <label>Labels</label>
                            <AutoComplete
                                className='lognex-label'
                                completeMethod={props.actions.completeMethodLabel}
                                onChange={props.actions.labelChangeHandler}
                                suggestions={props.filterFields.labelList}
                                placeholder="Label"
                                value={props.filterFields.label ? props.filterFields.label : null}
                                name="label"
                                field="name"
                                disabled={props.disabled}
                                multiple={true}
                            />
                        </div>

                        <div className="p-col-2 form-field-container">
                            <br />
                            <button
                                className="lognex-btn-highlighted"
                                disabled={!canFilter}
                                style={{ width: "100%" }}
                                title="Filtrar"
                                onClick={props.actions.applyFilter}>
                                Filtrar
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}
