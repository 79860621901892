import React from 'react'
import { InputText } from "primereact/inputtext"

export class InputPassword extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            currentValue: this.props.value || "",
            showPassword: false
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({ currentValue: this.props.value || "" });
        }
    }

    toggleShowPassword = () => {
        this.setState((prevState) => ({
            showPassword: !prevState.showPassword
        }));
    }

    onChangePasswordHandler = (e) => {
        const value = e.target.value;

        this.setState({
            currentValue: value,
        });
        this.props.onChange(e);
    }


    render() {
        return (
            <div className="lxInputPassword">
                <div className="p-inputgroup">
                    <InputText
                        name={this.props.name}
                        value={this.state.currentValue}
                        onChange={(e) => {
                            e.stopPropagation();
                            this.onChangePasswordHandler(e);
                        }}
                        type={this.state.showPassword ? "text" : "password"}
                        style={{ width: 'calc(100% - 36px)' }}
                    />

                    <button
                        className="lognex-btn-highlighted"
                        onClick={this.toggleShowPassword}
                    >
                        {this.state.showPassword ? (
                            <i className="pi pi-eye-slash" />
                        ) : (
                            <i className="pi pi-eye" />
                        )}
                    </button>

                </div>
            </div>
        )
    }
}