
import api from '../../services/api.service';
import { build } from '../../services/crudFactory';
import { validate } from './validations';

import { makeMessages } from '../../store/message';


import { Action } from '../../services/actions.factory';
import * as messageActions from '../../store/message/message.actions';
import { actions as userActions } from '../usuario/usuario.actions';


const BASE_PATH = '/lognex/drivers';
const prefix = 'crudDriver';

const crud = build(validate, BASE_PATH);

const newTypes = {
   SET_USER_ID: `${prefix}setUserId`,
   SET_VALIDATE_CNH: `${prefix}setValidateCNH`,
}

const actions = new Action(prefix, crud);

const types = Object.assign({}, actions.types, newTypes);

actions.setValidateCNH = (date) => {
   return {
      type: types.SET_VALIDATE_CNH,
      date
   }
}

actions.setUserId = (userId) => {
   return {
      type: types.SET_USER_ID,
      userId
   }
}

actions.saveDriver = () => {
   return async (dispatch, getState) => {
      let current = getState().crudDriverState.currentRecord;

      if (current.carrier && current.carrier.cnpj && current.carrier.cnpj != "") {


         let userFound = await getUserFromDriver(current.documentNumber);

         //salvar motorista
         if (!current.oid && !userFound) {
            dispatch(userActions.saveUserFromUserDriver(current, (result) => {
               // cadastro de usuário deu certo
               if (result) {
                  const userId = result.oid;
                  //adiciona no currentRecord o id do usuário cadastrado
                  current.userId = userId;

                  // setar o password do motorista 
                  current.password = result.password;


                  try {
                     //salva o motorista
                     dispatch(actions.save());
                     dispatch(
                        messageActions.messageShowMessages(
                           makeMessages(["Procedimento realizado com sucesso."], "success")
                        )
                     );

                  } catch (error) {
                     // se der erro ao salvaro motorista deve excluir o usuáruio gerado pelos dados do motorista
                     dispatch(userActions.removeUserFromDriver(userId)
                        .then((response) => {
                           console.log(response);
                        })
                        .catch((error) => {
                           console.log(error)
                           dispatch(
                              messageActions.messageShowMessages(
                                 makeMessages(["Ops!, erro ao cadastrar motorista, contate o administrador do sistema."], "error")
                              )
                           );
                        })
                     )
                  }
               }
            },
               (err) => {
                  console.log(err);
                  dispatch(
                     messageActions.messageShowMessages(
                        makeMessages(["Erro ao salvar o motorista."], "error")
                     )
                  );
               })
            );


         } else {
            try {
               dispatch(userActions.saveUserFromUserDriver(current, (status) => {
                  console.log("retorno do metodo saveUserFromUserDriver => ", status);
                  // atualização do usuário deu certo
                  if (status === 200) {
                     try {
                        //salva o motorista
                        dispatch(actions.save());
                        dispatch(
                           messageActions.messageShowMessages(
                              makeMessages(["Atualização realizada com sucesso."], "success")
                           )
                        );

                     } catch (error) {
                        // se der erro ao salvar o motorista deve excluir o usuário gerado pelos dados do motorista
                        dispatch(userActions.removeUserFromDriver(current.userId)
                           .then((response) => {
                              console.log(response);
                           })
                           .catch((error) => {
                              console.log(error)
                              dispatch(
                                 messageActions.messageShowMessages(
                                    makeMessages(["Ops!, erro ao atualizar motorista, contate o administrador do sistema."], "error")
                                 )
                              );
                           })
                        )
                     }
                  } else {
                     dispatch(
                        messageActions.messageShowMessages(
                           makeMessages(["Ops!, erro ao atualizar motorista."], "error")
                        )
                     );
                  }
               }));

            } catch (error) {
               console.log(error);
               dispatch(
                  messageActions.messageShowMessages(
                     makeMessages(["Erro ao atualizar o motorista."], "error")
                  )
               );
            }

         }



         // if (current.oid) {
         //    //atualizar o usuário e o record

         // } else {

         //    // se não encontrou o usuário executa o procedimento para salvar o motorista
         //    // e criar um usuário com os dados do motorista  
         //    if (!userFound) {

         //       // devo criar o usuário com base nos dados do motorista
         //       // e cadastrar na base de dados

         //    } else {
         //       this.props.sendMessageError(["Já existe um motorista com o mesmo documento."], "error");
         //    }
         // }
      }
   }
}

const getUserFromDriver = (documentNumber) => {
   return new Promise((resolve, reject) => {
      const filter = {
         "documentNumber": documentNumber
      }
      api.post("/api/v1/lognex/users/find-by-documentnumber", filter)
         .then((response) => {
            resolve(response.data);
         })
         .catch((err) => {
            reject(err);
         });
   });
}

export { types, actions }