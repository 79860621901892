import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';


// Componentes padrão da lib
import { Card } from 'primereact/card';
import { Dialog } from 'primereact/dialog';
import { Column } from 'primereact/column';

// componentes customizados
import Toolbar from "../../../components/toolbar/Toolbar";
import { Page } from "../../../components/page/Page";
import { routes } from '../../../constants';
import { DriverList } from "./DriverList";

// ações e serviços
import storage from '../../../services/stoage.service';
import { actions } from '../driver.actions';
import { AppService, COMPANY_CHANGE_EVENT_NAME, BUSINESS_UNIT_CHANGE_EVENT_NAME } from "../../../services/app.service";



const mapStateToProps = state => {
  const { crudDriverState } = state;
  let carrier;
  const user = storage.getUser();
  if (user.carrier) {
    carrier = user.carrier
  }

  return {
    columns: crudDriverState.columns,
    records: crudDriverState.records,
    first: crudDriverState.index,
    rows: crudDriverState.max,
    totalRecords: crudDriverState.count,
    sortField: crudDriverState.sortField,
    order: crudDriverState.sortOrder,
    carrier
  }
}
const mapDispatchToProps = dispatch => {
  return {
    paginate: paginationParams => dispatch(actions.paginate(paginationParams)),
    sortOrder: sortParams => dispatch(actions.sortOrder(sortParams)),
    applyFilter: filterParams => {
      dispatch(actions.setType(null));
      dispatch(actions.applyFilter(filterParams))
    },
    load: () => dispatch(actions.load()),
    hideDialog: () => dispatch(actions.setLogImportXLSXResult(null)),
  }
}


class DriverListPageContainer extends React.Component {

  newHandler = () => {
    this.props.history.push(`${routes.PATH_DRIVER}/novo`)
  }


  componentDidMount() {
    this.props.load();
    AppService.on(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.on(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
  }
  componentWillUnmount() {
    AppService.off(COMPANY_CHANGE_EVENT_NAME, this);
    AppService.off(BUSINESS_UNIT_CHANGE_EVENT_NAME, this);
  }

  onReceiveAppNotifications = (eventName, params) => {
    if (eventName === COMPANY_CHANGE_EVENT_NAME || eventName === BUSINESS_UNIT_CHANGE_EVENT_NAME) {
      this.props.load();
    }
  }


  onPage = (e) => {
    this.props.paginate({
      max: e.rows,
      index: e.first
    })
  }
  onSort = (e) => {
    this.props.sortOrder({
      sortOrder: e.sortOrder,
      sortField: e.sortField
    })


  }
  onFilter = (e) => {
    const filters = [];
    const keys = Object.keys(e.filters) || [];
    keys.forEach(key => {
      if (key == "oid") {
        filters.push({
          campo: key,
          valor: parseInt(e.filters[key].value),
          tipoFiltro: 'IGUAL_A'
        })
      } else {
        filters.push({
          campo: key,
          valor: e.filters[key].value,
          tipoFiltro: 'CONTEM'

        })
      }
    })
    this.props.applyFilter(filters)

  }

  onSearchHandler = () => {
    this.props.history.push(`${routes.PATH_CARRIER_PORTAL}`);
  }

  errorsDialogOnHide = () => {
    this.props.hideDialog()
  }

  render() {

    const header = (
      <div>
        <h1>Motoristas</h1>
        <div>
          <Toolbar onNew={this.newHandler} newButtonStyle='highlighted'
            onSearch={this.props.carrier ? this.onSearchHandler : null}
          >


          </Toolbar >
        </div>

      </div>
    );

    const formatCPF = (cpf) => {
      if (!cpf || cpf.lenth < 11) {
        return cpf;
      }
      const mask = "###.###.###-##"
      let index = 0;
      const data = mask.split('').map(it => {
        if (it === '#') {
          return cpf[index++]
        } else {
          return it
        }
      })
      let formated = data.join('');
      return formated
    }

    const cpfTemplate = (rowData, column) => {
      return (
        <span style={{ display: "inline-block", width: "100%", textAlign: "center" }}>{formatCPF(rowData.documentNumber)}</span>
      )
    }



    const oidTemplate = (rowData, column) => {
      return (
        <div className="id-link"><Link to={`${routes.PATH_DRIVER}/${rowData.oid}`}>{rowData.oid}</Link></div>
      )
    }

    const columns = this.props.columns.map(col => {

      if (col.field === 'documentNumber') {
        return (<Column {...col} key={col.field} body={cpfTemplate} />);
      }

      if (col.field === 'oid') {
        return (<Column {...col} key={col.field} body={oidTemplate} />);
      }

      return (<Column {...col} key={col.field} />)
    })

    const content = (
      <div>

        <DriverList  {...this.props} onFilter={this.onFilter} onPage={this.onPage} onSort={this.onSort} columns={columns} />
      </div>);
    return <Page header={header} content={content} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DriverListPageContainer);
