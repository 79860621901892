import React from "react";
import { connect } from "react-redux";

// componentes customizados
import Toolbar from "../../../components/toolbar/Toolbar";
import { Page } from "../../../components/page/Page";
import { routes } from '../../../constants';
import { DriverForm } from './DriverForm';


// ações e serviços
import storage from '../../../services/stoage.service';
import { actions } from "../driver.actions";
import { makeMessages, messageActions } from "../../../store/message";
import moment from "moment";



const mapStateToProps = state => {
  let record = state.crudDriverState.currentRecord;

  const user = storage.getUser();
  if (record && user.carrier && user.carrier.oid && user.carrier.oid !== "") {
    record.carrier = user.carrier
  }

  return {
    record,
  };
};

const mapDispatchToProps = dispatch => {
  const _setField = (field, value) => {
    dispatch(actions.setField(field, value));
  }

  return {

    changeHandler: event => {
      const target = event.target;
      const field = target.name;
      let value = target.value;

      if (typeof value === 'string' && field != 'password') {
        value = value.toUpperCase();
      }

      dispatch(actions.removeErrors([{ field }]));
      _setField(field, value);
    },
    saveHandler: () => {
      dispatch(actions.saveDriver());
    },
    removeHandler: event => {
      dispatch(actions.remove());
    },
    newHandler: event => {
      dispatch(actions.setRecord({}))
    },
    loadById: oid => {
      dispatch(actions.setRecord(null))
      dispatch(actions.loadById(oid))
    },

    setField: (field, value) => {
      _setField(field, value)
    },

    sendMessageError: (message, messageType) => dispatch(
      messageActions.messageShowMessages(
        makeMessages([message], messageType)
      ),
    ),

    changeValidateCNHHandler: value => dispatch(actions.setValidateCNH(value)),

  }
};

class DriverFormPageContainer extends React.Component {

  constructor(props) {
    super(props);
    actions.afterSave = ({ oid }) => {
      this.props.history.replace(`${routes.PATH_DRIVER}/${oid}`);
    };
    actions.afterNew = () => {
      this.props.newHandler();
      this.props.history.replace(`${routes.PATH_DRIVER}/novo`);
    };
    actions.afterRemove = () => {
      this.props.history.replace(`${routes.PATH_DRIVER}`);
    };
  }

  componentDidMount() {
    const oid = this.props.match.params.id;

    if (oid && parseInt(oid)) {
      this.props.loadById(oid);
    } else {
      this.props.newHandler();
      // this.props.history.replace(`${routes.PATH_DRIVER}/novo`);
    }
  }
  searchHandler = () => {
    this.props.history.push(`${routes.PATH_DRIVER}`)
  }

  onSaveHandler = () => {
    if (this.props.record.carrier && this.props.record.carrier.tradeName !== "" && this.props.record.carrier.documentNumber !== "") {
      this.props.saveHandler();
    } else {
      this.props.sendMessageError(["Não está selecionado a transportadora."], "error");
    }
  }

  onChangeValidateCNHHandler = (e) => {
    const value = moment(e.value, 'DD/MM/YYYY').toDate();
    this.props.changeValidateCNHHandler(value)
  }


  changeHandler = (e) => {
    console.log("changeHandler:", e)
    this.props.changeHandler(e);
  }

  render() {
    const title = `Motorista ${this.props.record.oid || 'novo'}`
    const header = (
      <div>
        <h1>{title}</h1>
        <div>
          <Toolbar
            onSave={this.onSaveHandler} saveButtonStyle='highlighted'
            onRemove={this.props.removeHandler} removeButtonStyle='danger'
            onNew={this.props.newHandler}
            onSearch={this.searchHandler}>
          </Toolbar>
        </div>
      </div>
    );

    const content = (
      <DriverForm
        record={this.props.record}
        changeHandler={this.changeHandler}
        changeValidateCNHHandler={this.onChangeValidateCNHHandler}
      />);
    return (<Page header={header} content={content} />);
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DriverFormPageContainer);
