import { validate } from "./validations";
import { build } from "../../services/crudFactory";
import { Action } from "../../services/actions.factory";
import { messageActions } from "../../store/message/index";
import { actions as appActions } from '../../store/app' 
import { actions as actionFreightTable } from "../freightTable/freightTable.actions";


import api from "../../services/api.service";
const BASE_PATH = "/lognex/accountconfigurations";
const prefix = "crudAccountConfiguration";
const crud = build(validate, BASE_PATH);

const makeMessages = (messages, type) => {
  return messages.map((message) => {
    return { sticky: true, severity: type, detail: message };
  });
};

const newTypes = {
  SET_FAVORITE: `${prefix}setFavorite`,
  SET_LAST_ACCESSED: `${prefix}setLastAccessed`,
  LOAD_ACCOUNT_CONFIGURATIONS_BY_COMPANY: `${prefix}loadAccountConfigurationsByCompany`,
  SAVE_ACCOUNT_CONFIGURATIONS: `${prefix}saveAccountConfigurations`,
  SET_CHANGED: `${prefix}setChanged`,
  SET_SELECTED_BUSINESS_UNIT_RECORD: `${prefix}setSelectedBusinessUnitRecord`,
  SET_LOOKUP_BUSINESS_UNIT_VISIBLE: `${prefix}setLookupBusinessUnitSetVisible`,
  SET_LOOKUP_UNIT_SELECTED: `${prefix}setLookupUnitSelected`,
  ADD_NEW_BUSINESS_UNIT: `${prefix}addNewBusinessUnit`,
  REMOVE_BUSINESS_UNIT_SELECTED: `${prefix}onRemoveBusinessUnitSelected`,
  SET_CHANGE_ROW_WAREHOUSE_EMAIL: `${prefix}setSelectedWarehouseEmail`,
  INPUT_CHANGE_WAREHOUSE_EMAIL: `${prefix}inputChangeWarehouseEmail`,
  ADD_NEW_WAREHOUSE_EMAIL: `${prefix}addNewEmailWareHouse`,
  REMOVE_SELECTED_WAREHOUSE_EMAIL: `${prefix}onRemoveEmailWareHouseSelected`,
  CHANGE_CARRIER_RECORD_SELECTED: `${prefix}onChangeCarrierRecordSelect`,
  REMOVE_CARRIER_RECORD_SELECTED: `${prefix}onRemoveCarrierSelected`,
  SET_LOOKUP_CARRIER_VISIBLE: `${prefix}setLookupCarrierSetVisible`,
  SET_CARRIER_SELECTED_LOOKUP: `${prefix}setLookupCarrierSelected`,
  INPUT_CHANGE_LOOKUP_CARRIR: `${prefix}setLookupCarrierInputChange`,
  ADD_NEW_CARRIER_SELECTED: `${prefix}addNewCarrierSelected`,
  INPUT_CHANGE_CARRIER_EMAIL: `${prefix}inputEmailCarrierChangeHandler`,
  ADD_NEW_CARRIER_EMAIL: `${prefix}addNewEmailCarrier`,
  REMOVE_SELECTED_CARRIER_EMAIL: `${prefix}onRemoveEmailcarrierSelected`,
  SET_CHANGE_ROW_CARRIER_EMAIL: `${prefix}onSelectedEmailCarrierRecordChange`,
  ADD_NEW_CARRIER_EQUIP_TYPE_SELECTED: `${prefix}addNewCarrierEquipTypeSelected`,
  SET_CARRIER_EQUIP_TYPE_SELECTED_LOOKUP: `${prefix}setLookupCarrierEquipTypeSelected`,
  SET_LOOKUP_CARRIER_EQUIP_TYPE_VISIBLE: `${prefix}setLookupCarrierEquipTypeSetVisible`,
  CHANGE_CARRIER_EQUIP_TYPE_RECORD_SELECTED: `${prefix}onChangeCarrierEquipTypeRecordSelect`,
  SET_LOOKUP_EQUIPMENT_VISIBLE: `${prefix}setLookupEquipmentVisible`,
  SET_EQUIPMENT_TYPE: `${prefix}setEquipmentType`,
  ADD_EQUIPMENT_TYPE: `${prefix}addNewEquipType`,
  REMOVE_CARRIER_EQUIP_TYPE_RECORD_SELECTED: `${prefix}onRemoveCarrierEquipTypeRecord`,
  SET_DIALOG_CUSTOM_FIELDS_EQUIP_TYPE_VISIBLE: `${prefix}setVisibliDialogCustomFieldsEquipType`,
  SET_EQUIP_TYPE_SELECTED: `${prefix}setEquipTypeSelected`,
  SET_EQUIP_TYPE_CUSTOM_FIELDS: `${prefix}onChangeEquipTypeCustomFields`,
  SET_SELECTED_EQUIP_TYPE_RECORD: `${prefix}setSelectedEquipTypeRecord`,
  REMOVE_SELECTED_EQUIP_TYPE_RECORD: `${prefix}onRemoveSelectedEquipTypeRecord`,
  SET_OPERATION_TIME_DOCK: `${prefix}setOperationTimeDock`,
  SET_TIME_BETWEEN_OPERATION_DOCK: `${prefix}setTimeNextOperationDock`,
  SET_SELECTED_OPERATION_TYPE_DOCK: `${prefix}setSelectedOperationType`,
  SET_SELECTED_PACKAGING_TYPE_DOCK: `${prefix}setSelectedPackagingTypeDock`,
  SET_SELECTED_VEHICLE_TYPE_DOCK: `${prefix}setSelectedVehicleTypeDock`,
  ADD_SELECTED_CONFIG_DOCK: `${prefix}addBtnAddConfigDock`,
  SET_SELECTED_CONFIG_DOCK: `${prefix}setConfigDockRecordSelect`,
  REMOVE_SELECTED_CONFIG_DOCK_RECORD: `${prefix}onRemoveSelectedConfigDockRecord`,
  SET_DOCA_VEHICLE_OPERATION: `${prefix}setDocaVehicleOperation`,
  SET_WORK_DAY_RANGE_TIME: `${prefix}setWorkDayTimeRangeTime`,
  SET_SELECTED_RANGE_TIME_RECORDS: `${prefix}setSelectedRangeTimeRecord`,
  REMOVE_SELECTED_RANGE_TIME_RECORDS: `${prefix}removeSelectedRangeTimeRecord`,
  SET_ENABLE_WORK_DAY_UNIT: `${prefix}onChangeWorkDay`,
  SET_DAY_TO_SCHEDULE: `${prefix}changeDayStartSchedule`,
  SET_TIME_NEXT_OPERATION_SCHEDULE: `${prefix}changeTimeNextOperation`,
  SET_NEW_FREIGHT_TAG: `${prefix}addNewFreightTag`,
  SET_SELECTED_TAG_RECORD: `${prefix}setSelectedTagRedord`,
  ON_REMOVE_SELECTED_TAG_RECORD: `${prefix}onRemoveSelectedTagRecord`,
  SET_SELECTED_HOLIDAY_RECORDS: `${prefix}setSelectedHolidayRecord`,
  REMOVE_SELECTED_HOLIDAY_RECORDS: `${prefix}removeSelectedHolidayRecord`,
  SET_HOLIDAY: `${prefix}setDateHoliday`,
  ON_IMPORT_CSV_HOLIDAY: `${prefix}onClickImportHolidayXLSX`,
  SET_HOLIDAY_RECORS_XLS: `${prefix}setHolidayRecordsXls`,
  SET_ENABLE_USE_DOCK: `${prefix}setDocaWorkDayTimeUseDocks`,
  SET_RANGE_DAY_TO_SCHEDULE: `${prefix}changeRangeDaysSchedule`,
  SET_LABEL_CARRIER:  `${prefix}setLabelCarrier`,  
  SET_CNPJ_CARRIER:  `${prefix}setCnpjCarrier`,  
  SET_LOOKUP_FREIGHTTABLE_VISIBLE: `${prefix}setLookupFreightTableVisible`,
  SET_LOOKUP_FREIGHTTABLE_SELECTED:  `${prefix}setLookupFreightTableSelected`,  
  CONFIRM_FREIGHTTABLE_DEFAULT_SELECTED:  `${prefix}onConfirmFreightTable`, 
  REMOVER_FREIGHTTABLE_DEFAULT_SELECTED:  `${prefix}onRemoveDefaultFreightTable`, 
  LOAD_FREIGHT_TABLE_BY_ID:  `${prefix}loadFreightTableById`, 
  SET_DEFAULT_FREIGHTTABLE_DEFAULT:  `${prefix}setDefaultFreightTableRecord`, 
  SET_SELECTED_VEHICLE_TYPE:  `${prefix}setSelectedVehicleType`, 
  ADD_SELECTED_VEHICLE_TYPE_FREIGHT_TABLE:  `${prefix}addBtnAddVehicleTypeSelected`, 
  REMOVE_VEHICLE_TYPE_FREIGHT_TABLE:  `${prefix}onRemoveVehicleDefaultFreightTable`, 
};


const actions = new Action(prefix, crud);

const types = Object.assign({}, actions.types, newTypes);
 
 
actions.setDefaultFreightTableRecord = (freightTable) => {
  return {
    type: types.SET_DEFAULT_FREIGHTTABLE_DEFAULT,freightTable
  };
}

actions.onRemoveDefaultFreightTable = (oid) => {
  return {
    type: types.REMOVER_FREIGHTTABLE_DEFAULT_SELECTED,
    oid
  };
}
actions.onConfirmFreightTable = () => {
  return {
    type: types.CONFIRM_FREIGHTTABLE_DEFAULT_SELECTED
  };
}

actions.setLookupFreightTableSelected = (freightTable) => {
  return {
    type: types.SET_LOOKUP_FREIGHTTABLE_SELECTED,freightTable
  };
}
actions.setLookupFreightTableVisible = (visible) => {
  return {
    type: types.SET_LOOKUP_FREIGHTTABLE_VISIBLE,visible
  };
}

actions.setLabelCarrier = (label) => {
  return {
    type: types.SET_LABEL_CARRIER,label
  };
}
actions.setCnpjCarrier = (cnpj) => {
  return {
    type: types.SET_CNPJ_CARRIER,cnpj
  };
}

actions.onRemoveSelectedTagRecord = () => {
  return {
    type: types.ON_REMOVE_SELECTED_TAG_RECORD
  };
}

actions.setSelectedTagRedord = (selectedTagRecord) => {
  return {
    type: types.SET_SELECTED_TAG_RECORD,
    selectedTagRecord
  };
}

actions.addNewFreightTag = (tag) => {
  return {
    type: types.SET_NEW_FREIGHT_TAG,
    tag
  }
}

actions.setDocaWorkDayTimeUseDocks = (useDocks) => {
  return {
    type: types.SET_ENABLE_USE_DOCK, useDocks
  };
}

actions.setHolidayRecordsXls = (records) => {
  return {
    type: types.SET_HOLIDAY_RECORS_XLS, records
  };
}
actions.onClickImportHolidayXLSX = (xlsx) => {
  return (dispatch, getState) => {
    const URL = '/api/v1/lognex/accountconfigurations/importHolidaylsx';
    api.uploadFiles(URL, xlsx, {}).then(response => {

      const { records = [], erros = [] } = response.data

      if (records.length === 0 || erros.length > 0) {
        let messages = ["A planilha importada não possui registros válidos."];
        if (erros.length > 0) {
          messages = erros
        }
        dispatch(messageActions.messageShowMessages(makeMessages(messages, "error")));
      } else {
        dispatch(actions.setHolidayRecordsXls(records))
      }
    })
      .catch((err) => {
        console.log(err);
        const messages = ["Não foi possível processar a planilha."];
        dispatch(messageActions.messageShowMessages(makeMessages(messages, "error")));
      })
  }
}

actions.setDateHoliday = (holiday) => {
  return {
    type: types.SET_HOLIDAY, holiday
  };
}

actions.removeSelectedHolidayRecord = (value) => {
  return {
    type: types.REMOVE_SELECTED_HOLIDAY_RECORDS
  };
}
actions.setSelectedHolidayRecord = (value) => {
  return {
    type: types.SET_SELECTED_HOLIDAY_RECORDS,
    value
  };
}

actions.changeTimeNextOperation = (value) => {
  return {
    type: types.SET_TIME_NEXT_OPERATION_SCHEDULE,
    value
  };
}
actions.changeDayStartSchedule = (value) => {
  return {
    type: types.SET_DAY_TO_SCHEDULE,
    value
  };
}
actions.changeRangeDaysSchedule = (value) => {
  return {
    type: types.SET_RANGE_DAY_TO_SCHEDULE,
    value
  };
}

actions.onChangeWorkDay = (fieldValue) => {
  return {
    type: types.SET_ENABLE_WORK_DAY_UNIT,
    fieldValue
  };
}

actions.removeSelectedRangeTimeRecord = () => {
  return {
    type: types.REMOVE_SELECTED_RANGE_TIME_RECORDS
  };
}
actions.setSelectedRangeTimeRecord = (selectedRangeTimeRecord) => {
  return {
    type: types.SET_SELECTED_RANGE_TIME_RECORDS,
    selectedRangeTimeRecord
  };
}

actions.setWorkDayTimeRangeTime = (timeRange) => {
  return {
    type: types.SET_WORK_DAY_RANGE_TIME,
    timeRange
  };
}

actions.setDocaVehicleOperation = (docaVehicleOperation) => {
  return {
    type: types.SET_DOCA_VEHICLE_OPERATION,
    docaVehicleOperation
  };
}
actions.onRemoveSelectedConfigDockRecord = () => {
  return {
    type: types.REMOVE_SELECTED_CONFIG_DOCK_RECORD
  };
}
actions.setConfigDockRecordSelect = (selectedConfigDockRecord) => {
  return {
    type: types.SET_SELECTED_CONFIG_DOCK,
    selectedConfigDockRecord
  };
}
actions.addBtnAddConfigDock = () => {
  return {
    type: types.ADD_SELECTED_CONFIG_DOCK
  };
}
actions.setSelectedVehicleTypeDock = (selectedVehicleTypeDock) => {
  return {
    type: types.SET_SELECTED_VEHICLE_TYPE_DOCK,
    selectedVehicleTypeDock,
  };
}

actions.setSelectedPackagingTypeDock = (selectedPackagingTypeDock) => {
  return {
    type: types.SET_SELECTED_PACKAGING_TYPE_DOCK,
    selectedPackagingTypeDock,
  };
}

actions.setSelectedOperationType = (operationTypeDock) => {
  return {
    type: types.SET_SELECTED_OPERATION_TYPE_DOCK,
    operationTypeDock,
  };
}
actions.setOperationTimeDock = (operationTimeDock) => {
  return {
    type: types.SET_OPERATION_TIME_DOCK,
    operationTimeDock,
  };
}

actions.setTimeNextOperationDock = (timeNextOperationDock) => {
  return {
    type: types.SET_TIME_BETWEEN_OPERATION_DOCK,
    timeNextOperationDock,
  };
}

actions.setChanged = (value) => {
  return {
    type: types.SET_CHANGED,
    value,
  };
}

actions.setFavorite = (value) => {
  return {
    type: types.SET_FAVORITE,
    value,
  };
};

actions.setLastAccessed = (value) => {
  return {
    type: types.SET_LAST_ACCESSED,
    value,
  };
};
actions.setConfigurations = (configurations) => {
  return {
    type: types.LOAD_ACCOUNT_CONFIGURATIONS_BY_COMPANY,
    configurations,
  };
};


actions.loadAccountConfigurationsByCompany = () => {
  return (dispatch) => {
    api
      .post("/api/v1/lognex/accountconfigurations", null)
      .then(({ data }) => {
        dispatch(actions.setConfigurations(data.records[0].configurations));
      })
      .catch((err) => {
        if (err) {
          console.log(err);
        }
      });
  };
};

actions.saveAccountConfigurations = (data) => {
  let bodyAccountConfigurations = {
    record: {
      auditConfiguration: data.record.auditConfigurations,
      freightCalculationConfiguration: data.record.freightCalcConfigurations,
      integrationConfigurations: data.record.integrationConfigurations,
      businessUnitConfigurations: data.record.businessUnitConfigurations,
      orderManagementConfigurations: data.record.orderManagementConfigurations,
      deliveryDeadLineConfigurations: data.record.deliveryDeadLineConfigurations,
      docaVehicleOperation: data.record.docaVehicleOperation,
      customColumns: data.record.customColumns,
    },
    params: { reprocess: data.reprocess },
  };

  return (dispatch) => {

    const requests = [
      api.post(
        "/api/v1/lognex/accountconfigurations/saveconfiguration",
        bodyAccountConfigurations
      ),
    ]
    if (data.user) {
      const bodyUserConfigurations = {
        userId: data.user.oid,
        indicators: data.record.indicatorsConfigurations,
        favorites: data.record.favoriteConfigurations,
      };
      requests.push(
        api.post(
          "/api/v1/lognex/userconfigurations/updateconfigurations",
          bodyUserConfigurations
        )
      )
    }
    Promise.all(requests)
      .then((results) => {
        const messages = ["Registro atualizado com sucesso"];
        dispatch(actions.setConfigurations(results[0].data.configurations));
        dispatch(appActions.setFavorites(data.record.favoriteConfigurations))
        dispatch(appActions.setIndicators(data.record.indicatorsConfigurations))
        dispatch(
          messageActions.messageShowMessages(makeMessages(messages, "success"))
        );
      })
      .catch((err) => {
        if (err) {
          console.log(err);
          const messages = ["Não foi possível atualizar o registro"];
          dispatch(
            messageActions.messageShowMessages(makeMessages(messages, "error"))
          );
        }
      });
  };
};

actions.setSelectedBusinessUnitRecord = (selectedBusinessUnitRecord) => {
  return {
    type: types.SET_SELECTED_BUSINESS_UNIT_RECORD,
    selectedBusinessUnitRecord,
  };
};

actions.setLookupBusinessUnitSetVisible = (visible) => {
  return {
    type: types.SET_LOOKUP_BUSINESS_UNIT_VISIBLE,
    visible,
  };
};

actions.setLookupUnitSelected = (lookupUnitSelected) => {
  return {
    type: types.SET_LOOKUP_UNIT_SELECTED,
    lookupUnitSelected,
  };
};

actions.addNewBusinessUnit = () => {
  return {
    type: types.ADD_NEW_BUSINESS_UNIT
  };
};

actions.onRemoveBusinessUnitSelected = () => {
  return {
    type: types.REMOVE_BUSINESS_UNIT_SELECTED
  };
};

actions.setSelectedWarehouseEmail = (email) => {
  return {
    type: types.SET_CHANGE_ROW_WAREHOUSE_EMAIL,
    email,
  };
}


actions.inputChangeWarehouseEmail = (email) => {
  return {
    type: types.INPUT_CHANGE_WAREHOUSE_EMAIL,
    email,
  };
}

actions.addNewEmailWareHouse = () => {
  return {
    type: types.ADD_NEW_WAREHOUSE_EMAIL,
  };
};


actions.onRemoveEmailWareHouseSelected = () => {
  return {
    type: types.REMOVE_SELECTED_WAREHOUSE_EMAIL,
  };
};


actions.onChangeCarrierRecordSelect = (selectedCarrierRecord) => {
  return {
    type: types.CHANGE_CARRIER_RECORD_SELECTED,
    selectedCarrierRecord
  };
};

actions.onRemoveCarrierSelected = () => {
  return {
    type: types.REMOVE_CARRIER_RECORD_SELECTED,
  };
};

actions.setLookupCarrierSetVisible = (visible) => {
  return {
    type: types.SET_LOOKUP_CARRIER_VISIBLE,
    visible,
  };
};

actions.setLookupCarrierSelected = (carrierSelected) => {
  return { type: types.SET_CARRIER_SELECTED_LOOKUP, carrierSelected };
};

actions.setLookupCarrierInputChange = (personalName) => {
  return {
    type: types.INPUT_CHANGE_LOOKUP_CARRIR,
    personalName,
  };
};

actions.addNewCarrierSelected = () => {
  return {
    type: types.ADD_NEW_CARRIER_SELECTED,
  };
}

actions.inputEmailCarrierChangeHandler = (email) => {
  return {
    type: types.INPUT_CHANGE_CARRIER_EMAIL, email
  };
}

actions.addNewEmailCarrier = () => {
  return {
    type: types.ADD_NEW_CARRIER_EMAIL,
  };
};

actions.onRemoveEmailcarrierSelected = () => {
  return {
    type: types.REMOVE_SELECTED_CARRIER_EMAIL,
  };
}
actions.onSelectedEmailCarrierRecordChange = (selectedCarrierEmail) => {
  return {
    type: types.SET_CHANGE_ROW_CARRIER_EMAIL, selectedCarrierEmail
  };
}


actions.addNewCarrierEquipTypeSelected = () => {
  return {
    type: types.ADD_NEW_CARRIER_EQUIP_TYPE_SELECTED,
  };
}

actions.setLookupCarrierEquipTypeSelected = (carrierEquipTypeSelected) => {
  return {
    type: types.SET_CARRIER_EQUIP_TYPE_SELECTED_LOOKUP, carrierEquipTypeSelected
  };
}
actions.setLookupCarrierEquipTypeSetVisible = (visible) => {
  return {
    type: types.SET_LOOKUP_CARRIER_EQUIP_TYPE_VISIBLE,
    visible,
  };
};

actions.onChangeCarrierEquipTypeRecordSelect = (selectedCarrierEquipTypeRecord) => {
  return {
    type: types.CHANGE_CARRIER_EQUIP_TYPE_RECORD_SELECTED,
    selectedCarrierEquipTypeRecord
  };
};

actions.setLookupEquipmentVisible = (visible) => {
  return {
    type: types.SET_LOOKUP_EQUIPMENT_VISIBLE,
    visible,
  };
};

actions.setEquipmentType = (equipmentType) => {
  return {
    type: types.SET_EQUIPMENT_TYPE,
    equipmentType,
  };
};

actions.addNewEquipType = () => {
  return {
    type: types.ADD_EQUIPMENT_TYPE
  };
};

actions.onRemoveCarrierEquipTypeRecord = () => {
  return {
    type: types.REMOVE_CARRIER_EQUIP_TYPE_RECORD_SELECTED
  };
};


actions.setVisibliDialogCustomFieldsEquipType = (visible) => {
  return {
    type: types.SET_DIALOG_CUSTOM_FIELDS_EQUIP_TYPE_VISIBLE,
    visible,
  };
}

actions.setEquipTypeSelected = (equipTypeSelected) => {
  return {
    type: types.SET_EQUIP_TYPE_SELECTED,
    equipTypeSelected,
  };
}

actions.onChangeEquipTypeCustomFields = (equipTypeSelected) => {
  return {
    type: types.SET_EQUIP_TYPE_CUSTOM_FIELDS,
    equipTypeSelected,
  };
}


actions.setSelectedEquipTypeRecord = (selectedEquipTypeRecord) => {
  return {
    type: types.SET_SELECTED_EQUIP_TYPE_RECORD,
    selectedEquipTypeRecord,
  };
}

actions.onRemoveSelectedEquipTypeRecord = () => {
  return {
    type: types.REMOVE_SELECTED_EQUIP_TYPE_RECORD
  };
}

actions.loadFreightTableById = () => {
  return (dispatch, getState) => {
    const selectedBusinessUnitRecord = getState().crudAccountConfigurationState.selectedBusinessUnitRecord; 
    if(selectedBusinessUnitRecord && selectedBusinessUnitRecord.freightTableDefaultRoute && selectedBusinessUnitRecord.freightTableDefaultRoute.oid){
      let oids =[];
      if(Array.isArray(selectedBusinessUnitRecord.freightTableDefaultRoute.oid)){
        oids = selectedBusinessUnitRecord.freightTableDefaultRoute.oid;
      }else{
        oids = [selectedBusinessUnitRecord.freightTableDefaultRoute.oid];
      }

      const filters = [        
        {
          campo: "_id",
          valor: oids,
          tipoFiltro: "ESTA_CONTIDO_EM",
        },
      ];
      const params = {
        filters,
        index: 0,
        max: 10,
        nefields:["generalFreightComponents"]
    } 
    

      api.post(`/api/v1/lognex/freighttables/all`,params)
      .then((result) => { 
        if(result.data && result.data.records){ 
          console.log("params=",params)
          dispatch(actions.setDefaultFreightTableRecord(result.data.records));
        }else{
          dispatch(actions.setDefaultFreightTableRecord(null));
        }
      }).catch((err) => {
        dispatch(actions.setDefaultFreightTableRecord(null));
        if (err) {
          console.log(err);
        }
      });
    }else{
      dispatch(actions.setDefaultFreightTableRecord(null));
    }
  }
}

actions.setSelectedVehicleType = (vehicleType) => {
  return {
    type: types.SET_SELECTED_VEHICLE_TYPE,
    vehicleType,
  };
}

actions.addBtnAddVehicleTypeSelected = () => {
  return {
    type: types.ADD_SELECTED_VEHICLE_TYPE_FREIGHT_TABLE,
  };
}

actions.onRemoveVehicleDefaultFreightTable = (oid) => {
  return {
    type: types.REMOVE_VEHICLE_TYPE_FREIGHT_TABLE,oid
  };
}


export { types, actions };
