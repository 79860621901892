import React from "react";

import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";

import { LookupField } from "../../../components/lookup/LookupField";
import { formatNumber } from "../../../utils";

export const CollectionOrderPortalForm = (props) => {
  const fieldDisabled =
    props.record.status && props.record.status === "REJECTED";


  return (
    <div className="collectionOrder-form">
      <div className="p-grid">

        <div className="p-col-12">
          <div className="p-grid">
            <div className="p-col-12 form-field-container">
              <h2>Dados Gerais</h2>
            </div>
            <div className="p-col-4 form-field-container">
              <label>Cliente</label>
              <InputText
                name="sender.name"
                value={props.record.sender.name ?? ""}
                readOnly={true}
              />
            </div>
            <div className="p-col-8 form-field-container"></div>
            <div className="p-col-3 form-field-container">
              <label>Tipo de veículo</label>
              <InputText
                name="vehicle.vehicleType"
                value={props.record.vehicle.vehicleType ?? ""}
                readOnly={true}
              />
            </div>
            <div className="p-col-3 form-field-container">
              <label>Data de Coleta</label>
              <InputText
                name="vehicle.pickupDate"
                value={props.record.pickupDate ?? ""}
                readOnly={true}
              />
            </div>
            <div className="p-col-3 form-field-container">
              <label>Origem</label>
              <InputText
                name="origin"
                value={props.record.origin ?? ""}
                readOnly={true}
              />
            </div>
            <div className="p-col-3 form-field-container">
              <label>Destino</label>
              <InputText
                name="destination"
                value={props.record.destination ?? ""}
                readOnly={true}
              />
            </div>

            <div className="p-col-12 form-field-container">
              <h2>Endereço de coleta</h2>
            </div>

            <div className="p-col-8 form-field-container">
              <label>Logradouro</label>
              <InputText
                name="collectionAddress.street"
                value={props.record.collectionAddress.street ?? ""}
                readOnly={true}
              />
            </div>
            <div className="p-col-4 form-field-container">
              <label>Número</label>
              <InputText
                name="collectionAddress.number"
                value={props.record.collectionAddress.number ?? ""}
                readOnly={true}
              />
            </div>
            <div className="p-col-12 form-field-container">
              <label>Complemento</label>
              <InputText
                name="collectionAddress.complement"
                value={props.record.collectionAddress.complement ?? ""}
                readOnly={true}
              />
            </div>
            <div className="p-col-3 form-field-container">
              <label>Bairro</label>
              <InputText
                name="collectionAddress.district"
                value={props.record.collectionAddress.district ?? ""}
                readOnly={true}
              />
            </div>
            <div className="p-col-3 form-field-container">
              <label>Cidade</label>
              <InputText
                name="collectionAddress.city"
                value={props.record.collectionAddress.city ?? ""}
                readOnly={true}
              />
            </div>
            <div className="p-col-3 form-field-container">
              <label>Cep</label>
              <InputText
                name="collectionAddress.zipcode"
                value={props.record.collectionAddress.zipcode ?? ""}
                readOnly={true}
              />
            </div>
            <div className="p-col-3 form-field-container">
              <label>Estado</label>
              <InputText
                name="collectionAddress.state"
                value={props.record.collectionAddress.state ?? ""}
                readOnly={true}
              />
            </div>
            {props.docasHourSchedules &&
              <div className="p-col-12 form-field-container">
                <h2>Agendamento de Docas</h2>
              </div>
            }

            {props.docasHourSchedules &&
              props.docasHourSchedules.map((doc, ind) => {

                let dockSchedule;
                if (props.dockScheduling) {
                  //selecionado
                  props.dockScheduling.forEach(dock => {
                    if (doc.sigla == dock.sigla && dock.documentNumber == doc.documentNumber) {
                      dockSchedule = dock;
                    }
                  });
                }
                // console.log(" props.dockScheduling=",props.dockScheduling)

                const isDisabledDoca = dockSchedule && dockSchedule.dockScheduleId ? true : false;
                const labelDoca = doc.label ?? doc.sigla;

                return <div className="p-col-4 form-field-container" key={ind}>

                  <label style={{ fontWeight: "bold" }}>{labelDoca}</label>
                  {/* <div style={{ width: "345px" }}> */}
                  <Dropdown style={{ width: "100%" }}
                    placeholder="Selecione"
                    disabled={isDisabledDoca}
                    value={dockSchedule ? dockSchedule.rangeTime : null}
                    key={Math.random() + "-" + ind}
                    dataKey={"labelView"}
                    optionLabel="labelView"
                    options={doc.rangeTime}
                    onChange={props.onDropdownScheduleDocaHourChange}
                    name={"dockScheduling"}
                  />
                </div>

                // </div>
              })
            }

            <div className="p-col-12 form-field-container">
              <h2>Informação da carga</h2>
            </div>
            <div className="p-col-3 form-field-container">
              <label>Peso</label>
              <InputText
                name="cargo.weight"
                value={formatNumber(props.record.cargo.weight, 3)}
                readOnly={true}
              />
            </div>

            <div className="p-col-12 form-field-container">
              <h2>Dados da transportadora</h2>
            </div>
            <div className="p-col-12 form-field-container">
              <label>Nome</label>
              <InputText
                name="shippingCarrier"
                value={props.record.shippingCarrier ?? ""}
                readOnly={true}
              />
            </div>
            <div className="p-col-3 form-field-container">
              <label className="lxRequiredField">Placa do veículo</label>
              <InputText
                name="vehicle.plate"
                value={props.record.vehicle.plate ?? ""}
                onChange={props.changeHandler}
                disabled={fieldDisabled}
              />
            </div>
            <div className="p-col-4 form-field-container">
              <label>Motorista</label>
              <LookupField
                value={props.record.driver || null}
                field="name"
                name="driver"
                removeEdit={true}
                placeholder="Motorista"

                rows={props.lookupDriver.fields.rows}
                modal={props.lookupDriver.fields.modal}
                first={props.lookupDriver.fields.first}
                header={props.lookupDriver.fields.header}
                records={props.lookupDriver.fields.records}
                visible={props.lookupDriver.fields.visible}
                columns={props.lookupDriver.fields.columns}
                sortOrder={props.lookupDriver.fields.order}
                sortField={props.lookupDriver.fields.sortField}
                editDisabled={props.lookupDriver.fields.editDisabled}
                totalRecords={props.lookupDriver.fields.totalRecords}

                onHide={props.lookupDriver.actions.onHide}
                onPage={props.lookupDriver.actions.onPage}
                onSort={props.lookupDriver.actions.onSort}
                onClick={props.lookupDriver.actions.onClick}
                onFilter={props.lookupDriver.actions.onFilter}
                disabled={props.lookupDriver.actions.disabled}
                onCancel={props.lookupDriver.actions.onCancel}
                onConfirm={props.lookupDriver.actions.onConfirm}
                onComplete={props.lookupDriver.actions.onComplete}
                onEditField={props.lookupDriver.actions.onEdit}
                onInputFilter={props.lookupDriver.actions.onInputFilter}
                paginator={true}
                rowsPerPageOptions={[5, 10, 20, 50]}
              />
            </div>
            <div className="p-col-3 form-field-container">
              <label className="lxRequiredField">CPF motorista</label>
              <InputText
                name="driver.documentNumber"
                value={props.record.driver.documentNumber ?? ""}
                onChange={() => { }}
                disabled={true}
              />
            </div>
            {/* Dados para criar viagem Torre de Controle */}
            <div className="p-col-12 form-field-container">
              <h2>Dados de espelhamento do Equipamento</h2>
            </div>
            <div className="p-col-3 form-field-container">
              <label>Usuário espelhamento</label>
              <InputText
                name="towerIntegration.userName"
                value={props.record.towerIntegration.userName ?? ""}
                onChange={props.changeHandler}
              />
            </div>
            <div className="p-col-3 form-field-container">
              <label>Senha espelhamento</label>
              <InputText
                name="towerIntegration.password"
                value={props.record.towerIntegration.password ?? ""}
                onChange={props.changeHandler}
              />
            </div>
            <div className="p-col-3 form-field-container">
              <label>Integração de rastreamento</label>
              <Dropdown
                placeholder="Selecione"
                value={props.record.towerIntegration.equipmentType ?? ""}
                dataKey={"oid"}
                key={Math.random()}
                optionLabel="name"
                options={props.equipmentTypes}
                onChange={props.onDropdownEquipmentTypeChange}
              />
            </div>


            {/* Dados para criar viagem Torre de Controle */}

            <div className="p-col-6 form-field-container">
              <br />
              <button
                className="lognex-btn-highlighted"
                style={{ width: "100%" }}
                title="Confirmar"
                onClick={props.onAcceptedHandler}
                disabled={props.disabledBtnConfirm}
              >
                Confirmar
              </button>
            </div>
            <div className="p-col-6 form-field-container">
              <br />
              <button
                className="lognex-btn-danger"
                style={{ width: "100%" }}
                title="Rejeitar"
                onClick={props.onRejectHandler}
                disabled={props.disabledBtnReject}
              >
                Rejeitar
              </button>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};
